import classNames from "classnames";
import Typography from "components/Typography";
import styles from "styles/components/chemistryProgress.module.css";
import { ChemistryColorScheme } from "core/enums";

type Props = {
  totalBars?: number;
  chemistry: number;
  displayValue?: boolean;
  type?: ChemistryColorScheme;
};

const ChemistryProgress = ({
  chemistry,
  totalBars = 4,
  displayValue = false,
  type = ChemistryColorScheme.ChemistryColorScheme1,
}: Props) => {
  const segregationCount = 11; // this is just for UI
  const radius = 38; // Radius of the circle
  const strokeWidth = displayValue ? 6 : 14; // Base width of the progress bar
  const anglePerBar = 360 / segregationCount; // Angle covered by each bar
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  const filledBarLength = (circumference / segregationCount) * 1.6; // Increase length for filled segments
  const gapLength = circumference / segregationCount / 6; // Gap between segments

  const filledBarsCount = Math.ceil(chemistry / (100 / totalBars));

  const getStrokeClass = (index: number) => {
    const isFilled = index < filledBarsCount;

    switch (type) {
      case ChemistryColorScheme.ChemistryColorScheme2:
        return classNames({
          [styles.primaryStrokeChemistryColorScheme2]: isFilled && filledBarsCount === 1,
          [styles.secondaryStrokeChemistryColorScheme2]: isFilled && filledBarsCount === 2,
          [styles.tertiaryStrokeChemistryColorScheme2]: isFilled && filledBarsCount >= 3,
          [styles.quaternaryStrokeChemistryColorScheme2]: !isFilled,
        });
      case ChemistryColorScheme.ChemistryColorScheme1:
      default:
        return classNames({
          [styles.primaryStrokeChemistryColorScheme1]: isFilled && filledBarsCount === 1,
          [styles.secondaryStrokeChemistryColorScheme1]: isFilled && filledBarsCount === 2,
          [styles.tertiaryStrokeChemistryColorScheme1]: isFilled && filledBarsCount >= 3,
          [styles.quaternaryStrokeChemistryColorScheme1]: !isFilled,
        });
    }
  };

  const renderBars = () => {
    return Array.from({ length: totalBars }).map((_, index) => (
      <circle
        key={index}
        cx="50" // Center X of the circle
        cy="50" // Center Y of the circle
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        strokeDasharray={`${filledBarLength} ${circumference - filledBarLength + gapLength}`}
        strokeDashoffset={-(index * 21) + index * gapLength}
        transform={`rotate(${index * anglePerBar} 50 50) rotate(-204 50 50)`}
        className={getStrokeClass(index)} // Apply stroke color class
      />
    ));
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.noBg]: displayValue,
      })}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        {renderBars()}
        <circle cx="50" cy="50" r="38" fill={displayValue ? "none" : "fill-current"} />
      </svg>
      <div
        className={classNames(styles.content, {
          [styles.contentTop]: displayValue,
        })}
      >
        <img
          src="icon/beaker.png"
          alt="chemistry"
          className={classNames(styles.chemistryIconNormal, {
            [styles.chemistryIconSmall]: displayValue,
          })}
        />
        <Typography fontWeight="font700" hidden={!displayValue} fontFamily="avenirNextSemiBold">
          {chemistry}
          <span className={classNames(styles.spanText)}>%</span>
        </Typography>
      </div>
    </div>
  );
};

export default ChemistryProgress;
