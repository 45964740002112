import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useImagesPreloaded } from "hooks/usePreloader";
import { shallow } from "zustand/shallow";
import Typography from "components/Typography";
import Loader from "components/Loader";
import LazyLoader from "components/LazyLoader";
import getConfig from "util/configHelper";
import appEventInstance from "util/eventHandler";
import sessionHelper from "service/sessionHelper";
import styles from "styles/components/drawers/teamNameDrawer.module.css";
import { AppEventTypes, CanvasEvents, CanvasMessage } from "core/enums";
import Drawer from "components/Drawer";
import {
  DrawerPositions,
  DrawerSizes,
  DrawerBgType,
  DrawerContentHeight,
  CanvasView,
} from "core/enums";
import useGameStore from "store/gameStore";
import TextFeild from "components/TextFeild";
import {
  useFtuNationalityQuery,
  useFtuUpdateMutation,
  useProfanityCheckLazyQuery,
} from "generated/graphql";
import PixiContainer from "components/PixiContainer";
import _Error, { ErrorMessage } from "util/ErrHandler";

const Nationality =({initialUserDetails,handleNationality, data}: any) => {
  const { configData } = getConfig();
  const [loading, setLoading] = React.useState(true)

  const allImagesLoaded = useImagesPreloaded(data?.teamManager?.ftuNationality?.map((el:any)=>el?.imageUrl));

  React.useEffect(()=> {
    if(allImagesLoaded) {
      console.log("allImagesLoaded: All image loaded")
      setLoading(false)
    }
  }, [allImagesLoaded])

  return (
   <>
   {
    loading ? (<Loader/>) : (
      <div className={classNames(styles.nationalityRoot)}>
      <Typography fontFamily="degularBold">{configData?.ftuiPages?.selectNationlaity}</Typography>
      <div className={classNames(styles.scrollableList)}>
        
        {//@ts-ignore
        data?.teamManager?.ftuNationality.map((nation, index) => (
          <div
            key={index}
            className={classNames(styles.flexRow, {
              [styles.activeRow]: nation.name === initialUserDetails.country,
            })}
            onClick={() => handleNationality(nation.name)}
          >
            <img src={`${nation.imageUrl}`} alt={nation.name} className={classNames(styles.flagImg)}/>
            {/* <LazyLoader url={`${nation.imageUrl}`} alt={nation.name} className={classNames(styles.flagImg)} /> */}
            <Typography wrap fontFamily="degularBold" size="2xl">
              {nation.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
    )
   }</>
  )
}

const TeamNameDrawer: React.FC = () => {
  const { configData, errConfigData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isOpenTeamNameDrawer,
    closeAllDrawers,
    updateDrawerStates,
    initialUserDetails,
    setInitialUserDetails,
    setFtuData,
    ftuData,
  } = useGameStore(
    (state) => ({
      isOpenTeamNameDrawer: state.drawerStates.isOpenTeamNameDrawer,
      closeAllDrawers: state.closeAllDrawers,
      updateDrawerStates: state.updateDrawerStates,
      initialUserDetails: state.initialUserDetails,
      setInitialUserDetails: state.setInitialUserDetails,
      setFtuData: state.setFtuData,
      ftuData: state.ftuData,
    }),
    shallow,
  );

  const [view, setView] = useState<string>("team_name");
  const [showFooterButton, setShowFooterButton] = useState(true);
  const [ftuUpdateMutation] = useFtuUpdateMutation();
  const { data } = useFtuNationalityQuery({});
  const [checkProfanity] = useProfanityCheckLazyQuery();
  const [loading, setLoading] = useState(false)

  const handleClose = async () => {
    try {
      if (view === "team_name") {
        setLoading(true)
        if (!initialUserDetails?.name?.trim()) throw new _Error({ message: errConfigData.GEN03, toastPosition: "bottom" });
        if (initialUserDetails?.name?.trim()?.length < 3)
          throw new _Error({ message: errConfigData.GEN05, toastPosition: "bottom" });

        let resp = await checkProfanity({
          variables: { text: initialUserDetails.name },
        });

        if (resp?.data?.players?.profanityCheck?.isValid) {
          setView("select_country");
        } else {
          setInitialUserDetails({ ...initialUserDetails, name: "" });
          throw new _Error({ message: errConfigData.PROF01, toastPosition: "bottom" });
        }
      } else if (view === "select_country") {
        if (initialUserDetails?.country === "") {
          throw new _Error({ message: errConfigData.GEN04, toastPosition: "top" });
        }
        setView("animation_started");
        
        //@ts-ignore
        window?.gameExtension?.({
          type: CanvasMessage.initiateCanvas,
          value: {},
        });

        const resp = await ftuUpdateMutation({
          variables: { input: { name: initialUserDetails?.name?.trim(), country: initialUserDetails.country } },
        });

        if (resp?.data?.teamManager?.ftuUpdate) {
          setFtuData(resp.data.teamManager.ftuUpdate);

          //@ts-ignore
          window?.gameExtension?.({
            type: CanvasMessage.ftuData,
            value: {...resp.data.teamManager.ftuUpdate, country: initialUserDetails?.country},
          });
        }
      } else if (view === "animation_started") {
        setShowFooterButton(false);

        //@ts-ignore
        window?.gameExtension?.({
          type: "ftuAnimation",
          message: "CONTINUE_ANIMATION",
        });
      } else if (view === "animation_completed") {
        updateDrawerStates({ isOpenTeamNameDrawer: false });
        appEventInstance.dispatch({
          eventType: AppEventTypes.fetchTeam,
          payload: { value: true },
        });
        navigate(`/team${location.search}`);
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  const handleOnTeamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInitialUserDetails({ name: event.target.value });
  };

  const handleNationality = (nation: string) => {
    setInitialUserDetails({ country: nation });
  };

  const renderView = () => {
    const viewComponents: Record<string, JSX.Element> = {
      team_name: (
        <div className={classNames(styles.teamNameRoot)}>
          <Typography size="3xl" fontFamily="degularBold">
            {configData?.ftuiPages?.nameYourTeam}
          </Typography>
          <img src={`images/team/teamEdit/badges/${sessionHelper.badge}.svg`} alt="clubImage" className={classNames(styles.clubImg)} />
          <div className={classNames(styles.teamName)}>
            <TextFeild
              onChange={handleOnTeamChange}
              value={initialUserDetails.name}
              addBorder
              backGroundColor="transparent"
              topLabel="Team Name"
              isHideInsideLabel={true}
              isHideTopLabel={false}
            />
          </div>
        </div>
      ),
      select_country: (
        <Nationality data={data} initialUserDetails={initialUserDetails} handleNationality={handleNationality} />
      ),
      // animation_started: <PixiContainer view={CanvasView.packOpening} />,
      // animation_completed: (
      //   <div className={classNames(styles.packSummarRoot)}>
      //     <div className={classNames(styles.flexCol)}>
      //       <Typography fontFamily="degularBlack" size="3xl">
      //         {ftuData?.packName}
      //       </Typography>
      //       <Typography fontFamily="degularBold" size="2xl">
      //         {configData?.ftuiPages?.summary}
      //       </Typography>
      //     </div>
      //     <div className={classNames(styles.scrollablePlayers)}>
      //       <ListView<UserPlayerCard>
      //         headerData={{ title: configData.team.allPlayers, filterOptions: playerListFilterOptions }}
      //         list={ftuData?.players || []}
      //         renderItem={renderPlayerCard}
      //       />
      //     </div>
      //   </div>
      // ),
    };

    if(view === "animation_completed") return viewComponents["animation_started"]
    return viewComponents[view] || null;
  };

  const handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;
    switch (detail.messageName) {
      case CanvasEvents.packOpeningCompleted:
        setView("animation_completed");
        setShowFooterButton(true);
        break;
      case CanvasEvents.removeContinue:
        setView("animation_started");
        setShowFooterButton(false);
        break;
      default:
    }
  };

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, handleCanvasEvent);

    if (sessionHelper?.isFTU) {
      setTimeout(() => {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleWelcomeModal,
          payload: { msg: configData?.ftuiPages?.welcomepackMsg },
        });
      }, 700);
    }

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
      appEventInstance.remove(AppEventTypes.fetchTeam);
      closeAllDrawers();
    };
  }, []);

  return (
    <Drawer
      id="teamNameDrawer"
      onClose={handleClose}
      isOpen={isOpenTeamNameDrawer}
      position={DrawerPositions.Top}
      disableSlideInAnimation={true}
      hideButtonText={!showFooterButton}
      drawerSize={DrawerSizes.Xl}
      drawerBgType={
        view === "team_name"
          ? DrawerBgType.TeamName
          : view === "select_country"
            ? DrawerBgType.SelectNationality
            : view === "animation_completed"
              ? DrawerBgType.SummerPack
              : DrawerBgType.TeamName
      }
      contentHeight={DrawerContentHeight.Full}
      footerButtonText={view === "animation_started" ? "Tap To Open" : "Continue"}
      loading={loading}
      disabled={loading}
    >
      <section className={styles.root}>
        <div className={classNames({ [styles.removeVisibility]: ["team_name", "select_country"].includes(view) })}>
          {isOpenTeamNameDrawer && <PixiContainer view={CanvasView.packOpening} />}
        </div>
        <div
          className={classNames(styles.subRoot, { [styles.hideEl]: !["team_name", "select_country"].includes(view) })}
        >
          {["team_name", "select_country"].includes(view) ? renderView() : null}
        </div>
      </section>
    </Drawer>
  );
};

export default TeamNameDrawer;
