const errConfig = {
  GEN00: "Something went wrong!",
  GEN01: "Session Expired.",
  GEN03: "Team Name cannot be empty.",
  GEN04: "Please select country.",
  GEN05: "Team name should contain atleast 3 characters.",

  PROF01: "The team name contains inappropriate language. Please try a different name.",
};

export default errConfig;
