import { useState, useEffect } from "react";

/**
 * Custom hook to preload images and return whether all are preloaded
 * @param {string[]} imageUrls - List of image URLs to preload
 * @returns {boolean} - `true` if all images are preloaded, otherwise `false`
 */
export function useImagesPreloaded(imageUrls: string[]) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!imageUrls || imageUrls.length === 0) {
      setIsLoaded(true);
      return;
    }

    let isCancelled = false;

    const preloadImages = async () => {
      try {
        const preloadPromises = imageUrls.map(
          (url) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            }),
        );

        await Promise.all(preloadPromises);

        if (!isCancelled) {
          setIsLoaded(true);
        }
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();

    // Cleanup function to prevent setting state after unmount
    return () => {
      isCancelled = true;
    };
  }, [imageUrls]);

  return isLoaded;
}
