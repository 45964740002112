import React, { useState } from "react";
import ContentShell from "components/ContentShell";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/stakePannelOptions.module.css";
import StatsCard from "pages/teamSelect/components/StatsCard";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, CanvasEvents, RoutesEnum } from "core/enums";
import getConfig from "util/configHelper";
import { MyTeam, useCurrentBetSlipQuery, useOddsQuery, usePlayersMutation } from "generated/graphql";
import { TeamData } from "core/interfaces/common";
import BettingsOdds from "./components/odds/BettingsOdds";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import StakePannelInput from "./components/StakePannelInput";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import Button from "components/Button";
import Loader from "components/Loader";

const StakePannelOptions = () => {
  const { configData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const { setSelectedOddsOptions } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
    }),
    shallow,
  );

  const [teamVersus, setTeamVersus] = React.useState<MyTeam[]>([]);
  debug({ event: "calling", message: "Stake Pannel Options  ..." });

  const { data, loading, error } = useOddsQuery({
    skip: !!sessionHelper.oddsDetails?._id,
    onCompleted: (data) => {
      if (data) {
        const beResponse = data?.players?.odds;

        if (beResponse?._id) {
          let _oddsResp = data?.players?.odds;
          sessionHelper.updateOddDetails(_oddsResp);
        }
        let UpdatedTeamVersus = [
          { ...sessionHelper.oddsDetails?.teamA },
          {
            name: "versus",
            icon: "headvs",
          },
          { ...sessionHelper.oddsDetails?.teamB },
        ];
        setTeamVersus(UpdatedTeamVersus as any);
      }
    },
    // skip: sessionHelper.oddsDetails?._id,
  });

  const {
    data: currentStakeSlipsData,
    loading: isCurrentStakeSlipsLoading,
    error: isCurrentStakeSlipError,
  } = useCurrentBetSlipQuery({
    onCompleted: (data) => {
      if (data) {
        const currStakeSlipRes = data.players.currentBetSlip;
        // console.log(currStakeSlipRes, "resetting selected stake options");
        setSelectedOddsOptions(currStakeSlipRes as any, "reset");
      }
    },
  });

  const [playersMutation, { data: startMatchData, loading: isStartMatchLoading, error: startMatchError }] =
    usePlayersMutation();

  const handleStartMatch = async () => {
    let resp = await playersMutation();
    if (resp.data?.players?.startMatch?.matchId) {
      navigate(`${RoutesEnum.playground}`);
    } else {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: "Error Starting Match !" },
      });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      // for setting up footer functinality
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.drawerCloseBtnWords.back,
          event: CanvasEvents.showBack,
          callback: () => {
            navigate(`/team${location.search}`);
          },
          forCanvas: false,
        },
      });
    }, 0);
    let UpdatedTeamVersus = [
      { ...sessionHelper.oddsDetails?.teamA },
      {
        name: "versus",
        icon: "headvs",
      },
      { ...sessionHelper.oddsDetails?.teamB },
    ];
    setTeamVersus(UpdatedTeamVersus as any);
  }, []);
  return (
    <ContentShell loading={loading}>
      <div
        className={classNames(styles.drawerRoot, {
          [styles.disableClick]: sessionHelper.isFTU,
        })}
      >
        <header className={styles.betPannelHeader}>
          <div className={styles.teamVersus}>
            {teamVersus?.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  {data.name === "versus" ? (
                    <img className={styles.vs} src={`icon/home/headvs.png`} alt="teamImage" />
                  ) : (
                    <div
                      className={classNames(styles.team, {
                        [styles.rowReverse]: index === teamVersus.length - 1,
                      })}
                    >
                      <Typography
                        size={data.name && data?.name?.length > 10 ? "md2" : "md"}
                        fontFamily="degularBold"
                        fontWeight="font800"
                        transform={true}
                      >
                        {data.name}
                      </Typography>
                      <img
                        src={configData.teamEdit.badges.find((item) => item?.name === data.badge)?.image}
                        alt="badge1"
                        className={styles.teamIcon}
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div
            className={classNames(styles.teamsCardContainer, {
              [styles.stickToTop]: !sessionHelper?.isFTU,
            })}
          >
            {/* below the indexes are hardCo ded cause any how we know the indexs  */}
            <div>
              <StatsCard
                data={sessionHelper?.oddsDetails?.teamA as TeamData}
                roundedCorner={true}
                team={"myTeam"}
                tableTitle={"My Team Fc"}
              />
            </div>
            <div>
              <StatsCard
                data={sessionHelper?.oddsDetails?.teamB as TeamData}
                roundedCorner={true}
                team={"opponent"}
                tableTitle={"Fc ToLoose"}
              />
            </div>
          </div>
          <section className={styles.betPannelContainer}>
            {sessionHelper?.oddsDetails?._id && <BettingsOdds />}{" "}
            <div className={styles.startMatchBtn}>
              {" "}
              <Button
                addDropShadow={true}
                onClick={() => handleStartMatch()}
                size="stretch"
                color={"darkGreenBtn"}
                addMinHeight={true}
                displayFlex={true}
                disabled={isStartMatchLoading}
              >
                <Typography color={true ? "purpleBlue" : "purpleBlue700"} fontFamily="avenirNextBold" size={"xl"}>
                  {configData.betPannelWords.startMatch}
                </Typography>
                <Loader type="grayLoader" hide={!isStartMatchLoading} />
              </Button>
            </div>
          </section>
        </header>
      </div>

      <div className={styles.stakePannelInputContainer}>
        <StakePannelInput />
      </div>
    </ContentShell>
  );
};

export default StakePannelOptions;
