import classNames from "classnames";
import { useState, useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import Carousel, { ICarouselNode } from "components/Carousal";
import Tabs from "components/Tab";
import Typography from "components/Typography";
import Button from "components/Button";
import Drawer from "components/Drawer";
import useGameStore from "store/gameStore";
import { formatDataToCarousalNodeType } from "util/miscUtils";
import getConfig from "util/configHelper";
import { DrawerBgType, TabType, DrawerPositions, ZIndexLevels, AppEventTypes } from "core/enums";
import { CardItem, MyTeamsResponse } from "core/interfaces/home";
import styles from "styles/components/drawers/teamEditDrawer.module.css";
import { useProfanityCheckLazyQuery, useUpdateTeamMetadataMutation } from "generated/graphql";
import _Error, { ErrorMessage } from "util/ErrHandler";
import appEventInstance from "util/eventHandler";
import { useImagesPreloaded } from "hooks/usePreloader";
import Loader from "components/Loader";

const TeamEditDrawer = () => {
  const { configData, errConfigData } = getConfig();
  const { isOpenTeamEditDrawer, updateDrawerStates, myTeamResponse, setMyTeamResponse } = useGameStore(
    (state) => ({
      isOpenTeamEditDrawer: state.drawerStates.isOpenTeamEditDrawer,
      updateDrawerStates: state.updateDrawerStates,
      myTeamResponse: state.myTeamResponse,
      setMyTeamResponse: state.setMyTeamResponse,
    }),
    shallow,
  );
  const [activeTab, setActiveTab] = useState<string>(configData.teamEditTabOption[0]?.id);
  const [isEditing, setIsEditing] = useState(false);
  const [kits, setKits] = useState<ICarouselNode<CardItem>[]>([]);
  const [badges, setBadges] = useState<ICarouselNode<CardItem>[]>([]);
  const [selectedKit, setSelectedKit] = useState<ICarouselNode<CardItem> | undefined>();
  const [selectedBadge, setSelectedEmblem] = useState<ICarouselNode<CardItem> | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [UpdateTeamMetadataMutation] = useUpdateTeamMetadataMutation();
  const [checkProfanity] = useProfanityCheckLazyQuery();
  const [loading, setLoading] = useState(true);
  const kitsImageUrls = configData?.teamEdit?.kits?.map((kit: any) => kit.image) || [];
  const badgesImageUrls = configData?.teamEdit?.badges?.map((badge: any) => badge.image) || [];
  const allImagesLoaded = useImagesPreloaded([...kitsImageUrls, ...badgesImageUrls]);

  useEffect(() => {
    if (allImagesLoaded) {
      console.log("All carousel images loaded");
      setLoading(false);
    }
  }, [allImagesLoaded]);

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.populateTeamEdit, (data) => {
      const initializedKits = formatDataToCarousalNodeType(configData?.teamEdit?.kits || [], true);
      const initializedBadges = formatDataToCarousalNodeType(configData?.teamEdit?.badges || [], true);
      const _data = { ...data.detail.data };
      setKits(initializedKits);
      setBadges(initializedBadges);

      if (!selectedKit) {
        const _kit = initializedKits.find((el) => el.id === _data?.kit?.split(".")[0]);
        setSelectedKit(_kit);
      }
      if (!selectedBadge) {
        const _badge = initializedBadges.find((el) => el.id === _data?.badge?.split(".")[0]);
        setSelectedEmblem(_badge);
      }
    });

    return () => appEventInstance.remove(AppEventTypes.populateTeamEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendTeamInfoToCanvas = (data: Partial<MyTeamsResponse>) => {
    //@ts-ignore
    if (window?.gameExtension) {
      //@ts-ignore
      window?.gameExtension({
        type: "TEAM_INFO",
        value: {
          kit: data?.kit,
          badge: data?.badge,
          name: data?.name,
          kitColor: data?.kitColor,
          kitColorDark: data?.kitColorDark,
        },
      });
    }
  };

  const renderItem = (item: ICarouselNode<CardItem>, index: number) => {
    const isSelected =
      activeTab === configData.teamEditTabOption[0]?.id ? selectedKit?.id === item?.id : selectedBadge?.id === item?.id;
    return (
      <div key={index} className={styles.arrows}>
        <div
          className={classNames(styles.carousel, { [styles.selected]: isSelected })}
          onClick={() => handleSelect(item)}
        >
          <img
            src={item?.data?.image as string}
            alt={`item-${index}`}
            className={classNames({
              [styles.carouselKitImg]: activeTab === configData?.teamEdit?.tabs?.kits,
              [styles.carouselEmblemImg]: activeTab === configData?.teamEdit?.tabs?.badges,
            })}
          />
        </div>
      </div>
    );
  };

  const handleSelect = (item: ICarouselNode<CardItem>) => {
    if (activeTab === configData.teamEditTabOption[0]?.id) {
      setSelectedKit(item);
    } else if (activeTab === configData.teamEditTabOption[1]?.id) {
      setSelectedEmblem(item);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0); // Delay to allow state to update
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== null) {
      setMyTeamResponse({ name: value });
    }
  };

  const handleNameSave = async () => {
    setIsEditing(false);
    // Check if the profanity check is valid
    try {
      if (!myTeamResponse?.name) throw new _Error({ message: errConfigData.GEN03, toastPosition: "bottom" });
      if (myTeamResponse?.name?.trim()?.length < 3)
        throw new _Error({ message: errConfigData.GEN05, toastPosition: "bottom" });

      let _res = await checkProfanity({ variables: { text: myTeamResponse?.name } });

      if (!_res?.data?.players?.profanityCheck?.isValid) {
        setMyTeamResponse({ name: "" });
        throw new _Error({ message: errConfigData.PROF01, toastPosition: "bottom" });
      }

      const response = await UpdateTeamMetadataMutation({
        variables: { input: { name: myTeamResponse?.name as string } },
      });

      const data = response.data?.teamManager.updateTeamMetadata;

      // sending team info to canvas
      sendTeamInfoToCanvas(data);
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  const onTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleClose = async () => {
    try {
      if (!myTeamResponse?.name) throw new _Error({ message: errConfigData.GEN03, toastPosition: "bottom" });
      if (myTeamResponse?.name?.trim()?.length < 3)
        throw new _Error({ message: errConfigData.GEN05, toastPosition: "bottom" });

      let _res = await checkProfanity({ variables: { text: myTeamResponse?.name } });

      if (!_res?.data?.players?.profanityCheck?.isValid) {
        setMyTeamResponse({ name: "" });
        throw new _Error({ message: errConfigData.PROF01, toastPosition: "bottom" });
      }

      updateDrawerStates({ isOpenTeamEditDrawer: false });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  const handleSelectClick = async () => {
    try {
      // updating kit and badges
      const resp = await UpdateTeamMetadataMutation({
        variables: {
          input: {
            kit: selectedKit?.id,
            name: myTeamResponse?.name as string,
            badge: selectedBadge?.id,
          },
        },
      });

      const data = resp.data?.teamManager.updateTeamMetadata;

      // update team details in gamestores
      setMyTeamResponse(data);

      // sending team info data to canvas
      sendTeamInfoToCanvas(data);

      updateDrawerStates({ isOpenTeamEditDrawer: false });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
      // showSnackbar({
      //   msg: errMsg?.message,
      //   status: "err",
      //   position: errMsg?.toastPosition,
      // });
    }
  };

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    input.value = input.value.replace(/[^a-zA-Z0-9\s']/g, "");
  };

  return (
    <Drawer
      id="teamEditDrawer"
      onClose={handleClose}
      isOpen={isOpenTeamEditDrawer}
      position={DrawerPositions.Top}
      showFooterButton={true}
      drawerBgType={DrawerBgType.TeamEdit}
      zIndexLevel={ZIndexLevels.Level1}
    >
      <div className={classNames(styles.mainDiv)}>
        <div className={classNames(styles.firstDiv)}>
          <input
            ref={inputRef}
            type="text"
            value={myTeamResponse?.name ?? ""}
            onChange={handleNameChange}
            onInput={handleInput}
            onBlur={handleNameSave}
            autoFocus
            maxLength={10}
            className={classNames(styles.teamNameInput)}
          />
          <div onClick={handleEditClick}>
            <img src="images/team/editButton.png" alt="editBtn" className={classNames(styles.editButton)} />
          </div>
        </div>
        <div className={classNames(styles.secondDiv)}>
          <img src={selectedKit?.data?.image} alt="tshirtImg" className={classNames(styles.tshirtImg)} />
          <img src={selectedBadge?.data?.image} alt="badgeImg" className={classNames(styles.emblemImg)} />
        </div>
        <div className={classNames(styles.tabs)}>
          <Tabs
            type={TabType.Square}
            onClick={(tabId) => onTabClick(tabId)}
            tabNames={configData.teamEditTabOption}
            activeTab={activeTab}
          />
        </div>
        <div className={classNames(styles.thirdDiv)}>
          {loading ? (
            <Loader />
          ) : (
            <Carousel
              pauseOnHover
              showDots={true}
              infinite={true}
              items={activeTab === "kits" ? kits : badges}
              renderItem={renderItem}
            />
          )}
        </div>
        <div className={classNames(styles.selectButton)}>
          <Button size="medium" color="greenBtn" onClick={handleSelectClick}>
            <Typography color="navyBlue" size="lg" fontFamily="degularBlack">
              {configData?.teamEdit?.tabs?.select}
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default TeamEditDrawer;
