import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/stakeInfoModal.module.css";

const StakeInfoModal = () => {
  const { configData } = getConfig();
  const { isOpenStakeInfoModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenStakeInfoModal: false });
  };

  return (
    <Modal
      id="stakeInfoModal"
      open={isOpenStakeInfoModal}
      handleClose={handleModalClose}
      footer={<></>}
      containerSize="lg"
    >
      <section className={classNames(styles.root)}>
        <div className={classNames(styles.row)}>
          <img className={classNames(styles.icon)} src="icon/infoGreen.png" alt="infoGreen" />
        </div>{" "}
        <div className={classNames(styles.row)}>
          <Typography position="center" fontFamily="avenirNextBold" size="xl">
            {configData.xpBoostInfo.xpBoost}
          </Typography>
        </div>
        <div className={classNames(styles.row)}>
          <Typography size="md" fontFamily="avenirNextMedium">
            {/* {configData.xpBoostInfo.forEveryStake} */}
            {configData?.xpBoostInfo?.forEvery}{" "}
            <span className={classNames(styles.fontBlack)}>{configData?.xpBoostInfo?.euro} </span>
            {configData?.xpBoostInfo?.staked}{" "}
            <span className={classNames(styles.fontBlack)}>{configData?.xpBoostInfo?.xp}</span>.
          </Typography>
          <Typography size="md" fontFamily="avenirNextMedium">
            {configData.xpBoostInfo.xpBoostedInfo}
          </Typography>
          {configData.xpBoostInfo.xpOddsInfoList.map((item, index) => (
            <div className={classNames(styles.list)} key={index}>
              <Typography fontFamily="avenirNextMedium"> • </Typography>
              <Typography size="md" fontFamily="avenirNextMedium" noWrap={true} addSpaceToRight={true}>
                {item.item}
              </Typography>
              <Typography fontFamily="avenirNextMedium" addSpaceToRight={true}>
                <span className={classNames(styles.fontBlack)}>{item.percent}</span>
              </Typography>
              <Typography size="md" fontFamily="avenirNextMedium">
                {item.boost}
              </Typography>
            </div>
          ))}
        </div>
      </section>
    </Modal>
  );
};
export default React.memo(StakeInfoModal);
