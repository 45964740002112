import classNames from "classnames";
import Typography from "components/Typography";
import sessionHelper from "service/sessionHelper";
import styles from "styles/pages/notFound/notFound.module.css";

function NotFound() {
  return (
    <section className={classNames(styles.notFoundRoot)}>
      <Typography fontFamily="avenirNextBold" size="xl">
        {sessionHelper.operatorId.length > 1 ? "Page Not Found !..." : "Session Expired ..."}
      </Typography>
    </section>
  );
}

export default NotFound;
