import React from 'react';
import classNames from "classnames";
import ImgLazyLoader from "components/ImgLazyLoader";
import { PlayerCardVariant, PlayerCardSize } from "core/enums";
import styles from "styles/components/playerCard.module.css";
import Typography from "components/Typography";
import { positions } from "dummy/dummy";
import ChemistryProgress from "components/ChemistryProgress";
import { UserPlayerCard, CardRarityEnum } from "generated/graphql";
import getConfig from "util/configHelper";

type Props = {
  size?: PlayerCardSize;
  variant?: PlayerCardVariant;
  playerInfo: UserPlayerCard;
  displayChemistryProgress?: boolean;
};

const PlayerCard = ({
  playerInfo,
  size = PlayerCardSize.Sm,
  variant = PlayerCardVariant.Default,
  displayChemistryProgress = true,
}: Props) => {
  const { configData } = getConfig();
  return (
    <div
      className={classNames(styles.root, {
        [styles.basicCardSm]: variant === PlayerCardVariant.Default && size === PlayerCardSize.Sm,
        [styles.basicCardLg]: variant === PlayerCardVariant.Default && size === PlayerCardSize.Lg,
        [styles.basicBronzeCard]: variant === PlayerCardVariant.Default && playerInfo.rarity === CardRarityEnum.Bronze,
        [styles.basicSilverCard]: variant === PlayerCardVariant.Default && playerInfo.rarity === CardRarityEnum.Silver,
        [styles.basicGoldCard]: variant === PlayerCardVariant.Default && playerInfo.rarity === CardRarityEnum.Gold,
        [styles.detailedCardSm]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Sm,
        [styles.detailedCardMd]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Md,
        [styles.detailedCardLg]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Lg,
        [styles.detailedBronzeCard]:
          variant === PlayerCardVariant.Extended && playerInfo.rarity === CardRarityEnum.Bronze,
        [styles.detailedSilverCard]:
          variant === PlayerCardVariant.Extended && playerInfo.rarity === CardRarityEnum.Silver,
        [styles.detailedGoldCard]: variant === PlayerCardVariant.Extended && playerInfo.rarity === CardRarityEnum.Gold,
      })}
    >
      <div className={classNames(styles.imageContainer)}>
        <div className={classNames(styles.flexCol)}>
          <Typography fontFamily="degularBlack">{playerInfo.overallRating}</Typography>
          <div className={styles.clubDiv} style={{ backgroundColor: playerInfo?.extraData?.clubBGColor }}>
            <Typography style={{ color: playerInfo?.extraData?.clubTextColor }} fontFamily="degularSemiBold">
              {playerInfo?.extraData?.clubAbbrName}
            </Typography>
          </div>
        </div>
        <div>
          <div
            className={classNames(styles.text, {
              [styles.positionBgRed]: positions.attack.includes(playerInfo.preferredPosition),
              [styles.positionBgYellow]: positions.midfield.includes(playerInfo.preferredPosition),
              [styles.positionBgBlue]:
                positions.defence.includes(playerInfo.preferredPosition) ||
                positions.goalkeeper.includes(playerInfo.preferredPosition),
            })}
          >
            <Typography size="xs3" fontFamily="degularBlack">
              {playerInfo.preferredPosition}
            </Typography>
          </div>
          <ImgLazyLoader
            src={playerInfo?.nationalityImageUrl || ""}
            alt={playerInfo.displayName || ""}
            className={styles.clubImg}
          />
        </div>
        <ImgLazyLoader src={playerInfo?.imageUrl || ""} alt={playerInfo.displayName || ""} />
      </div>
      <Typography fontFamily="degularBold" color="black">{playerInfo?.displayName}</Typography>
      <div
        className={classNames(styles.statsContainer, {
          [styles.hide]: variant === PlayerCardVariant.Default,
        })}
      >
        <div className={styles.eachStat}>
          <Typography color="black" fontFamily="degularBold">
            {configData.team.playerDetails.statoption.attack}
          </Typography>
          <Typography color="black" fontFamily="degularBold">
            {playerInfo.attackRating}
          </Typography>
        </div>
        <div className={styles.separator}>|</div>

        <div className={styles.eachStat}>
          <Typography color="black" fontFamily="degularBold">
            {configData.team.playerDetails.statoption.defence}
          </Typography>
          <Typography color="black" fontFamily="degularBold">
            {playerInfo.defenseRating}
          </Typography>
        </div>
        <div className={styles.separator}>|</div>
        <div className={styles.eachStat}>
          <Typography color="black" fontFamily="degularBold">
            {configData.team.playerDetails.statoption.creativity}
          </Typography>
          <Typography color="black" fontFamily="degularBold">
            {playerInfo.creativityRating}
          </Typography>
        </div>
      </div>
      <div
        className={classNames(styles.chemistryProgressContainer, {
          [styles.hide]: !displayChemistryProgress,
        })}
      >
        <div>
          <ChemistryProgress chemistry={playerInfo.chemistry || 0} totalBars={4} />
        </div>
      </div>
    </div>
  );
};


export default PlayerCard;
