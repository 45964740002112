import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { home } from "dummy/dummy";
import getConfig from "util/configHelper";
import { gsap, Linear } from "gsap";
import styles from "styles/components/vault.module.css";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    hide?: boolean;
  }
>;

const Vault = ({ hide = false }: Props) => {
  const [startValue] = useState(0); 
  const [endValue] = useState(80);
  const [duration] = useState(3); 

  const { configData } = getConfig();
  const [countDown, setCountDown] = React.useState(0);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [percent, setPercent] = useState(startValue);

  function countdown(counter: number) {
    for (let i = 1; i <= counter; i++) {
      setTimeout(() => {
        setCountDown(i);
      }, i * 10);
    }
  }

  useEffect(() => {
    console.log("running ");
    // Set initial width of progress bar based on startValue
    gsap.set(progressBarRef.current, { width: `${startValue}%` });

    // Start GSAP animation to animate from startValue to endValue
    const tween = gsap.to(progressBarRef.current, {
      width: `${endValue}%`,
      duration: duration,
      ease: Linear.easeNone,
      onUpdate: () => {
        // Calculate the current percentage based on the animation progress
        const newPercent = Math.floor(startValue + (endValue - startValue) * tween.progress());
        setPercent(newPercent);
      },
      onComplete: () => {
        progressBarRef.current?.classList.add("complete");
      },
    });
  }, [startValue, endValue, duration, hide]);

  React.useEffect(() => {
    if (hide) countdown(50);
  }, [hide]);

  return (
    <div
      className={classNames(styles.vaultContainer, {
        [styles.hide]: hide,
      })}
    >
      <div className={classNames(styles.innerDiv)}>
        <div className={classNames(styles.flexRow)}>
          <Typography fontFamily="degularBlack" size="xl1">
            {configData?.home?.theVault?.vault}
          </Typography>
          <Typography fontFamily="avenirNextBold" size="md2">
            {configData?.home?.theVault?.xp}{" "}
          </Typography>
          <Typography fontFamily="avenirNextBold" size="md2">
            {percent}
          </Typography>
          <Typography fontFamily="avenirNextBold">/100</Typography>
        </div>
      </div>
      <div className={classNames(styles.progressContainer)}>
        <div className={classNames(styles.progressBar)} ref={progressBarRef}></div>
        <img
          src={"images/bets/vault.png"}
          alt="pbPacket"
          className={classNames(styles.pbPacket, {
            [styles.rotate]: true,
          })}
        />
      </div>
    </div>
  );
};

export default Vault;