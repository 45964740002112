import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/minMaxStakeModal.module.css";

const MinMaxStakeModal = () => {
  const { configData } = getConfig();
  const { isOpenMinMaxStakeModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenMinMaxStakeModal: state.modalStates.isOpenMinMaxStakeModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenMinMaxStakeModal: false });
  };

  return (
    <Modal
      id="stakeInfoModal"
      open={isOpenMinMaxStakeModal}
      handleClose={handleModalClose}
      footer={<></>}
      containerSize="lg"
    >
      <div className={classNames(styles.body)}>
        {/* <img src="icon/errorPopUp.png" alt="errorPopUp" className="w-12" /> */}
        <div className={classNames(styles.text)}>
          {configData.bets.minMaxValueList.map((list, index) => (
            <div key={`${list.list} - ${index}`}>
              <li>{list.list}</li>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
export default React.memo(MinMaxStakeModal);
