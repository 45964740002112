import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import { CanvasView, AppEventTypes, CanvasEvents, RoutesEnum } from "core/enums";
import { useLocation, useNavigate } from "react-router-dom";
import appEventInstance from "util/eventHandler";
import getConfig from "util/configHelper";
import { useEffect, useState } from "react";
import { useOddsQuery } from "generated/graphql";
import sessionHelper from "service/sessionHelper";

const TeamMatching = () => {
  const { configData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading, error } = useOddsQuery({
    onCompleted: (data) => {
      // console.log("object", data);
      if (data) {
        const beResponse = data?.players?.odds;
        console.log(beResponse);
        if (beResponse?._id) {
          let _oddsResp = data?.players?.odds;
          sessionHelper.updateOddDetails(_oddsResp);
        }
      }
    },
  });

  const onButtonClick = () => {
    navigate(`${RoutesEnum.placeBet}${location.search}`);
  };

  const handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;

    switch (detail.messageName) {
      case CanvasEvents.teamListShown:
        appEventInstance.dispatch({
          eventType: AppEventTypes.toogleFooter,
          payload: {
            label: configData.drawerCloseBtnWords.continue,
            event: CanvasEvents.showContinue,
            callback: () => onButtonClick(),
            forCanvas: false,
            disable: false,
          },
        });
        break;
      default:
    }
  };

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, handleCanvasEvent);

    setTimeout(() => {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.drawerCloseBtnWords.continue,
          event: CanvasEvents.showContinue,
          callback: () => onButtonClick(),
          forCanvas: false,
          disable: true,
        },
      });
    }, 0);

    return () => {
      console.log("wrapper: TeamMatching remove: ", AppEventTypes.canvasEvent)
      appEventInstance.remove(AppEventTypes.canvasEvent);
    };
  }, []);

  return (
    <ContentShell loading={false}>
      <PixiContainer view={CanvasView?.teamMatching} />
    </ContentShell>
  );
};

export default TeamMatching;
