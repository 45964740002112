import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/correctScore.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import getConfig from "util/configHelper";
import sessionHelper from "service/sessionHelper";
import { _getTeamName, debug } from "util/miscUtils";
import { WinEnum } from "generated/graphql";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

const CorrectScore = () => {
  const { configData } = getConfig();
  const [openContent, setOpenContent] = useState(true);
  const [showMore, setShowMore] = useState(false);
  debug({ event: "calling", message: "correct Score  ..." });
  let keyName = sessionHelper.oddsDetails?.correctScore?.name as string;

  const selectedCorrectScoreOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
    }),
    shallow,
  );

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  let correctScoreOdds = sessionHelper.oddsDetails?.correctScore;
  const maxVisibleItems = 4;
  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    try {
      let resp = await handleBoxClick(id, keyName, [...selectedCorrectScoreOptions]);
      setSelectedOddsOptions(resp || [], keyName);
    } catch (e) {}
  };

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        boxHeading={correctScoreOdds?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxRowContainer}>
          {["A", "draw", "B"].map((winType) => {
            const itemOdd = correctScoreOdds?.data.find((item) => item.win === winType);
            if (!itemOdd) return null; // Skip if no matching data

            const visibleValues = showMore ? itemOdd.values : itemOdd.values.slice(0, maxVisibleItems);

            return (
              <div key={itemOdd.win} className={styles.boxCol}>
                <div className={classNames(styles.col)}>
                  <Typography
                    color="grayPlus"
                    size={getTeamName(itemOdd.win).length > 10 ? "xs" : "md"}
                    fontFamily="avenirFont"
                    noWrap={true}
                  >
                    {getTeamName(itemOdd.win)}
                  </Typography>
                </div>
                {visibleValues.map((oddValues, oddIndex) => (
                  <div key={`${oddIndex}-${oddValues.odd}`} className={styles.col}>
                    <BoxSelection
                      active={
                        selectedCorrectScoreOptions.length &&
                        selectedCorrectScoreOptions?.some((ele) => ele.oddId === oddValues.id)
                          ? true
                          : false
                      } // implement active state
                      onClick={() => handleUserInteraction(oddValues.id, keyName)}
                      title={`${oddValues.team1}-${oddValues.team2}`}
                      open={openContent}
                      isClicked={isAddingBetLoading}
                      value={oddValues.odd}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        {correctScoreOdds?.data.some((item) => item.values.length > maxVisibleItems) && (
          <div onClick={() => setShowMore((prev) => !prev)} className={styles.showMoreContainer}>
            <Typography size="lg" className={styles.showMoreButton}>
              {showMore ? configData.bets.showLess : configData.bets.showMore}
            </Typography>
            <img
              className={classNames(styles.arrowIcon, {
                [styles.rotateIcon]: showMore,
              })}
              src={"icon/arrowDown.png"}
              alt=""
            />
          </div>
        )}
      </Accordion>
    </section>
  );
};

export default React.memo(CorrectScore);
