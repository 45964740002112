import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Modal from "components/Modal";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/playerChemistryModal.module.css";
import ChemistryProgress from "components/ChemistryProgress";
import PlayerCard from "components/PlayerCard";
import { positions } from "dummy/dummy";
import { ChemistryInfoProps } from "core/interfaces/common";
import useGameStore from "store/gameStore";
import { players, PlayerChemistyInfo } from "dummy/dummy";
import { ChemistryColorScheme } from "core/enums";

// Functional component to display a series of Man Icons
const ManIconComp: React.FC<{ times: number }> = ({ times }) => (
  <>
    {Array.from({ length: times }).map((_, index) => (
      <img src="icon/manIcon.png" alt="Man Icon" key={index} />
    ))}
  </>
);

// Functional component to display chemistry information
const ChemistryStats: React.FC<{ info: ChemistryInfoProps; noProgressBar?: boolean }> = ({ info, noProgressBar }) => (
  <div
    className={classNames(styles.infoBox, {
      [styles.preferredPosition]: info.label !== "Position",
    })}
  >
    <div className={styles.infoContent}>
      <img
        className={classNames(styles.infoIcon, {
          [styles.hide]: !info.icon,
        })}
        src={info.icon}
        alt={`${info.label} Icon`}
      />
      <div
        className={classNames(styles.role, {
          [styles.positionBgRed]: info?.position && positions.attack.includes(info.position),
          [styles.positionBgYellow]: info?.position && positions.midfield.includes(info.position),
          [styles.positionBgBlue]: info?.position && positions.defence.includes(info.position),
          [styles.positionBgBlue]: info?.position && positions.goalkeeper.includes(info.position),
          [styles.hide]: info.icon,
        })}
      >
        <Typography color="black" size="xs" fontFamily="avenirFont">
          {" "}
          {info?.position || "N/A"}
        </Typography>
      </div>
      <Typography fontFamily="avenirFont" className={styles.infoLabel}>
        {info.label}
      </Typography>
    </div>

    {/* Conditionally render ManIconComp or Typography based on info.label */}
    <Typography size="xs" fontFamily="avenirFont">
      {info.text}
    </Typography>
    <div
      className={classNames(styles.iconContainer, {
        [styles.hide]: info.id === 3,
      })}
    >
      <ManIconComp times={info.value1} />
    </div>
    <div className={styles.progressContainer}>
      <ChemistryProgress chemistry={info.progressbar1} type={ChemistryColorScheme.ChemistryColorScheme2} />
    </div>
    <div className={classNames(styles.separator)} />
    <Typography size="xs" fontFamily="avenirFont"></Typography>
    <div
      className={classNames(styles.iconContainer, {
        [styles.hide]: info.id === 3,
      })}
    >
      <ManIconComp times={info.value2} />
    </div>
    <div className={classNames(styles.progressContainer, { [styles.hide]: noProgressBar })}>
      <ChemistryProgress chemistry={info.progressbar2} type={ChemistryColorScheme.ChemistryColorScheme2} />
    </div>
  </div>
);

const PlayerChemistryModal: React.FC<{}> = () => {
  const { configData } = getConfig();
  const { isOpenPlayerChemistryModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenPlayerChemistryModal: state.modalStates.isOpenPlayerChemistryModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleClose = () => {
    updateModalStates({ isOpenPlayerChemistryModal: false });
  };

  return (
    <>
      <Modal
        id="playerChemistryModal"
        open={isOpenPlayerChemistryModal}
        handleClose={() => handleClose()}
        title={configData?.team?.modal?.buildingChemistry?.title}
        footer={null}
        containerSize="lg"
      >
        <div className={styles.playerModalContainer}>
          <Typography size="xs" fontFamily="avenirFont">
            {configData?.team?.modal?.buildingChemistry?.body}
          </Typography>
          <div className={styles.playerCardContainer}>
            <PlayerCard playerInfo={players[0]} />
          </div>
          <div className={styles.infoContainer}>
            <ChemistryStats info={PlayerChemistyInfo?.nationality} />
            <ChemistryStats info={PlayerChemistyInfo?.club} />
            <ChemistryStats info={PlayerChemistyInfo?.position} noProgressBar />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(PlayerChemistryModal);
