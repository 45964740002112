import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Modal from "components/Modal";
import Typography from "components/Typography";
import Button from "components/Button";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import styles from "styles/components/modals/errorPopUpModal.module.css";

const WelcomePackModal = () => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.toggleWelcomeModal, (info) => {
      const { detail } = info;
      console.log("open");
      setMsg(detail.msg);
      setOpen(true);
    });
    return () => {
      appEventInstance.remove(AppEventTypes.toggleWelcomeModal);
    };
  }, []);

  const handleClose = async () => {
    setOpen(false);

    await setTimeout(() => {
      setMsg("");
    }, 600);
  };
  return (
    <Modal id="welcomePackModal" open={open} handleClose={handleClose} title="" containerSize="xs">
      <div className={classNames(styles.body)}>
        {/* <img src="icon/errorPopUp.png" alt="errorPopUp" className="w-12" /> */}
        <div className={classNames(styles.text)}>
          <Typography fontFamily="avenirNextSemiBold">
            {msg}
          </Typography>
        </div>
        <div className={classNames(styles.btn)}>
          <Button color="blueBtn" size="small" onClick={handleClose}>
            <Typography fontFamily="degularBlack" color="navyBlue">
              OK
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(WelcomePackModal);
