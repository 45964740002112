import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import styles from "styles/components/carousal.module.css";
import { CarouselProps, CarouselRefHandler, GenericCarouselType, WithForwardRefType } from "core/interfaces/team";

export interface ICarouselNode<RecordOption = Record<string, unknown>> extends Record<string, unknown> {
  id: string;
  data: RecordOption;
}

type Props<RecordType> = CarouselProps<RecordType>;

function CarouselWithRef<RecordType>(
  {
    items,
    renderItem,
    autoScroll = false,
    pauseOnHover = false,
    delayStart = 1000,
    infinite = false,
  }: Props<RecordType>,
  carouselRef: Ref<CarouselRefHandler>,
) {
  const ref = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout>();
  const mouseEnterRef = useRef<boolean>(false);

  // Auto-scroll logic with smooth scrolling
  useEffect(() => {
    if (!autoScroll) return;

    const timeout = setTimeout(() => {
      const interval = 16.67; // 60fps for smooth scrolling
      intervalRef.current = setInterval(() => {
        if (ref.current) {
          ref.current.scrollLeft += 1;
        }
      }, interval);

      if (ref.current && pauseOnHover) {
        ref.current.addEventListener("mouseenter", () => {
          mouseEnterRef.current = true;
          clearInterval(intervalRef.current);
        });

        ref.current.addEventListener("mouseleave", () => {
          mouseEnterRef.current = false;
          intervalRef.current = setInterval(() => {
            if (ref.current) {
              ref.current.scrollLeft += 1;
            }
          }, interval);
        });
      }

      clearTimeout(timeout);
    }, delayStart);

    return () => {
      clearTimeout(timeout);
      clearInterval(intervalRef.current);
    };
  }, [autoScroll, pauseOnHover, delayStart]);

  useImperativeHandle(
    carouselRef,
    () => ({
      snapToNext: () => handleOnSnapNext(),
      snapToPrev: () => handleOnSnapPrev(),
    }),
    [],
  );

  // Snap to the next item
  const handleOnSnapNext = () => {
    if (ref.current && ref.current.firstChild) {
      let itemWidth = (ref.current.firstChild as HTMLElement).offsetWidth;
      itemWidth += 6;
      const scrollLeft = ref.current.scrollLeft;

      ref.current.scrollTo({
        left: scrollLeft + itemWidth,
        behavior: "smooth",
      });
    }
  };

  // Snap to the previous item
  const handleOnSnapPrev = () => {
    if (ref.current && ref.current.firstChild) {
      let itemWidth = (ref.current.firstChild as HTMLElement).offsetWidth;
      itemWidth += 6;

      const scrollLeft = ref.current.scrollLeft;

      ref.current.scrollTo({
        left: scrollLeft - itemWidth,
        behavior: "smooth",
      });
    }
  };

  // Infinite scroll behavior (copy items 10 times if `infinite` is true) will optimize in future
  const renderedItems = infinite ? [...Array(10)]?.flatMap(() => items) : items;

  const next = () => {
    handleOnSnapNext();
  };

  const previous = () => {
    handleOnSnapPrev();
  };

  return (
    <div className={classNames(styles.carouselContainer)}>
      <div className={classNames(styles.appCarousel)} ref={ref}>
        {renderedItems.map((item, index) => (
          <div key={index}>{renderItem(item, index)}</div>
        ))}
      </div>

      <div className={classNames(styles.carouselArrowContainer)}>
        <div onClick={previous}>
          <img src="icon/backArrow.png" alt="backArrow" className={styles.carouselArrowImage} />
        </div>
        <div onClick={next}>
          <img
            src="icon/backArrow.png"
            alt="backArrow"
            className={classNames(styles.carouselArrowImage, styles.carouselArrowImageRotate)}
          />
        </div>
      </div>
    </div>
  );
}

type UnknownRecordType = Record<string, unknown>;

const Carousel: WithForwardRefType<GenericCarouselType<UnknownRecordType>> = forwardRef<
  CarouselRefHandler,
  CarouselProps<GenericCarouselType<UnknownRecordType>>
>(CarouselWithRef);

export default Carousel;
