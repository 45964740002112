import { ReactNode } from "react";
import styles from "styles/pages/team/components/listView.module.css";
import { OptionType } from "core/interfaces/common";
import classNames from "classnames";
type Props<RecordType> = {
  list: Array<RecordType>;
  hide?: boolean;
  headerData: {
    title?: string;
    filterOptions: OptionType[];
  };
  renderItem: (item: RecordType, index: number) => ReactNode;
};

const ListView = <RecordType extends Record<string, unknown>>({
  list = [],
  headerData,
  renderItem,
  hide = false,
}: Props<RecordType>) => {
  return (
    <div
      className={classNames(styles.listContainer, {
        [styles.hide]: hide,
      })}
    >
      {list.map((item, index) => (
        <div key={index} className={styles.card}>
          {renderItem(item, index)}
        </div>
      ))}
    </div>
  );
};

export default ListView;
