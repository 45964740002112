export type ErrorMessage = {
  message: string;
  code?: string;
  toastPosition?: string;
}

export default class CustomError extends Error {
  public code: string;
  public toastPosition: string;
  constructor(data: ErrorMessage, options = {}) {
    const { message, code, toastPosition } = data;

    super(message);
    this.code = code || "";
    this.toastPosition = toastPosition || "";
  }
}

