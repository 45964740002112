import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/finalResult.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import { _getTeamName, debug } from "util/miscUtils";
import sessionHelper from "service/sessionHelper";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

const FinalResult = () => {
  const [openContent, setOpenContent] = useState(true);

  const keyName = sessionHelper.oddsDetails?.finalResult?.name as string;
  const selectedFinalResultOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions, updateStakeInputPannelState } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      updateStakeInputPannelState: state.updateStakeInputPannelState,
    }),
    shallow,
  );

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  let finalResultOddDetails = sessionHelper.oddsDetails?.finalResult;

  const { handleBoxClick, isAddingBetLoading, isRemovingBetLoading } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    try {
      let resp = await handleBoxClick(id, keyName, [...selectedFinalResultOptions]);
      setSelectedOddsOptions(resp || [], keyName);
      updateStakeInputPannelState({ isOpenStakeInputsPannel: true }); // just for now .... change logic handling here ...
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    debug({ event: "calling", message: "final result inside useEffect" });
  }, []);

  return (
    <section className={classNames(styles.root)}>
      <Accordion
        open={openContent}
        boxHeading={finalResultOddDetails?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxList}>
          {finalResultOddDetails?.data.map((item, itemIndex) => (
            <section
              key={`${itemIndex}-${item.odd}`}
              className={classNames(styles.list, {
                [styles.highLightBox]: itemIndex === 0 && sessionHelper.isFTU,
              })}
            >
              <BoxSelection
                active={
                  selectedFinalResultOptions.length && selectedFinalResultOptions?.some((ele) => ele.oddId === item.id)
                    ? true
                    : false
                } // implement active state
                onClick={() => handleUserInteraction(item.id, keyName)}
                title={getTeamName(item.win)}
                value={item.odd}
                isClicked={isAddingBetLoading} // disable until successfull api call ...
              />
            </section>
          ))}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(FinalResult);
