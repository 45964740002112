import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import appEventInstance from "util/eventHandler";
import getConfig from "util/configHelper";
import { CanvasView, AppEventTypes, CanvasEvents, RoutesEnum } from "core/enums";

const Playground = () => {
  const { configData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.drawerCloseBtnWords.continue,
          event: CanvasEvents.showContinue,
          callback: () => {
            console.log("wrapper: Playground", `${RoutesEnum.MatchResult}${location.search}`);
            navigate(`${RoutesEnum.MatchResult}${location.search}`);
          },
          forCanvas: false,
        },
      });
    }, 50);
  }, []);

  return (
    <ContentShell>
      <PixiContainer view={CanvasView?.matchSim} />
    </ContentShell>
  );
};

export default Playground;
