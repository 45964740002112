import { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { setCurrencyFormat } from "util/miscUtils";
import styles from "styles/pages/store/components/packAndCoin.module.css";
import { PackType, PackAndCoinType, CoinType } from "core/enums";

interface CoinData {
  id: number;
  coins: number;
  coinType: CoinType;
  amount: number;
}

interface PackData {
  id: number;
  coins: number;
  amount?: number;
  packType: PackType;
}

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    type: PackAndCoinType;
    data: CoinData | PackData;
  }
>;

const packAndCoin = {
  random: "images/store/randomPack.png",
  bronze: "images/store/bronzePack.png",
  silver: "images/store/silverPack.png",
  gold: "images/store/goldPack.png",
  smallCoins: "images/store/smallCoins.png",
  bigCoins: "images/store/bigCoins.png",
};

const PackAndCoin = ({ type, data }: Props) => {
  const imageSrc =
    type === PackAndCoinType.Pack
      ? packAndCoin[(data as PackData).packType || PackType.Random]
      : packAndCoin[(data as CoinData).coinType || CoinType.small];

  return (
    <div className={classNames(styles.root)}>
      <div
        className={classNames({
          [styles.coinsBlock]: type === PackAndCoinType.Coin,
          [styles.packsBlock]: type === PackAndCoinType.Pack,
        })}
      >
        <div
          className={classNames({
            [styles.hideEl]: type === PackAndCoinType.Pack,
            [styles.show]: type === PackAndCoinType.Coin,
          })}
        >
          <img src="icon/coinIcon.png" alt="iconIcon" className={styles.coinIcon} />
          <Typography color="navyBlue" fontWeight="font700" size="lg">
            {data?.coins}
          </Typography>
        </div>
        <img src={imageSrc} alt={`${type}Image`} className={classNames({ [styles.hideEl]: !imageSrc })} />
        <div
          className={classNames({
            [styles.hideEl]: type === PackAndCoinType.Coin,
            [styles.show]: type === PackAndCoinType.Pack,
          })}
        >
          <img src="icon/coinIcon.png" alt="iconIcon" className={styles.coinIcon} />
          <Typography color="navyBlue" fontWeight="font700" size="lg">
            {data?.coins}
          </Typography>
        </div>
        <div
          className={classNames({
            [styles.hideEl]: type === PackAndCoinType.Pack,
            [styles.show]: type === PackAndCoinType.Coin,
          })}
        >
          <Typography color="navyBlue" fontWeight="font700" size="lg">
            {setCurrencyFormat({ value: 20 })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PackAndCoin;
