import classNames from "classnames";
import { PlayerInfo } from "core/interfaces/common";
import { PlayerCardType, PlayerCardVariant, PlayerCardSize } from "core/enums";
import styles from "styles/components/playerCard.module.css";
import Typography from "components/Typography";
import { positions } from "dummy/dummy";
import ChemistryProgress from "components/ChemistryProgress";

type Props = {
  size?: PlayerCardSize;
  variant?: PlayerCardVariant;
  playerInfo: PlayerInfo;
  displayChemistryProgress?: boolean;
};

const PlayerCard = ({
  playerInfo,
  size = PlayerCardSize.Sm,
  variant = PlayerCardVariant.Default,
  displayChemistryProgress = true,
}: Props) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.basicCardSm]: variant === PlayerCardVariant.Default && size === PlayerCardSize.Sm,
        [styles.basicCardLg]: variant === PlayerCardVariant.Default && size === PlayerCardSize.Lg,
        [styles.basicBronzeCard]:
          variant === PlayerCardVariant.Default && playerInfo.cardType === PlayerCardType.Bronze,
        [styles.basicSilverCard]:
          variant === PlayerCardVariant.Default && playerInfo.cardType === PlayerCardType.Silver,
        [styles.basicGoldCard]: variant === PlayerCardVariant.Default && playerInfo.cardType === PlayerCardType.Gold,
        [styles.detailedCardSm]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Sm,
        [styles.detailedCardMd]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Md,
        [styles.detailedCardLg]: variant === PlayerCardVariant.Extended && size === PlayerCardSize.Lg,
        [styles.detailedBronzeCard]:
          variant === PlayerCardVariant.Extended && playerInfo.cardType === PlayerCardType.Bronze,
        [styles.detailedSilverCard]:
          variant === PlayerCardVariant.Extended && playerInfo.cardType === PlayerCardType.Silver,
        [styles.detailedGoldCard]:
          variant === PlayerCardVariant.Extended && playerInfo.cardType === PlayerCardType.Gold,
      })}
    >
      <div className={classNames(styles.imageContainer)}>
        <div className={classNames(styles.flexCol)}>
          <Typography fontFamily="degularBlack">{playerInfo.level}</Typography>
          <img src={playerInfo?.club?.icon} alt={playerInfo.name} className={styles.clubImg} />
        </div>
        <div>
          <div
            className={classNames(styles.text, {
              [styles.positionBgRed]: positions.attack.includes(playerInfo.position),
              [styles.positionBgYellow]: positions.midfield.includes(playerInfo.position),
              [styles.positionBgBlue]:
                positions.defence.includes(playerInfo.position) || positions.goalkeeper.includes(playerInfo.position),
            })}
          >
            <Typography size="xs" fontFamily="degularBlack">{playerInfo.position}</Typography>
          </div>
          <img src={playerInfo?.country?.icon} alt={playerInfo.name} className={styles.flagImg} />
        </div>
        <img src={playerInfo.avatar} alt={playerInfo.name} />
      </div>
      <Typography fontFamily="degularBold" color="black" size="xs3">{playerInfo.name}</Typography>
      <div
        className={classNames(styles.statsContainer, {
          [styles.hide]: variant === PlayerCardVariant.Default,
        })}
      >
        {playerInfo.stats.map((stat, index) => (
          <div key={index}>
            <div className={styles.eachStat}>
              <Typography color="black" fontFamily="degularBold">{stat.label}</Typography>
              <Typography color="black" fontFamily="degularBold">{stat.value}</Typography>
            </div>
            <div
              className={classNames(styles.separator, {
                [styles.hide]: index === playerInfo.stats.length - 1,
              })}
            >
              |
            </div>
          </div>
        ))}
      </div>

      <div
        className={classNames(styles.chemistryProgressContainer, {
          [styles.hide]: !displayChemistryProgress,
        })}
      >
        <div>
          <ChemistryProgress chemistry={playerInfo.stats[2].value} totalBars={4} />
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
