import React, { useMemo, ReactNode } from "react";
import classNames from "classnames";
import { generateRandomId } from "util/miscUtils";
import styles from "styles/components/snackbar.module.css";

type SnackbarStatus = "err" | "success";
type SnackbarContent = {
  id: string;
  msg?: string;
  status?: SnackbarStatus;
  position?: string;
};

type SnackbarContextType = {
  showSnackbar: (content: Omit<SnackbarContent, "id">) => void;
};

interface SnackbarComponentProps {
  close: () => void;
  children: ReactNode;
  status?: SnackbarStatus;
  position?: string
}

interface SnackbarProviderProps {
  children: ReactNode;
  position?: string;
}

const SnackbarContext = React.createContext<SnackbarContextType | undefined>(undefined);

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ close, children, status = "err", position='top' }) => {
  React.useEffect(() => {
    const timer = setTimeout(close, 2000);

    return () => clearTimeout(timer);
  }, [close]);

  return (
    <div
      className={classNames(styles.snackbar, {
        [styles.err]: status === "err",
        [styles.success]: status === "success",
        [styles.snackBarTop]: position === "top" || !position, //default view should be top
        [styles.snackBarBottom]: position === "bottom",
      })}
    >
      {children}
      <div onClick={close}>X</div>
    </div>
  );
};

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [toasts, setToasts] = React.useState<SnackbarContent[]>([]);

  const showSnackbar = (content: Omit<SnackbarContent, "id">) => {
    if (!content.msg) return;
    setToasts((currentToasts) => [...currentToasts, { id: generateRandomId(), ...content }]);
  };

  const close = (id: string) => setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));

  const contextValue = useMemo(() => ({ showSnackbar }), []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      <div className={classNames(styles.toastWrapper, {})}>
        {toasts.map((toast) => (
          <SnackbarComponent key={toast.id} status={toast.status || "err"} close={() => close(toast.id)} position={toast?.position}>
            {toast.msg}
          </SnackbarComponent>
        ))}
      </div>
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export { SnackbarProvider, useSnackbar };
