import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import appEventInstance from "util/eventHandler";
import getConfig from "util/configHelper";
import { CanvasView, AppEventTypes, CanvasEvents, RoutesEnum, CanvasMessage } from "core/enums";

const Vault = () => {
  const { configData } = getConfig();

  const navigate = useNavigate();
  const location = useLocation();

  const _handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;

    switch (detail.messageName) {
      case CanvasEvents.enableBack:
        appEventInstance.dispatch({
          eventType: AppEventTypes.toogleFooter,
          payload: {
            label: configData.drawerCloseBtnWords.back,
            event: CanvasEvents.showBack,
            callback: () => {
              setTimeout(() => {
                //@ts-ignore
                window?.gameExtension({
                  type: CanvasMessage.showFTUIP3,
                  value: { isFTUI: true },
                });
              }, 300);
              navigate(`${RoutesEnum.Home}${location.search}`);
            },
            forCanvas: false,
            disable: false,
          },
        });
        break;
      default:
    }
  };

  React.useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, _handleCanvasEvent);

    setTimeout(() => {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.drawerCloseBtnWords.back,
          event: CanvasEvents.showBack,
          callback: () => navigate(`${RoutesEnum.Home}${location.search}`),
          forCanvas: false,
          disable: true,
        },
      });
    }, 0);

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
    };
  }, []);

  return (
    <ContentShell>
      <PixiContainer view={CanvasView?.showVault} />
    </ContentShell>
  );
};

export default Vault;
