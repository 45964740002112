import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/components/boxSelection.module.css";

const BoxSelection = ({
  smallBoxPadding = false,
  hideTitle = false,
  smallText = false,
  title = "",
  active = false,
  numberSelectorForTextSize = 5,
  value = "",
  isClicked = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <section
      className={classNames(styles.boxRoot, {
        [styles.active]: active,
        [styles.smBoxPadding]: smallBoxPadding,
        [styles.disableClick]: isClicked,
      })}
      onClick={onClick}
      {...props}
    >
      {" "}
      <Typography
        hide={hideTitle}
        size={title.length > 10 ? "xs2" : "xs"}
        color={active ? "navyBlue" : "gray"}
        fontFamily={active ? "avenirNextMedium" : "avenirFont"}
        isCapitalizeStartLetter={true}
      >
        {title}
      </Typography>
      <Typography
        size={value.length > numberSelectorForTextSize ? "xs" : "lg"}
        color={active ? "navyBlue" : "white"}
        fontFamily={active ? "avenirNextBold" : "avenirNextSemiBold"}
      >
        {value ? value : "-"}
      </Typography>
    </section>
  );
};

export default BoxSelection;
