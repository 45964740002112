import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/resultBtts.module.css";
import getConfig from "util/configHelper";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

const ResultBtts = () => {
  const { configData } = getConfig();
  let keyName = sessionHelper.oddsDetails?.resultBtts?.name as string;
  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const [openContent, setOpenContent] = useState(true);
  const selectedResultBttsOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
    }),
    shallow,
  );

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  const resultBttsOddsDetails = sessionHelper.oddsDetails?.resultBtts;
  const handleUserInteraction = async (id: string, keyName: string) => {
    try {
      let resp = await handleBoxClick(id, keyName, [...selectedResultBttsOptions]);
      setSelectedOddsOptions(resp || [], keyName);
    } catch (e) {}
  };
  debug({ event: "calling", message: "Result BTTS" });

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        boxHeading={resultBttsOddsDetails?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.colRowContainer}>
          <div className={styles.row}>
            <div className={styles.col}></div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {configData.bets.yes}
              </Typography>
            </div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {configData.bets.no}
              </Typography>
            </div>
          </div>

          <div className={styles.boxRowContainer}>
            {/* Directly map and render items inside JSX */}
            {resultBttsOddsDetails?.data.map((items, index) => {
              const data = items?.values; // Extract data from each entry
              return (
                <div
                  key={index} // Unique key for each 'items' entry
                  className={classNames(styles.boxRow, {
                    [styles.marginBottom]: index < resultBttsOddsDetails.data.length - 1, // Apply marginBottom to all except the last item
                  })}
                >
                  <div
                    className={classNames(styles.col, {
                      [styles.alignLeft]: true,
                    })}
                  >
                    <Typography
                      color="grayPlus"
                      size={getTeamName(items.win).length > 10 ? "md2" : "md"}
                      fontFamily="avenirFont"
                      noWrap={true}
                    >
                      {getTeamName(items.win)}
                    </Typography>
                  </div>
                  {data?.map((odd) => (
                    <div key={odd.id} className={styles.col}>
                      {odd.value === "Yes" || odd.value === "No" ? (
                        <BoxSelection
                          active={
                            selectedResultBttsOptions.length &&
                            selectedResultBttsOptions?.some((ele) => ele.oddId === odd.id)
                              ? true
                              : false
                          }
                          onClick={() => handleUserInteraction(odd.id, keyName)}
                          value={odd.odd}
                          isClicked={isAddingBetLoading}

                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(ResultBtts);
