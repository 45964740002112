import UserDetails from "components/UserDetails";
import BalanceDetails from "components/BalanceDetails";

const Header = () => {
  return (
    <div className="w-full">
      <UserDetails />
      <BalanceDetails  />
    </div>
  );
};

export default Header;
