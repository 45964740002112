import config from "core/config";
import errConfig from "core/err";

const getConfig = () => {
  let configData = { ...config };
  let errConfigData = { ...errConfig };

  return { configData, errConfigData };
};

export default getConfig;
