import ContentShell from "components/ContentShell";
import React from "react";
import PixiContainer from "components/PixiContainer";
import { AppEventTypes, CanvasEvents, CanvasView } from "core/enums";
import appEventInstance from "util/eventHandler";
import useGameStore from "store/gameStore";
import { useMyTeamQuery } from "generated/graphql";
import sessionHelper from "service/sessionHelper";
import styles from "styles/pages/team/team.module.css";
import { useNavigate, useLocation } from "react-router-dom";

const Team = () => {
  const {
    updateDrawerStates,
    closeAllDrawers,
    setMyTeamResponse,
    updateModalStates,
    myTeamResponse,
    isOpenTeamNameDrawer,
  } = useGameStore((state) => ({
    updateDrawerStates: state.updateDrawerStates,
    updateModalStates: state.updateModalStates,
    closeAllDrawers: state.closeAllDrawers,
    setMyTeamResponse: state.setMyTeamResponse,
    myTeamResponse: state.myTeamResponse,
    isOpenTeamNameDrawer: state.drawerStates.isOpenTeamNameDrawer,
  }));

  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const teamDataRef = React.useRef({});

  const { refetch } = useMyTeamQuery({
    skip: !sessionHelper?.beToken || isOpenTeamNameDrawer || !!myTeamResponse,
    onCompleted: (data) => {
      if (data) {
        teamDataRef.current = { ...data?.teamManager?.myTeam };
        _sendInfoToCanvas(data);
      }
    },
  });

  const _getCurrStateValues = () => {
    let myTeamResponse = useGameStore.getState().myTeamResponse;

    return { myTeamResponse };
  };

  const _handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;

    switch (detail.messageName) {
      case CanvasEvents.playMatch:
        navigate(`/team-matching${location?.search}`);
        break;
      case CanvasEvents.teamEdit:
        const { myTeamResponse } = _getCurrStateValues();
        teamDataRef.current = { ...myTeamResponse };
        appEventInstance.dispatch({
          eventType: AppEventTypes.populateTeamEdit,
          payload: { data: { ...teamDataRef.current } },
        });

        updateDrawerStates({ isOpenTeamEditDrawer: true });

        // setTimeout(()=>updateDrawerStates({ isOpenTeamEditDrawer: true }), 100)
        break;
      case CanvasEvents.teamInfo:
        updateModalStates({ isOpenPlayerChemistryModal: true });
        break;
      default:
    }
  };

  console.log("wrapper====");
  const _sendInfoToCanvas = (data: any) => {
    //@ts-ignore
    // if (window?.gameExtension) {
    //   //@ts-ignore
    //   window.gameExtension({ type: CanvasMessage.teamData, value: data?.teamManager?.myTeam });
    // }

    setMyTeamResponse(data?.teamManager?.myTeam);
    setLoading(false);
  };

  const _handleFetchTeamData = async () => {
    try {
      setLoading(true);
      refetch();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, _handleCanvasEvent);
    appEventInstance.listen(AppEventTypes.fetchTeam, _handleFetchTeamData);
    if (!!myTeamResponse) {
      teamDataRef.current = { ...myTeamResponse };
      setLoading(false);
    }

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
      appEventInstance.remove(AppEventTypes.fetchTeam);
      closeAllDrawers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentShell loading={loading}>
      <div className={styles.root}>
        <div className={styles.teamView}>
          <PixiContainer view={CanvasView.teamView} />
        </div>
      </div>
    </ContentShell>
  );
};

export default Team;
