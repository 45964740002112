import React, { HTMLAttributes, MouseEvent, PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import styles from "styles/components/modal.module.css";

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    id: string;
    title?: string;
    subTitle?: string;
    handleClose: () => void;
    open: boolean;
    containerSize?: "xs" | "lg" | "xl";
    hideCloseButton?: boolean;
    footer?: ReactNode;
    disableScroll?: boolean;
    addBorder?: boolean;
    noPaddingTop?: boolean;
    disableOverlayInteraction?: boolean;
  }
>;

const Modal = ({
  id,
  title = "",
  subTitle = "",
  footer = null,
  children,
  handleClose,
  open = false,
  disableScroll = false,
  noPaddingTop = false,
  addBorder = false,
  containerSize = "lg",
  hideCloseButton = false,
  disableOverlayInteraction = false,
}: Props) => {
  const isRenderedRef = React.useRef({ value: false });

  const handleOnCloseModal = (e: MouseEvent<HTMLElement>) => {
    if (e.currentTarget.id === "sellPlayerModal" || e.currentTarget.id === "playerChemistryModal") return;
    if (e.currentTarget.id === "errorPopUpModal" && !open) {
      console.log("errorPopUpModal already close");
      return;
    }

    let _id = id;
    if (e.currentTarget.id === id || e.currentTarget.id === `${id}-close`) {
      _id = e.currentTarget.id.split("-")[0];
      handleClose();
    }

    let _modal = document.getElementById(_id);

    setTimeout(() => {
      _modal?.classList.add(styles.hideEl);
    }, 500);
  };

  React.useEffect(() => {
    isRenderedRef.current.value = true;
  }, []);

  return (
    <div
      id={id}
      className={classNames(styles.root, {
        [styles.hideEl]: !isRenderedRef.current.value,
        [styles.hideBg]: !open,
        [styles.center]: true, // Always center the modal
      })}
      onClick={disableOverlayInteraction ? () => {} : () => handleOnCloseModal}
    >
      <div
        className={classNames(styles.container, {
          [styles.large]: containerSize === "lg",
          [styles.xl]: containerSize === "xl",
          [styles.xs]: containerSize === "xs",
          [styles.slideInTop]: open,
          [styles.slideOutTop]: !open,
        })}
      >
        {/* Modal content */}
        <div
          className={classNames(styles.modalContent, {
            [styles.noPaddingTop]: noPaddingTop,
          })}
        >
          {/* Modal header */}
          <div
            className={classNames(styles.modalHead, {
              [styles.hideEl]: hideCloseButton,
              [styles.addBorder]: addBorder,
            })}
          >
            <Typography size="2xl" fontFamily="degularSemiBold">
              {title}
            </Typography>
            <button type="button" data-modal-toggle="defaultModal" id={`${id}-close`} onClick={handleOnCloseModal}>
              <img src={"icon/close.png"} alt="close" height="18" width="18" />
            </button>
          </div>
          <div>
            {subTitle}
          </div>
          {/* Modal body */}
          <div className={classNames(styles.modalBody, { [styles.disableScroll]: disableScroll })}>{children}</div>
          <div
            className={classNames(styles.modalFooter, {
              [styles.hideEl]: !footer,
            })}
          >
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
