import React from "react";
import classNames from "classnames";
import { home } from "dummy/dummy";
import Typography from "components/Typography";
import styles from "styles/components/balanceDetails.module.css";
import { setCurrencyFormat, setNumberFormat } from "util/miscUtils";
import useGameStore from "store/gameStore";
import sessionHelper from "service/sessionHelper";
import { useParams, useLocation } from "react-router-dom";
import { RoutesEnum } from "core/enums";

const BalanceDetails: React.FC = () => {
  const { isOpenSettingsDrawer, updateDrawerStates } = useGameStore((state) => ({
    isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
    updateDrawerStates: state.updateDrawerStates,
  }));

  const { pathname } = useLocation();

  const toggleSettingsDrawer = () => {
    updateDrawerStates({ isOpenSettingsDrawer: !isOpenSettingsDrawer });
  };

  return (
    <div
      className={classNames(styles.balanceContainer, {
        [styles.hide]: pathname === RoutesEnum.TeamMatching,
      })}
    >
      <div className={classNames(styles.innerRect)}>
        <div className={classNames(styles.firstDiv)}>
          <Typography size="lg" fontFamily="avenirNextBold">
            {setCurrencyFormat({ getCurrencySymbol: true })}
          </Typography>
          <Typography size="lg" fontWeight="font700" fontFamily="avenirNextBold">
            {setNumberFormat(sessionHelper?.balance ?? 0, 2)}
          </Typography>
        </div>
        <div className={classNames(styles.secondDiv)}>
          <div className={classNames(styles.amnt)}>
            <img src={"icon/coinIcon.png"} alt="coinIcon" className={classNames(styles.coinIcon)} />
            <Typography fontWeight="font700" fontFamily="avenirNextBold" size="lg">
              {sessionHelper?.pbfCoin}
            </Typography>
          </div>
          <img
            onClick={toggleSettingsDrawer}
            src={"icon/home/settings.png"}
            alt="settingsIcon"
            className={classNames(styles.settingsIcon)}
          />
        </div>
      </div>
    </div>
  );
};

export default BalanceDetails;
