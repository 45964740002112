export enum RoutesEnum {
  Home = "/home",
  Demo = "/demo",
  Team = "/team",
  Store = "/store",
  Bets = "/bets",
  Wallet = "/wallet",
  TeamMatching = "/team-matching",
  MatchResult = "/match-result",
  TeamList = "/team-list",
  placeBet = "/place-bet",
  NotFound = "/page-not-found",
  playground = "/playground",
  gameOutcome = "/game-outcome",
  vault = "/vault"
}

export enum DrawerPositions {
  Right = "right",
  Left = "left",
  Top = "top",
  Bottom = "bottom",
}

export enum DrawerSizes {
  Xs = "xs",
  Md = "md", //with footer
  Xl = "xl",
  Lg = "lg",
  Xs2 = "xs2",
  Default = "",
}

export enum DrawerContentHeight {
  Full = "full",
  Fit = "fit",
  Screen = "screen",
}

export enum PlayerCardSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export enum PlayerCardType {
  Bronze = "bronze",
  Silver = "silver",
  Gold = "gold",
}

export enum PlayerCardVariant {
  Default = "default",
  Extended = "extended",
}

export enum TimelineDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum TabType {
  Square = "squareButton",
  Round = "roundButton",
}

export enum PackType {
  Random = "random",
  Bronze = "bronze",
  Silver = "silver",
  Gold = "gold",
}

export enum CoinType {
  small = "smallCoins",
  Big = "bigCoins",
}

export enum PackAndCoinType {
  Pack = "pack",
  Coin = "coin",
}

export enum ChemistryColorScheme {
  ChemistryColorScheme1 = "chemistryColorScheme1",
  ChemistryColorScheme2 = "chemistryColorScheme2",
}

export enum ZIndexLevels {
  Level1 = "level1",
  Level2 = "level2",
  Level3 = "level3",
}

export enum CanvasView {
  teamView = "TEAM_VIEW",
  simulation = "SIMULATION",
  luckyDraw = "LUCKY_DRAW",
  home = "HOME",
  packOpening = "PACK_OPENING",
  teamMatching = "TEAM_MAKING",
  matchSim = "MATCH_SIMULATION",
  matchResult = "MATCH_RESULT",
  showVault = "SHOW_VAULT",
  showPromotion = "SHOW_PROMOTION",
}

export const CanvasMessage = {
  boosterAdded: "BOOSTER_ADDED",
  teamData: "TEAM_DATA",
  ftuData: "FTU_DATA",
  packModalClosed: "PACK_MODAL_CLOSED",
  showFTUI: "SHOW_FTUI_PHASE2",
  showFTUIP3: "SHOW_FTUI_PHASE3",
  initiateCanvas: "INITIATE_CANVAS",
  matchId:  "MATCH_ID",
};

export const CanvasEvents = {
  playMatch: "PLAY_MATCH",
  allPlayers: "ALL_PLAYERS",
  showMyTeam: "SHOW_MYTEAM",
  playerSwap: "PLAYER_SWAP",
  teamEdit: "TEAM_EDIT",
  packOpeningCompleted: "PACK_ANIM_COMPLETED",
  playerDetails: "PLAYER_DETAILS",
  teamInfo: "TEAM_INFO",
  booster: "BOOSTERS",
  showBack: "SHOW_BACK",
  showContinue: "SHOW_CONTINUE",
  removeBack: "REMOVE_BACK",
  removeContinue: "REMOVE_CONTINUE",
  enableBack: "ENABLE_BACK",
  enableContinue: "ENABLE_CONTINUE",
  addMoreBets: "ADD_MORE_BETS",
  teamListShown: "TEAM_LIST_SHOWN",
  showVault: "SHOW_VAULT",
  ftuiComplete: "FTUI_COMPLETE",
};

export const AppEventTypes = {
  ToggleDrawerScroll: "TOGGLE_DRAWER_SCROLL",
  canvasEvent: "CANVAS_EVENT",
  toogleFooter: "TOGGLE_FOOTER",
  fetchTeam: "FETCH_TEAM",
  toggleErrorModal: "TOGGLE_ERROR_MODAL",
  togglePackModal: "TOGGLE_PACK_MODAL",
  toggleWelcomeModal: "TOGGLE_WELCOME_MODAL",
  populateTeamEdit: "POPULATE_TEAM_EDIT",
};

export enum DrawerBgType {
  Default = "default",
  TeamEdit = "teamEdit",
  BetPannelOptions = "betPannelOptions",
  TeamName = "teamName",
  SelectNationality = "selectNatiionality",
  SummerPack = "summerPack",
}
