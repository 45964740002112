import React, { HTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useInitMutation } from "generated/graphql";
import sessionHelper from "service/sessionHelper";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, CanvasEvents } from "core/enums";
import Loader from "components/Loader";
import useGameStore from "store/gameStore";
import { generateRandomUserId, timer } from "util/miscUtils";

type SessionHelperProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

function useQuery() {
  return window.location.href.split("?")[1];
}

const SessionHelper: React.FC<SessionHelperProps> = ({ children }) => {
  const { updateDrawerStates, toggleCanvasReady } = useGameStore((state) => ({
    updateDrawerStates: state.updateDrawerStates,
    toggleCanvasReady: state.toggleCanvasReady,
  }));
  const [loading, setLoading] = useState(true);

  const [initMutation] = useInitMutation();
  const navigate = useNavigate();
  const canvasRef = React.useRef({ value: false });
  const query = useQuery();

  const _shareFTUIStats = () => {
    //@ts-ignore
    if (window?.gameExtension) {
      console.log("wrapper:", "sending FTUI stat to canvas");
      //@ts-ignore
      window.gameExtension({ type: "isFTU", value: sessionHelper.isFTU });
    }
  };

  const initialize = async (token: string) => {
    try {
      const resp = await initMutation({ variables: { token: token ?? "", currency: "USD" } });

      if (resp?.data?.players?.init) {
        const initData = resp.data.players.init;
        let { beToken, balance, pbfCoin, isFTU, operatorId, badge, kit, kitColor, kitColorDark, extraData } = initData;
        // isFTU = true;

        sessionHelper.onSignIn({
          beToken,
          balance,
          pbfCoin,
          isFTU,
          operatorId,
          language: "en",
          location: "UK",
          initData,
          badge,
          kit,
          kitColor,
          kitColorDark,
          extraData,
        });

        if (!isFTU) {
          updateDrawerStates({ isOpenTeamNameDrawer: false });
        }

        while (!canvasRef.current.value) {
          console.log("wrapper:", "waiting for canvas to get ready");
          await timer(500);
        }

        _shareFTUIStats();
        console.log({ initData });
      } else {
        navigate("/page-not-found");
        console.error("Error: Init data not found in response");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/page-not-found");
      updateDrawerStates({ isOpenTeamNameDrawer: false });
      console.error("Error during initialization:", error);
    }
  };

  const getToken = async (queryParams: Record<string, any> = {}) => {
    try {
      let userId = localStorage.getItem("userId");

      if (!userId) {
        const newUserId = generateRandomUserId();
        localStorage.setItem("userId", newUserId);
        userId = newUserId;
      }

      const payload = {
        sessionToken: "xyz",
        languageCode: "en",
        gameId: "PBF",
        playMode: "demo",
        siteId: "site123",
        lobbyUrl: "https://example.com/lobby",
        bankingUrl: "https://example.com/banking",
        sessionTimeoutUrl: "https://example.com/session-timeout",
        responsibleGamingUrl: "https://example.com/responsible-gaming",
        gameHistoryUrl: "https://example.com/game-history",
        currency: "INR",
        userId: userId,
        isFTUE: queryParams.ftue === "true",
      };

      const response = await fetch(`${process.env.REACT_APP_EXTERNAL_URL}/games/launch`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();

      return data.token;
    } catch (error) {
      console.log("Error fetching token: ", error);
      navigate("/page-not-found");
    }
  };

  useEffect(() => {
    const params = queryString.parse(query);
    let token = params?.token as string;

    if (!token) {
      getToken(params).then((newToken) => initialize(newToken));
    } else initialize(token);

    window.addEventListener("message", (obj) => {
      if (obj?.data?.messageName === "CANVAS_READY") {
        console.log("wrapper:", "CANVAS_READY reveived");
        toggleCanvasReady(true);
        canvasRef.current.value = true;
      } else if (obj?.data?.messageName) {
        appEventInstance.dispatch({
          eventType: [CanvasEvents.showBack, CanvasEvents.showContinue, CanvasEvents.removeBack].includes(
            obj?.data?.messageName,
          )
            ? AppEventTypes.toogleFooter
            : AppEventTypes.canvasEvent,
          payload: { ...obj?.data },
        });
      }
    });

    return () => {
      //@ts-ignore
      window.removeEventListener("message", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <Loader /> : <>{children}</>;
};

export default SessionHelper;
