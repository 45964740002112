import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import styles from "styles/components/drawers/settingsDrawer.module.css";
import Typography from "components/Typography";
import Drawer from "components/Drawer";
import { DrawerContentHeight, DrawerPositions, ZIndexLevels } from "core/enums";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import { OddsDisplayEnum, useUpdateUserSettingsMutation } from "generated/graphql";
import { oddsDisplay } from "dummy/dummy";
import sessionHelper from "service/sessionHelper";
import { getLanguage, getVersion } from "util/miscUtils";
import appEventInstance from "util/eventHandler";
import _Error, { ErrorMessage } from "util/ErrHandler";
import { AppEventTypes } from "core/enums";

const SettingsDrawer: React.FC<{}> = () => {
  const { configData } = getConfig();
  const initialLanguage = getLanguage(sessionHelper.language, sessionHelper.location);
  const [selectedOption, setSelectedOption] = useState<string>(
    oddsDisplay.find((option) => option.label === initialLanguage)?.label || "",
  );
  const [settingOptions, setSettingOptions] = useState(configData.accordion);
  const [isChecked, setIsChecked] = useState(true);
  const [updateUserSettingsMutation] = useUpdateUserSettingsMutation();
  const { isOpenSettingsDrawer, updateDrawerStates, updateModalStates, isOpenGameRulesModal } = useGameStore(
    (state) => ({
      isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
      updateDrawerStates: state.updateDrawerStates,
      updateModalStates: state.updateModalStates,
      isOpenGameRulesModal: state.updateModalStates,
    }),
    shallow,
  );

  const [version, setVersion] = useState("");

  //In Local value will be hardcoded please ignore
  useEffect(() => {
    getVersion().then(setVersion);
  }, []);

  const handleClose = async () => {
    updateDrawerStates({ isOpenSettingsDrawer: false });
  };

  const updateSettings = async (updatedSettings: { oddsDisplay: OddsDisplayEnum; audio: boolean }) => {
    try {
      const resp = await updateUserSettingsMutation({
        variables: { input: updatedSettings },
      });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: errMsg?.message },
      });
    }
  };

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);

    await updateSettings({ oddsDisplay: newSelectedOption as OddsDisplayEnum, audio: isChecked });
  };

  const handleToggle = async () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);

    await updateSettings({ oddsDisplay: selectedOption as OddsDisplayEnum, audio: newIsChecked });
  };

  const handleAccordion = (index: number) => {
    let updatedOptions = [...settingOptions];
    let optionsDataIndex = settingOptions[index];
    optionsDataIndex.openContent = !optionsDataIndex.openContent;
    setSettingOptions(updatedOptions);
  };

  const toggleModal = () => {
    updateModalStates({ isOpenGameRulesModal: true });
  };

  return (
    <Drawer
      id="settingDetailsDrawer"
      onClose={handleClose}
      isOpen={isOpenSettingsDrawer}
      position={DrawerPositions.Top}
      showFooterButton={true}
      header={
        <div className={classNames(styles.header)}>
          <Typography fontFamily="degularBold" size="2xl">
            {configData?.settingsDrawer?.settings}
          </Typography>
        </div>
      }
      zIndexLevel={ZIndexLevels.Level2}
      contentHeight={DrawerContentHeight.Full}
    >
      <section className={styles.box}>
        <div className={classNames(styles.root)}>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {configData?.settingsDrawer?.oddsDisplay}
              </Typography>
            </div>
            {oddsDisplay?.map((item, index) => (
              <div key={index} className={classNames(styles.marginBtm, styles.borderOddsDisplay)}>
                <div className={classNames(styles.firstDiv)}>
                  <div className={classNames(styles.flexRow, { [styles.selected]: selectedOption === item.label })}>
                    <Typography fontFamily="avenirNextMedium">{item.label}</Typography>
                    <Typography fontFamily="avenirNextMedium">{`(${item.value})`}</Typography>
                  </div>
                  <label className={classNames(styles.radioContainer)}>
                    <input
                      type="radio"
                      name="settings"
                      value={item.label}
                      checked={selectedOption === item.label}
                      onChange={handleRadioChange}
                      className={classNames(styles.hiddenRadio)}
                    />
                    <div
                      className={classNames(styles.customRadio, {
                        [styles.customRadioChecked]: selectedOption === item.label,
                      })}
                    ></div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {configData?.settingsDrawer?.sounds}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)}>
              <Typography fontFamily="avenirNextMedium">{configData?.settingsDrawer?.audio}</Typography>
              <label className={styles.toggleSwitch}>
                <input type="checkbox" checked={isChecked} onChange={handleToggle} className={styles.hiddenInput} />
                <div
                  className={classNames(styles.slider, {
                    [styles.checked]: isChecked,
                  })}
                />
              </label>
            </div>
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold" size="md">
                {configData?.settingsDrawer?.help}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)}>
              <Typography fontFamily="avenirNextMedium">{configData?.settingsDrawer?.gameRules}</Typography>
              <img
                src="icon/backArrow.png"
                alt="backArrow"
                onClick={toggleModal}
                className={classNames(styles.arrowIcon)}
              />
            </div>
          </div>
          <div className={classNames(styles.flexCol)}>
            <img src="icon/exitButton.png" alt="exitButton" className={classNames(styles.exitButton)} />
            <Typography fontFamily="degularBold">{configData?.settingsDrawer?.exit}</Typography>
          </div>
        </div>
        <div className={styles.version}>
          <Typography fontFamily="degularMedium">{version}</Typography>
        </div>
      </section>
    </Drawer>
  );
};

export default React.memo(SettingsDrawer);
