import React, { PropsWithChildren, HTMLAttributes, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import Footer from "components/Footer";
import styles from "styles/components/appshell.module.css";
import Header from "components/Header";
import DrawerRenderer from "components/drawers/DrawerRenderer";
import ModalRenderer from "components/modals/ModalRenderer";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, RoutesEnum } from "core/enums";
import Typography from "components/Typography";
import useDisableZoom from "hooks/useDisableZoom";

type AppShellProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const AppShell: React.FC<AppShellProps> = ({ children, ...props }) => {
  const { pathname } = useLocation();
  const childrenRef = useRef<HTMLDivElement>(null);
  useDisableZoom(); // Disable zoom for all devices

  useEffect(() => {
    const setViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${viewportHeight * 0.01}px`);
    };

    // Initial setting of the --vh custom property
    setViewportHeight();

    // Recalculate the --vh value on window resize
    window.addEventListener("resize", setViewportHeight);

    const handleDrawerScrollToggle = (event: CustomEvent) => {
      const { detail } = event;
      const shouldDisableScroll = detail?.isScrollDisabled ?? false;

      if (childrenRef.current) {
        childrenRef.current.style.overflowY = shouldDisableScroll ? "hidden" : "auto";
      }
    };

    appEventInstance.listen(AppEventTypes.ToggleDrawerScroll, handleDrawerScrollToggle);

    return () => {
      appEventInstance.remove(AppEventTypes.ToggleDrawerScroll, handleDrawerScrollToggle);
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const noFooterRoutes = ["/team-matching", "/team-list"];
  const shouldRenderFooter = !noFooterRoutes.includes(pathname);

  const handleShowFooter = (path: string) => {
    switch (path) {
      case RoutesEnum.placeBet:
        return false;
      default:
        return true;
    }
  };
  return (
    <div
      className={classNames(styles.mainContainer, {
        // [styles.homeBg]: pathname === "/home",
        // [styles.pitchStadiumBg]: !shouldRenderFooter,  // for rendernig bg
      })}
      {...props}
    >
      <div className={classNames({ [styles.notify]: true })}>
        <Typography>Please rotate the device to play the game!</Typography>
      </div>
      <Header />
      <div className={classNames(styles.children)} ref={childrenRef}>
        {children}
      </div>
      <Footer showFooter={handleShowFooter(pathname)} />
      <DrawerRenderer />
      <ModalRenderer />
    </div>
  );
};

export default AppShell;
