import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import useGameStore from "store/gameStore";
import Loader from "components/Loader";
import classNames from "classnames";
import { CanvasView } from "core/enums";
import styles from "styles/components/pixiContainer.module.css";
import sessionHelper from "service/sessionHelper";

interface CanvasProps {
  view?: CanvasView | null;
}

const PixiContainer: React.FC<CanvasProps> = ({ view = CanvasView.teamView }) => {
  const { canvasReady } = useGameStore(
    (state) => ({
      canvasReady: state.canvasReady,
    }),
    shallow,
  );

  useEffect(() => {
    //@ts-ignore
    if (window?.initPixi && canvasReady && view) {
      //Delaying the init call beacuase dimension fetching was incorrect
      setTimeout(() => {
        const _getParentDimensions = () => {
          let canvasStage = document.getElementById("gameStage");
    
          return { height: canvasStage?.offsetHeight, width: canvasStage?.offsetWidth };
        };

        //@ts-ignore
        window?.initPixi({
          view,
          canvasDimension: _getParentDimensions(),
          initData: sessionHelper.initData,
          urls: { gql: process.env.REACT_APP_GRAPHQL_API_URL, socket: process.env.REACT_APP_SOCKET_URL },
        });
      }, 100);
    }

    return () => {
      //@ts-ignore
      if (window?.unmountScreen) {
        //@ts-ignore
        // window?.unmountScreen({ event: "UNMOUNT", view });// temporary coment for dev purpose
      }
    };
  }, [canvasReady, view]);

  return (
    <div className={classNames(styles.iframeContainer, {})}>
      {canvasReady && view ? <div id="gameStage" className="h-full w-full" /> : <Loader />}
    </div>
  );
};

export default React.memo(PixiContainer);
