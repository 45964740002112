import { InputHTMLAttributes, PropsWithChildren, useState } from "react";
import classNames from "classnames";
import styles from "styles/components/textFeild.module.css";
import Typography from "components/Typography";
import { extractCurrencySymbol } from "util/miscUtils";

type Props = PropsWithChildren<
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    topLabel?: string;
    labelColor?: string;
    border?: string;
    displayFlex?: boolean;
    value?: string | number;
    disabled?: boolean;
    addBorder?: boolean;
    isHideInsideLabel?: boolean;
    isHideTopLabel?: boolean;
    noDefaultKeypad?: boolean;
    isHideErrorLabel?: boolean;
    isStakeInputHeight?: boolean;
    isLargeText?: boolean;
    backGroundColor?: string;
    maxLength?: number;
    labelPosition?: string;
    valueColor?: string;
    borderColor?: string;
    errorLabel?: string;
    startIcon?: string; // Allowing JSX elements or HTML elements
    endIcon?: React.ReactNode; // Allowing JSX elements or HTML elements
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
>;

const TextFeild = ({
  disabled = false,
  value = "",
  addBorder = false,
  label = "default",
  topLabel = "",
  onChange,
  borderColor = "",
  name = "",
  labelColor = "white",
  backGroundColor = "#2F2541",
  errorLabel = "",
  isHideInsideLabel = false,
  isHideTopLabel = true,
  isLargeText = false,
  isStakeInputHeight = false,
  isHideErrorLabel = true,
  valueColor = "",
  startIcon = '',
  endIcon = null,
  noDefaultKeypad = false,
}: Props) => {
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    input.value = input.value.replace(/[^a-zA-Z0-9\s']/g, ""); // Remove non-alphanumeric characters
    onChange?.(event as unknown as React.ChangeEvent<HTMLInputElement>); // Trigger onChange if needed
  };

  return (
    <section className={classNames(styles.root)}>
      <Typography hide={isHideTopLabel} fontFamily="degularBold" size="xl" position="Left">
        {topLabel}
      </Typography>
      <div
        className={classNames(styles.subRoot, {
          [styles.addBorder]: addBorder,
          [styles.purpleBlueBorder]: borderColor === "purpleBlue",
          [styles.lightGrayBorder]: borderColor === "lightGray",
          [styles.lightGrayBg]: backGroundColor === "lightGrayBg",
          [styles.addTopSpacing]: !isHideTopLabel,
          [styles.stakeInputHeight]: isStakeInputHeight,
        })}
      >
        <Typography hide={isHideInsideLabel} fontFamily="avenirNextMedium" size="xs" color={labelColor} position="Left">
          {label}
        </Typography>
        <div className={classNames(styles.inputContainer)}>
          <input
            className={classNames(styles.input, {
              [styles.purpleBlue]: valueColor === "purpleBlue",
              [styles.purpleBlue500]: valueColor === "purpleBlue500",
              [styles.textLg]: isLargeText && value.toLocaleString().length > 6,
            })}
            autoComplete="off"
            onChange={onChange}
            onInput={handleInput}
            disabled={disabled}
            type="text"
            name={name}
            value={startIcon === "currency" ? `${extractCurrencySymbol("£")}${value}` : value}
            maxLength={10}
            autoFocus
            readOnly={noDefaultKeypad}
          />
          {/* need to configure endIcon depending on future use cases UI*/}
          <div className={classNames(styles.endIcon, { [styles.hide]: !startIcon })}>{endIcon}</div>
        </div>
      </div>
      <Typography fontFamily="degularBold" size="xs" position="Left" hide={isHideErrorLabel}>
        {errorLabel}
      </Typography>
    </section>
  );
};

export default TextFeild;
