import { AppEventTypes, CanvasEvents, RoutesEnum, CanvasView } from "core/enums";
import { FTUData, MyTeamsResponse } from "core/interfaces/home";
import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import { useMyTeamQuery } from "generated/graphql";
import React, { useEffect } from "react";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import sessionHelper from "service/sessionHelper";
import appEventInstance from "util/eventHandler";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const { myTeamResponse, setMyTeamResponse, isOpenTeamNameDrawer, closeAllDrawers } = useGameStore(
    (state) => ({
      myTeamResponse: state.myTeamResponse,
      setMyTeamResponse: state.setMyTeamResponse,
      isOpenTeamNameDrawer: state.drawerStates.isOpenTeamNameDrawer,
      closeAllDrawers: state.closeAllDrawers,
    }),
    shallow,
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);

  const { data: myTeamData, refetch } = useMyTeamQuery({
    skip: !sessionHelper?.beToken || isOpenTeamNameDrawer || !!myTeamResponse,
    onCompleted: (data) => {
      console.log("object", data);
      if (data) {
        console.log(data);
        _sendInfoToCanvas(data);
      }
    },
  });

  const _handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;
    switch (detail.messageName) {
      case CanvasEvents.showMyTeam:
        navigate(`${RoutesEnum.Team}${location.search}`);
        break;
      case CanvasEvents.showVault:
        navigate(`${RoutesEnum.vault}${location.search}`);
        break;
      default:
    }
  };

  const _sendInfoToCanvas = (data: any) => {
    //@ts-ignore
    // if (window?.gameExtension) {
    //   //@ts-ignore
    //   window.gameExtension({ type: CanvasMessage.teamData, value: data?.teamManager?.myTeam });
    // }

    setMyTeamResponse(data?.teamManager?.myTeam);
    setLoading(false);
  };

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, _handleCanvasEvent);

    if (!!myTeamResponse) setLoading(false);

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
      closeAllDrawers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentShell loading={isOpenTeamNameDrawer || loading}>
      <PixiContainer view={CanvasView.home} />
    </ContentShell>
  );
};

export default Home;
