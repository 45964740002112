import classNames from "classnames";
import { shallow } from "zustand/shallow";
import Typography from "components/Typography";
import { CanvasMessage } from "core/enums";
import getConfig from "util/configHelper";
import Drawer from "components/Drawer";
import { DrawerPositions, DrawerSizes, DrawerBgType } from "core/enums";
import { useFtuNationalityQuery, useFtuUpdateMutation } from "generated/graphql";
import useGameStore from "store/gameStore";
import styles from "styles/components/drawers/selectNationalityDrawer.module.css";

const SelectNationalityDrawer = () => {
  const { configData } = getConfig();
  const [ftuUpdateMutation] = useFtuUpdateMutation();

  const { isOpenSelectNationality, updateDrawerStates, initialUserDetails, setInitialUserDetails } = useGameStore(
    (state) => ({
      isOpenSelectNationality: state.drawerStates.isOpenSelectNationality,
      updateDrawerStates: state.updateDrawerStates,
      initialUserDetails: state.initialUserDetails,
      setInitialUserDetails: state.setInitialUserDetails,
    }),
    shallow,
  );

  const handleClose = async () => {
    let resp = await ftuUpdateMutation({
      variables: { input: { name: initialUserDetails.name, country: initialUserDetails.country } },
    });

    //@ts-ignore
    if (window?.gameExtension) {
      //@ts-ignore
      window.gameExtension({ type: CanvasMessage.ftuData, value: resp?.data?.teamManager?.ftuUpdate });
    }
    updateDrawerStates({ isOpenPackSummary: true });
  };

  const handleNationality = (nation: any) => {
    setInitialUserDetails({ country: nation });
  };

  const { data, loading } = useFtuNationalityQuery({
    skip: !isOpenSelectNationality,
  });

  return (
    <Drawer
      id="selectNationalityDrawer"
      onClose={handleClose}
      isOpen={isOpenSelectNationality}
      showFooterButton={true}
      drawerSize={DrawerSizes.Xl}
      drawerBgType={DrawerBgType.SelectNationality}
      position={DrawerPositions.Top}
      footerButtonText="Continue"
      loading={loading}
    >
      <div className={classNames(styles.nationalityRoot)}>
        <Typography fontFamily="degularSemiBold" size="xl">
          {configData?.ftuiPages?.selectNationlaity}
        </Typography>
        <div className={classNames(styles.scrollableList)}>
          {data?.teamManager?.ftuNationality.map((nation, index) => (
            <div key={index} className={classNames(styles.flexRow)} onClick={() => handleNationality(nation.name)}>
              <img src={`${nation.imageUrl}`} alt={nation.name} className={classNames(styles.flagImg)}></img>
              <Typography wrap={true} fontFamily="degularSemiBold" size="2xl">
                {nation.name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default SelectNationalityDrawer;
