import { InitResponse, Odds } from "generated/graphql";

class SessionHelper {
  beToken = "";
  balance = 0;
  pbfCoin = 0;
  isFTU = "";
  operatorId = "";
  language = "";
  location = ""; // _id = null;
  initData: InitResponse | undefined;
  oddsDetails: Odds | undefined;
  badge = "";
  kit = "";
  kitColor = "";
  kitColorDark = "";
  extraData = {}

  //@ts-ignore
  onSignIn({ beToken, balance, pbfCoin, isFTU, operatorId, language, location, initData,  badge, kit ,kitColor ,kitColorDark ,extraData }) {
    this.beToken = beToken;
    this.balance = balance;
    this.pbfCoin = pbfCoin;
    this.isFTU = isFTU;
    this.operatorId = operatorId;
    this.language = language;
    this.location = location;
    this.initData = initData;
    this.badge = badge || "";
    this.kit = kit || "";
    this.kitColor = kitColor || "";
    this.kitColorDark = kitColorDark || "";
    this.extraData = extraData || {}
  }

  updateOddDetails(oddsDetails: Odds) {
    this.oddsDetails = oddsDetails as Odds;
  }
}

export default new SessionHelper();
