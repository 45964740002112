import { ReactElement } from "react";

import Home from "pages/home/Home";
import Demo from "pages/demo/Demo";
import Store from "pages/store/Store";
import Team from "pages/team/Team";
import Bets from "pages/bets/Bets";
import TeamMatching from "pages/teamSelect/TeamMatching";
import MatchResult from "pages/matchResult/MatchResult";
import Playground from "pages/playground/Playground";
import { RoutesEnum } from "core/enums";
import StakePannelOptions from "pages/stakePannelOptions/StakePannelOptions";
import NotFound from "pages/notFound/NotFound";
import Vault from "pages/vault/Vault";

interface RouteConfig {
  path: RoutesEnum;
  element: ReactElement;
}

export const routes: RouteConfig[] = [
  {
    path: RoutesEnum.Home,
    element: <Home />,
  },
  {
    path: RoutesEnum.Demo,
    element: <Demo />,
  },
  {
    path: RoutesEnum.Team,
    element: <Team />,
  },
  {
    path: RoutesEnum.placeBet,
    element: <StakePannelOptions />,
  },
  {
    path: RoutesEnum.NotFound,
    element: <NotFound />,
  },
  // {
  //   path: RoutesEnum.Store,
  //   element: <Store />,
  // },
  {
    path: RoutesEnum.Bets,
    element: <Bets />,
  },
  {
    path: RoutesEnum.TeamMatching,
    element: <TeamMatching />,
  },
  {
    path: RoutesEnum.playground,
    element: <Playground />,
  },
  {
    path: RoutesEnum.MatchResult,
    element: <MatchResult />,
  },
  {
    path: RoutesEnum.vault,
    element: <Vault />,
  },
];
