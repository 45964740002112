import classNames from "classnames";
import { home } from "dummy/dummy";
import Typography from "components/Typography";
import { formatCurrentTime } from "util/miscUtils";
import styles from "styles/components/userDetails.module.css";

const UserDetails = () => {
  return (
    <div className={classNames(styles.userContainer)}>
      <div className={classNames(styles.innerDiv)}>
        <img src="icon/home/dotsIcon.png" alt="dotsIcon" className={classNames(styles.dotsIcon)} />
        <div className={classNames(styles.heading)}>
          <img src="images/home/logo.png" alt="home heading Icon" />
        </div>
        <div className={classNames(styles.flexRow)}>
          <img src="icon/home/profile.png" alt="profile" className={classNames(styles.profile)} />
          <Typography fontFamily="degularSemiBold" size="md">{home?.users}</Typography>
          <Typography fontFamily="degularSemiBold" size="md">{formatCurrentTime({})}</Typography>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
