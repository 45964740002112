import React, { ButtonHTMLAttributes, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import Typography from "components/Typography";
import classNames from "classnames";
import Button from "components/Button";
import TextFeild from "components/TextFeild";
import { AppEventTypes } from "core/enums";
import NumbersKeypad from "components/NumbersKeypad";
import styles from "styles/components/stakePannelInput.module.css";
import appEventInstance from "util/eventHandler";
import useGameStore from "store/gameStore";
import { debug, extractCurrencySymbol } from "util/miscUtils";
import Vault from "../../../components/Vault";
import getConfig from "util/configHelper";
import { debounce, setNumberFormat } from "util/miscUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesEnum } from "core/enums";
import sessionHelper from "service/sessionHelper";
import useHandleAddUpdateOdds from "../../../hooks/useHandleAddUpdateOdds";
import { useBetsMutation, useCurrentBetSlipLazyQuery, usePlayersMutation } from "generated/graphql";
import Loader from "components/Loader";

interface StakePannelInputProps {
  updateBetPannelOptions?: (data: Record<string, any>) => void; // Update function signature to accept an object
}

const StakePannelInput: React.FC<StakePannelInputProps> = ({ updateBetPannelOptions }) => {
  const { configData } = getConfig();

  const {
    udpateModalStates,
    selectedOddsOptions,
    updateStakeById,
    setSelectedOddsOptions,
    activeOddsOptions,
    setActiveOddsOptions,
  } = useGameStore(
    (state) => ({
      udpateModalStates: state.updateModalStates,
      selectedOddsOptions: state.selectedOddsOptions,
      activeOddsOptions: state.activeOddsOptions,
      setActiveOddsOptions: state.setActiveOddsOptions,
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      updateStakeById: state.updateStakeById,
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
    }),
    shallow,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [playAnimation, setPlayAnimation] = useState(configData.stakePannelAnimationViews.selectedOddsInputsView);
  const [showKeypad, setShowKeyPad] = useState(false);
  const [isBetPlaced, setIsBetPlaced] = useState(false);
  const [activeInputId, setActiveInputId] = useState({ key: "", oddId: "", betId: "" });
  let _singleOddReturnVal = useRef<string | undefined>();

  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();
  debug({ event: "calling", message: "Stake Pannel inputs " });

  const [currentStakeSlipsData, { data, error: isCurrentStakeSlipError, loading: isCurrentStakeSlipsLoading }] =
    useCurrentBetSlipLazyQuery();

  const [playersMutation, { data: startMatchData, loading: isStartMatchLoading, error: startMatchError }] =
    usePlayersMutation();

  const debouncedApiCall = useCallback(
    debounce(async (oddId: string, betId: string, stake: number) => {
      // console.log("debounced stake : ", { oddId, betId, stake });
      let updateStakeSlip = await handleBoxClick(oddId, configData.bets.updateStakeSlip, [], betId, stake);
    }, 1000), // Delay
    [],
  );

  const handleKeyClick = async (key: string | number) => {
    // Find the active option using the activeInputId

    const activeOption = selectedOddsOptions[activeInputId?.key]?.find(
      (option) => option.oddId === activeInputId.oddId,
    );

    if (!activeOption) return; // Exit if no matching option is found

    // Raw stake value (unformatted)
    let stakeString = activeOption.betAmount?.toString() || "0";

    // console.log({ key });

    // Handle backspace ("icon") - remove last character
    if (key === "icon") {
      stakeString = stakeString.slice(0, -1) || "0"; // Default to "0" if empty
      updateStakeById(activeInputId.key, activeInputId.oddId, stakeString);
      debouncedApiCall(activeInputId.oddId, activeInputId.betId, Number(stakeString));
      return;
    }

    // Handle decimal point input
    if (key === "decimal") {
      if (!stakeString.includes(".")) {
        stakeString += ".";
        updateStakeById(activeInputId.key, activeInputId.oddId, stakeString);
      }
      return;
    }

    // Replace "0" with the new number if the current value is "0" and no decimal
    if (stakeString === "0" && key !== "decimal") {
      stakeString = "";
    }

    // Append the new key input (number) to the stake string
    const newStakeString = stakeString + key;

    // Validate: Ensure only two decimal places
    if (newStakeString.includes(".")) {
      const [integerPart, decimalPart] = newStakeString.split(".");
      if (decimalPart?.length > 2) {
        return; // Reject input if decimal exceeds two digits
      }
    }

    // Cap the value at 999
    const newStake = parseFloat(newStakeString);
    const cappedStake = Math.min(newStake, 10000); // move max stake to session helper
    if (calculateTotalReturnValue() >= 2000) {
      udpateModalStates({ isOpenMinMaxStakeModal: true });
    }

    // Update the raw value
    updateStakeById(activeInputId.key, activeInputId.oddId, cappedStake.toString());
    debouncedApiCall(activeInputId.oddId, activeInputId.betId, cappedStake);
  };

  const handleOptionClick = (defaultKey: string | number) => {
    updateStakeById(activeInputId.key, activeInputId.oddId, defaultKey as string);
    debouncedApiCall(activeInputId.oddId, activeInputId.betId, Number(defaultKey));
  };

  const handleInputChange = ({ value, name }: { value: string; name: string }) => {
    // console.log("Input Name:", name, value); // Access the input name as needed
  };

  const [betsMutation, { data: placeBetData, loading: isPlaceBetLoading, error: isPlaceBetError }] = useBetsMutation({
    variables: {},
  });

  // handling both start and place bet functionality here
  const handlePlaceBet = async () => {
    if (isPlaceBetLoading) return;
    setShowKeyPad(false);
    if (isBetPlaced) {
      // if bet place is true then just start match........
      try {
        let resp = await playersMutation();
        if (resp.data?.players?.startMatch?.matchId) {
          navigate(`${RoutesEnum.playground}${location.search}`);
        } else {
          appEventInstance.dispatch({
            eventType: AppEventTypes.toggleErrorModal,
            payload: { msg: "Error Starting Match !" },
          });
        }
      } catch (err) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: "Error Starting Match !" },
        });
      }
    } else {
      if (SelectedOddsOptionsCount === 0) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: "Please Add Bet." },
        });
        return;
      }
      try {
        let placeBet = await betsMutation();
        let placeBetResp = placeBet?.data?.players?.placeBet?.bets;
        if (placeBetResp?.length) {
          let resp = await currentStakeSlipsData();
          let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
          setIsBetPlaced(true);
          setActiveOddsOptions(totalCurrentStakeSlipAval as any); // store all the  reponse except added_to_slip
          setPlayAnimation(configData.stakePannelAnimationViews.betPlaceView);
        } else {
          appEventInstance.dispatch({
            eventType: AppEventTypes.toggleErrorModal,
            payload: { msg: "Error Placing Bet !." },
          });
        }
      } catch (err) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: `err placing Bet : ${error?.message}` },
        });
      }
    }
  };

  const handleAddMoreOddsSlips = async () => {
    setIsBetPlaced(false);
    setPlayAnimation(configData.stakePannelAnimationViews.selectedOddsInputsView);
    try {
      let resp = await currentStakeSlipsData();
      let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
      setSelectedOddsOptions(totalCurrentStakeSlipAval as any, "reset"); // store only added_to_slip
    } catch (err) {
      console.error(error);
    }
  };

  // to show and undo the place bet btn and XP showing ...
  const checkIsAnyStakeUpdated = () => {
    for (let key in selectedOddsOptions) {
      if (isBetPlaced) {
        return true;
      }
      if (selectedOddsOptions[key].some((item) => item.betAmount && Number(item.betAmount) >= 0.1)) {
        return true; // Return true if any betAmount is greater than threshold
      }
    }
    return false;
  };

  const handleUserInteraction = async (id: string, marketName: string) => {
    try {
      if (
        marketName === configData.gamePannelOptionsHeading.firstGoalScorer ||
        marketName === configData.gamePannelOptionsHeading.lastGoalScorer ||
        marketName === configData.gamePannelOptionsHeading.AnyTimeGoalScorer
      ) {
        marketName = configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorer;
      }
      let resp = await handleBoxClick(id, marketName, [...selectedOddsOptions[marketName]]);
      setSelectedOddsOptions(resp || [], marketName);
    } catch (e) {}
  };

  const calculateTotalBetAmount = () => {
    // Use reduce to sum the betAmount values from each array under the keys
    return Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return sum + arr.reduce((innerSum, item) => innerSum + Number(item.betAmount), 0);
    }, 0);
  };

  const calculateTotalReturnValue = () => {
    return Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return sum + arr.reduce((innerSum, item) => innerSum + Number(item.betAmount) * item.decimalOdd, 0);
    }, 0);
  };

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  const getBoostPercentage = (decimalOdd: number) => {
    if (decimalOdd > 11) return 100;
    if (decimalOdd > 6) return 40;
    if (decimalOdd > 3) return 20;
    return 0;
  };

  // functionality for which stake should take the numbers key values
  const handleSetActiveInputId = (oddId: string, marketName: string, betId: string) => {
    setShowKeyPad(true);
    // setPlayAnimation(configData.stakePannelAnimationViews.selectedOddsInputsAndKeypadView);
    // console.log({ marketName, oddId });

    // set market name as box heding rather than setting first , last , anyTime Goal scorer for activating the bet input which is active with bet id ...
    if (
      marketName === configData.gamePannelOptionsHeading.firstGoalScorer ||
      marketName === configData.gamePannelOptionsHeading.lastGoalScorer ||
      marketName === configData.gamePannelOptionsHeading.AnyTimeGoalScorer
    ) {
      marketName = configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorer;
    }
    if (oddId) {
      // console.log({ id: oddId, key: marketName });
      setActiveInputId({ oddId, key: marketName, betId });
    }
  };

  const getPlayerName = (playerId: number | string) => {
    let allPlayers = sessionHelper.oddsDetails?.allPlayers;
    let playerDetails = allPlayers?.filter((ele, index) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return "player not found.";
    }
  };

  let renderedCount = 0;
  let SelectedOddsOptionsCount = Object.values(selectedOddsOptions).reduce((sum, arr) => sum + arr.length, 0);

  return (
    <section
      className={classNames(styles.selectedStakeOptionsRoot, {
        [styles.hide]: SelectedOddsOptionsCount === 0 ? true : false,
      })}
    >
      <div
        className={classNames(styles.animationRoot, {
          [styles.addPaddingtop]: !isBetPlaced,
          [styles.addPaddingBottomg]: !showKeypad,
          [styles.selectedOddsInputsView]:
            playAnimation === configData.stakePannelAnimationViews.selectedOddsInputsView,
          [styles.selectedOddsInputsAndKeypadView]: showKeypad,
          [styles.selectedOddsLabelsView]:
            playAnimation === configData.stakePannelAnimationViews.selectedOddsLabelsView,
          [styles.betPlaceView]: playAnimation === configData.stakePannelAnimationViews.betPlaceView,
        })}
      >
        <Vault hide={!isBetPlaced} />
        {/* bet place animation bar  */}
        <div
          className={classNames(styles.betPlaceBar, {
            [styles.hide]: !isBetPlaced,
            [styles.slideFromLeft]: true,
          })}
        >
          <img src="/images/bets/active.png" alt="active btn" />
          <div className={styles.flexDirectionCol}>
            <Typography fontFamily="avenirNextSemiBold" size="lg">
              {configData.betPannelWords.betPlaced}
            </Typography>
          </div>
        </div>

        {/* selected options lables counter with market names*/}
        <div
          className={classNames(styles.selectedStakeContainer, {
            [styles.hide]: playAnimation !== configData.stakePannelAnimationViews.selectedOddsLabelsView,
          })}
        >
          <div className={classNames(styles.counterContainer)}>
            <div className={classNames(styles.counter)}>
              <Typography size="md" fontFamily="avenirNextBold" color="green">
                {SelectedOddsOptionsCount}
              </Typography>
            </div>
            <Typography size="md" fontFamily="avenirNextBold" color="black">
              {SelectedOddsOptionsCount > 1
                ? configData.betPannelWords.selections
                : configData.betPannelWords.selection}
            </Typography>
          </div>
          <div className={classNames(styles.teamListContainer)}>
            {Object.entries(selectedOddsOptions).map(([key, values], itemsIndex) => {
              const filteredValues = values.filter((value) => value?.betStatus === configData.bets.addedToSlip);

              // Calculate how many items can still be rendered
              const remainingSlots = 3 - renderedCount;

              // If no slots are remaining, skip rendering and return "..."
              if (remainingSlots <= 0) {
                return null;
              }

              // Slice the filtered values to ensure only the remaining slots are considered
              const valuesToRender = filteredValues.slice(0, remainingSlots);

              renderedCount += valuesToRender.length; // Update the rendered count

              // Determine if the total length exceeds 3 to show "..."
              const isMoreThanThree =
                Object.values(selectedOddsOptions).flatMap((val) =>
                  val.filter((v) => v?.betStatus === configData.bets.addedToSlip),
                ).length > 3;

              return (
                <React.Fragment key={`${key}-${itemsIndex}`}>
                  {valuesToRender.map((value) => (
                    <div key={`${value?.marketName}-${value?.betId}`} className={classNames(styles.selectedTeamList)}>
                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.finalResult}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)}
                      </Typography>
                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.goalsOverUnder}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {value.oddsDetails.type &&
                          value.oddsDetails.type.charAt(0).toUpperCase() + value.oddsDetails.type.slice(1)}{" "}
                        {value.oddsDetails.goals}
                      </Typography>
                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.bothTeamsToScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {value.oddsDetails.value}
                      </Typography>

                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.resultBothTeamsScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)} / {value.oddsDetails.value}
                      </Typography>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketName !== configData.gamePannelOptionsHeading.correctScore}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                        </Typography>
                        <Typography
                          hide={value.marketName !== configData.gamePannelOptionsHeading.correctScore}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.win as string)}
                        </Typography>
                      </div>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketType !== configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorerType}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {getPlayerName(value.oddsDetails.playerId)}
                        </Typography>
                        <Typography
                          hide={value.marketType !== configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorerType}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.teamId as string)}
                        </Typography>
                      </div>

                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.handicaps}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)} {value.oddsDetails.handicap}
                      </Typography>

                      <Typography
                        hide={value.marketName !== configData.gamePannelOptionsHeading.firstGoalScorerCorrectScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getPlayerName(value.oddsDetails.playerId)} /{" "}
                        {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                      </Typography>
                    </div>
                  ))}
                  {isMoreThanThree && renderedCount >= 3 && (
                    <Typography color="gray" fontFamily="degularBold" size="xl" className={styles.moreText}>
                      ...
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        {/* arrow down  */}
        <div
          className={classNames(styles.arrow, {
            [styles.hide]: isBetPlaced,
            [styles.centerFlatArrowDown]: playAnimation === configData.stakePannelAnimationViews.selectedOddsLabelsView,
          })}
        >
          <img
            src="icon/flatArrowDown.png"
            alt="flatArrowDown"
            onClick={() => {
              if (sessionHelper.isFTU) {
                return;
              }
              if (showKeypad) {
                setShowKeyPad(!showKeypad);
              }
              if (playAnimation === configData.stakePannelAnimationViews.selectedOddsInputsView) {
                setPlayAnimation(configData.stakePannelAnimationViews.selectedOddsLabelsView);
              } else {
                setPlayAnimation(configData.stakePannelAnimationViews.selectedOddsInputsView);
              }
            }}
          />
        </div>
        {/* arrow down  end*/}

        <div
          className={classNames(styles.row, {
            [styles.hide]: playAnimation === configData.stakePannelAnimationViews.selectedOddsLabelsView,
          })}
        >
          <section className={classNames(styles.stakeInputsContainer)}>
            {Object.entries(isBetPlaced ? activeOddsOptions : selectedOddsOptions).map(([key, values], oddsIndex) => (
              <React.Fragment key={key}>
                {values.map((item, index) => {
                  if (SelectedOddsOptionsCount === 1) {
                    _singleOddReturnVal.current = setNumberFormat(
                      (item.betAmount && item.decimalOdd * Number(item.betAmount))?.toLocaleString() ?? 0,
                      2,
                    );
                  }
                  return (
                    <div key={`${item.betId}-${index}`} className={classNames(styles.stakeInputsList)}>
                      <div
                        className={classNames(styles.col, {
                          [styles.addPaddingtop]: isBetPlaced,
                        })}
                      >
                        <Typography
                          color="purpleBlue700"
                          size={item.marketName.length > 25 ? "xs" : item.marketName.length > 20 ? "md" : "md2"}
                          fontFamily="avenirNextMedium"
                        >
                          {item.marketName}
                        </Typography>
                      </div>
                      {/* selected market list below with stake pannel inputs ..... */}
                      <div className={classNames(styles.col)}>
                        <div>
                          <div className={classNames(styles.flexSubCol, styles.addGap4)}>
                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]: item.marketName !== configData.gamePannelOptionsHeading.finalResult,
                              })}
                            >
                              {" "}
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {getTeamName(item.oddsDetails.win as string)}
                              </Typography>
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.odd}
                              </Typography>
                            </div>

                            {/* ================= */}
                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]: item.marketName !== configData.gamePannelOptionsHeading.goalsOverUnder,
                              })}
                            >
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.oddsDetails.type &&
                                  item.oddsDetails.type.charAt(0).toUpperCase() + item.oddsDetails.type.slice(1)}
                              </Typography>
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.oddsDetails.goals}
                              </Typography>{" "}
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.odd}
                              </Typography>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]: item.marketName !== configData.gamePannelOptionsHeading.bothTeamsToScore,
                              })}
                            >
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.oddsDetails.value}
                              </Typography>{" "}
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.odd}
                              </Typography>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]:
                                  item.marketName !== configData.gamePannelOptionsHeading.resultBothTeamsScore,
                              })}
                            >
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {getTeamName(item.oddsDetails.win as string)} / {item.oddsDetails.value}
                              </Typography>
                              <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                {item.odd}
                              </Typography>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]: item.marketName !== configData.gamePannelOptionsHeading.correctScore,
                                [styles.flexColDirection]: true,
                              })}
                            >
                              <div>
                                {" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.oddsDetails.team1} - {item.oddsDetails.team2}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md2">
                                  {item.odd}
                                </Typography>
                              </div>
                              <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                {getTeamName(item.oddsDetails.win as string)}
                              </Typography>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]:
                                  item.marketType !==
                                  configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorerType,
                                [styles.flexColDirection]: true,
                              })}
                            >
                              <div>
                                {" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {getPlayerName(item.oddsDetails.playerId)}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.odd}
                                </Typography>
                              </div>
                              <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                {getTeamName(item.oddsDetails.teamId as string)}
                              </Typography>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]: item.marketName !== configData.gamePannelOptionsHeading.handicaps,
                                [styles.flexColDirection]: true,
                              })}
                            >
                              <div>
                                {" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {getTeamName(item.oddsDetails.win as string)}
                                </Typography>{" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {` ${item.oddsDetails.handicap}`}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.odd}
                                </Typography>
                              </div>
                            </div>

                            {/* ================= */}

                            <div
                              className={classNames(styles.marketDetails, {
                                [styles.hide]:
                                  item.marketName !== configData.gamePannelOptionsHeading.firstGoalScorerCorrectScore,
                                [styles.flexColDirection]: true,
                              })}
                            >
                              <div>
                                {" "}
                                <Typography
                                  color="purpleBlue"
                                  fontFamily="avenirNextBold"
                                  size={getPlayerName(item.oddsDetails?.playerId).length > 10 ? "md2" : "md"}
                                  noWrap={true}
                                >
                                  {getPlayerName(item.oddsDetails?.playerId)} /
                                  {`${item.oddsDetails.team1}-${item.oddsDetails.team2}`}
                                </Typography>
                                <Typography
                                  color="purpleBlue"
                                  fontFamily="avenirNextBold"
                                  size={item.odd.length > 4 ? "md2" : "md"}
                                >
                                  {item.odd}
                                </Typography>
                              </div>
                              <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                {getTeamName(item.oddsDetails.teamId as string)}
                              </Typography>
                            </div>
                          </div>
                          {/* change the versus when you get from BE */}
                          {/* <Typography hide={!item.boxSubLabel} color="purpleBlue" fontFamily="avenirNext" size="md"> */}
                          <Typography color="purpleBlue700" fontFamily="avenirNextMedium" size="xs2">
                            {sessionHelper?.oddsDetails?.teamA?.name} {configData.bets.v}
                            {sessionHelper?.oddsDetails?.teamB?.name}
                          </Typography>{" "}
                          <div
                            className={classNames(styles.flexSubCol, styles.addTopSpacing, {
                              [styles.visibility]: item.betAmount && Number(item?.betAmount) >= 0.1 ? false : true,
                            })}
                          >
                            <Typography noWrap={true} color="purple" fontFamily="avenirNextBold" size="xs1">
                              {/* update this once xpBoostPercentage comes in the current Bet Slip  */}
                              {getBoostPercentage(item?.decimalOdd) > 0 &&
                                `${getBoostPercentage(item?.decimalOdd as any)}% ${configData.bets.vaultXpBoost}`}
                              {item.betAmount &&
                              sessionHelper?.oddsDetails?.xpToAward &&
                              getBoostPercentage(item?.decimalOdd as any) >= 20
                                ? setNumberFormat(
                                    (
                                      item.betAmount * sessionHelper?.oddsDetails?.xpToAward +
                                      (item.betAmount *
                                        sessionHelper?.oddsDetails?.xpToAward *
                                        getBoostPercentage(item?.decimalOdd)) /
                                        100
                                    ).toLocaleString() ?? 0,
                                    0,
                                  )
                                : item.betAmount &&
                                  sessionHelper?.oddsDetails?.xpToAward &&
                                  setNumberFormat(
                                    (item.betAmount * sessionHelper?.oddsDetails?.xpToAward).toLocaleString() ?? 0,
                                    0,
                                  )}
                              XP
                            </Typography>
                            <img
                              src="icon/info.png"
                              alt="flatArrowDown"
                              onClick={() => {
                                udpateModalStates({ isOpenStakeInfoModal: true });
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className={classNames(styles.inputWidth, {
                            [styles.hide]: isBetPlaced,
                          })}
                          onClick={() => handleSetActiveInputId(item.oddId, item.marketName, item.betId)}
                        >
                          <TextFeild
                            valueColor={item?.betAmount == 0 ? "purpleBlue500" : "purpleBlue"}
                            isStakeInputHeight={true}
                            isLargeText={item?.betAmount ? item?.betAmount?.toLocaleString().length > 6 : false}
                            startIcon={"currency"}
                            labelColor={"purpleBlue700"}
                            //@ts-ignore
                            borderColor={activeInputId.oddId === item?.oddId ? "purpleBlue" : "lightGray"}
                            label={configData.betPannelWords.stake}
                            //@ts-ignore
                            value={
                              item?.betAmount && Number(item?.betAmount) >= 0.1
                                ? setNumberFormat(item?.betAmount?.toLocaleString("en-IN") ?? 0, 2)
                                : "0.00"
                            }
                            backGroundColor="lightGrayBg"
                            onChange={() => handleInputChange}
                            noDefaultKeypad={true}
                            placeholder="0.00"
                          />
                          <div>
                            <Typography
                              hide={
                                item?.betAmount && Number(item.betAmount) > 0.1 && SelectedOddsOptionsCount !== 1
                                  ? false
                                  : true
                              }
                              color="purpleBlue700"
                              size={item?.betAmount && item?.betAmount > 10 ? "xs2" : "xs"}
                              fontFamily="avenirFont"
                              noWrap={true}
                            >
                              {configData.betPannelWords.toReturn} £
                              {setNumberFormat(
                                (item.betAmount && item.decimalOdd * Number(item.betAmount))?.toLocaleString() ?? 0,
                                2,
                              )}
                            </Typography>
                          </div>
                        </div>
                        <img
                          className={classNames(styles.closeIcon, {
                            [styles.hide]: isBetPlaced,
                          })}
                          src="icon/grayClose.png"
                          alt="grayClose"
                          onClick={() => {
                            if (sessionHelper.isFTU) return;
                            handleUserInteraction(item?.oddId, item.marketName);
                          }} // basically to check and remove the id Obj
                        />
                        <section
                          className={classNames(styles.stakeToReturn, {
                            [styles.hide]: !isBetPlaced,
                          })}
                        >
                          <div className={classNames(styles.row)}>
                            <div>
                              <Typography color="purpleBlue700" size="md2" fontFamily="avenirNextMedium">
                                {configData.betPannelWords.stake}
                              </Typography>
                              <Typography
                                color="purpleBlue"
                                size={setNumberFormat(item?.betAmount ?? 0, 2).length > 5 ? "md2" : "md"}
                                fontFamily="avenirNextBold"
                              >
                                {extractCurrencySymbol("£")}
                                {setNumberFormat(item?.betAmount ?? 0, 2)}
                              </Typography>
                            </div>
                            <div>
                              <Typography color="purpleBlue700" size="md2" fontFamily="avenirNextMedium">
                                {configData.betPannelWords.toReturn}
                              </Typography>
                              {/* change the calculation later  */}
                              <Typography
                                color="purpleBlue"
                                size={
                                  setNumberFormat(Number((item.betAmount as any) * item.decimalOdd) ?? 0, 2).length > 5
                                    ? "md2"
                                    : "md"
                                }
                                fontFamily="avenirNextBold"
                              >
                                {extractCurrencySymbol("£")}
                                {item.betAmount &&
                                  setNumberFormat(Number((item.betAmount as any) * item.decimalOdd) ?? 0, 2)}
                              </Typography>
                            </div>
                          </div>

                          <div className={classNames(styles.betRef)}>
                            <Typography color="black" fontFamily="avenirNextMedium" size="xs2_5" noWrap={true}>
                              {configData.betPannelWords.betRef} {item.betId}
                            </Typography>
                          </div>
                        </section>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </section>
          <div
            className={classNames(styles.col, {
              [styles.highLightBox]: checkIsAnyStakeUpdated() && sessionHelper?.isFTU,
            })}
          >
            {/* remove this extraPadding props after clikng on stake input  */}
            <Button
              addDropShadow={true}
              onClick={() => handlePlaceBet()}
              size="stretch"
              color={checkIsAnyStakeUpdated() ? "darkGreenBtn" : "purpleBlue100"}
              disabled={!checkIsAnyStakeUpdated()}
              addMinHeight={true}
              displayFlex={true}
            >
              <div className={classNames({ [styles.hide]: isBetPlaced })}>
                <div className={styles.placeBetRow}>
                  <Typography hide={isBetPlaced} fontFamily="degularBold" color="purpleBlue" size="xl">
                    {configData.betPannelWords.placeBet}{" "}
                  </Typography>
                  {checkIsAnyStakeUpdated() && (
                    <div
                      className={classNames(styles.addLeftMargin, {
                        [styles.hide]: SelectedOddsOptionsCount === 1,
                      })}
                    >
                      <Typography hide={isBetPlaced} fontFamily="degularBold" color="purpleBlue" size="xl">
                        {`${extractCurrencySymbol("£")} ${setNumberFormat(calculateTotalBetAmount() ?? 0, 2)}`}
                      </Typography>
                    </div>
                  )}
                </div>
                <Typography
                  hide={!checkIsAnyStakeUpdated() || SelectedOddsOptionsCount > 1} // hide if more than two odds selected
                  fontFamily="avenirNextBold"
                  color="purpleBlue700"
                  size="xs"
                >
                  {configData.betPannelWords.toReturn} {_singleOddReturnVal.current ? _singleOddReturnVal.current : ""}
                </Typography>
              </div>

              <Typography
                hide={!checkIsAnyStakeUpdated()}
                color={isBetPlaced ? "purpleBlue" : "purpleBlue700"}
                fontFamily="avenirNextBold"
                size={isBetPlaced ? "xl" : "xs"}
              >
                {isBetPlaced && configData.betPannelWords.startMatch}
              </Typography>

              <Loader type="grayLoader" hide={isBetPlaced ? !isStartMatchLoading : !isPlaceBetLoading} />
            </Button>
          </div>
        </div>
        <div
          className={classNames(styles.numbersKeyPad, {
            [styles.showKeypadView]: showKeypad,
            [styles.removeShowKeypadView]: !showKeypad && activeInputId.oddId.length > 2,
          })}
        >
          <NumbersKeypad onClickOption={handleOptionClick} onClickKey={handleKeyClick} />
        </div>
      </div>

      <Button
        onClick={() => handleAddMoreOddsSlips()}
        roundedEdge={true}
        addExtraPadding={true}
        displayFlex={true}
        size="stretch"
        color="blackBtn"
        disabled={sessionHelper?.isFTU ? true : false}
        hide={!isBetPlaced}
      >
        <img src="icon/backArrow.png" alt="backButton" className={classNames(styles.backButtonImage)} />
        <Typography fontFamily="degularBold" size="2xl" transform={true}>
          {isBetPlaced ? `${configData.drawerCloseBtnWords.addMoreBets}` : `${configData.drawerCloseBtnWords.back}`}
        </Typography>
      </Button>
    </section>
  );
};

export default StakePannelInput;
