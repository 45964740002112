import { ICarouselNode } from "components/Carousal";
import { SetCurrencyFormatParams, FormatCurrentTimeProps } from "core/interfaces/common";
import { PlayerPositionEnum, WinEnum } from "generated/graphql";
import { DateTime } from "luxon";
import sessionHelper from "service/sessionHelper";

type HasName = { name: string };

export const formatDataToCarousalNodeType = <T extends HasName | Record<string, unknown>>(
  list: Array<T> = [],
  nameAsId?: boolean | undefined,
) => {
  const formattedData: Array<ICarouselNode<T>> = list.map((item: T, index) => {
    return {
      id: nameAsId && "name" in item ? (item.name as string) : index.toString(),
      data: item,
    };
  });

  return formattedData;
};

export const debug = ({ event, message }: { event: string; message: string }) => {
  console.log(event, message);
};

export function extractCurrencySymbol(amount: any, currency = "") {
  // Define a regex pattern to match common currency symbols
  const currencySymbolPattern = /[\p{Sc}£]/u;

  // Use the match method to find the currency symbol
  const match = amount.match(currencySymbolPattern);

  // If a match is found, return the symbol; otherwise, return null
  return match ? match[0] : currency;
}

export const isObjectAndHasData = (data: unknown): data is Record<string, any> => {
  return typeof data === "object" && data !== null && Object.keys(data).length > 0;
};

export const isArrayAndHasData = <T>(data: T[]): boolean => {
  return Array.isArray(data) && data.length > 0;
};

export const setCurrencyFormat = ({
  value,
  language = "en-IN",
  currency = "USD",
  decimal = 2,
  format = true,
  getCurrencySymbol = false,
  showCurrency = false,
}: SetCurrencyFormatParams): string => {
  if (!Number(value) && !getCurrencySymbol) return "---";

  try {
    const amount = Number(value);

    if (Intl) {
      const str = getCurrencySymbol
        ? new Intl.NumberFormat(language, {
            style: "currency",
            currencyDisplay: "narrowSymbol",
            currency: currency,
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
          }).format(1)
        : format
          ? new Intl.NumberFormat(language, {
              style: "currency",
              currencyDisplay: "narrowSymbol",
              currency: currency,
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal,
            }).format(amount)
          : `${new Intl.NumberFormat(language, {
              minimumFractionDigits: decimal,
              maximumFractionDigits: decimal,
            }).format(amount)} ${currency?.toUpperCase()}`;

      return getCurrencySymbol ? extractCurrencySymbol(str, currency) : str;
    } else {
      return `${amount} ${currency}`;
    }
  } catch (e) {
    return "---";
  }
};

export const setNumberFormat = (number: any, decimal: any, skipRegionalFormating = false) => {
  if (!number || isNaN(number) || isNaN(decimal) || decimal < 0) {
    if (sessionHelper?.language === "id" && !skipRegionalFormating && number === 0) {
      return setCurrencyFormat({
        value: Number(number),
        decimal,
        showCurrency: false,
        format: true,
      });
    } else {
      return number?.toString()?.split(".")[0] + "." + `000000`.slice(0, decimal);
    }
  }

  if (sessionHelper?.language === "id" && !skipRegionalFormating) {
    return setCurrencyFormat({
      value: Number(number),
      decimal,
      showCurrency: false,
      format: true,
    });
  }

  // Format the number with commas and decimals
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return formatter.format(Number(number));
};

export const formatCurrentTime = ({ format = "HH:mm", date = new Date() }: FormatCurrentTimeProps): string => {
  return DateTime.fromJSDate(date).toFormat(format);
};

export const getLanguage = (language: string, location: string) => {
  if (language === "en") {
    if (location === "US") return "American";
    if (location === "UK") return "Fractional";
  }
  return "Decimal";
};

export const getPreferredPositions = (filter: string): PlayerPositionEnum[] | undefined => {
  switch (filter) {
    case "goalkeepers":
      return [PlayerPositionEnum.Gk];
    case "defenders":
      return [PlayerPositionEnum.Cb, PlayerPositionEnum.Lb, PlayerPositionEnum.Rb];
    case "midfielders":
      return [
        PlayerPositionEnum.Cm,
        PlayerPositionEnum.Lm,
        PlayerPositionEnum.Rm,
        PlayerPositionEnum.Cam,
        PlayerPositionEnum.Cdm,
      ];
    case "forwards":
      return [PlayerPositionEnum.St, PlayerPositionEnum.Lw, PlayerPositionEnum.Rw];
    default:
      return undefined; // No specific positions preferred
  }
};

export const getPreferredPositionLabel = (position: string): string => {
  switch (position) {
    case "GK":
      return "Goalkeeper";
    case "LB":
    case "RB":
    case "CB":
      return "Defender";
    case "LM":
    case "RM":
    case "CM":
    case "CAM":
    case "CDM":
      return "Midfielder";
    case "ST":
    case "LW":
    case "RW":
      return "Forward";
    default:
      return "Unknown Position";
  }
};

export const getPositionGroup = (position: PlayerPositionEnum): PlayerPositionEnum[] => {
  const forwardPositions = [PlayerPositionEnum.St, PlayerPositionEnum.Lw, PlayerPositionEnum.Rw];
  const midfielderPositions = [
    PlayerPositionEnum.Lm,
    PlayerPositionEnum.Rm,
    PlayerPositionEnum.Cm,
    PlayerPositionEnum.Cam,
    PlayerPositionEnum.Cdm,
  ];
  const defenderPositions = [PlayerPositionEnum.Lb, PlayerPositionEnum.Rb, PlayerPositionEnum.Cb];
  const goalkeeperPositions = [PlayerPositionEnum.Gk];

  const positionLabel = getPreferredPositionLabel(position);

  switch (positionLabel) {
    case "Goalkeeper":
      return goalkeeperPositions;
    case "Defender":
      return defenderPositions;
    case "Midfielder":
      return midfielderPositions;
    case "Forward":
      return forwardPositions;
    default:
      return [position]; // Return only the specific position if it's unknown
  }
};

export function generateRandomId() {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  let firstStr = ("000" + first.toString(36)).slice(-3);
  let secondStr = ("000" + second.toString(36)).slice(-3);

  return firstStr + secondStr;
}

export function generateRandomUserId() {
  const prefix = "user_";
  const randomId = Math.random().toString(36).substring(2, 10);
  return prefix + randomId;
}

function isValidVersion(version: string): boolean {
  const versionRegex = /^v(\d+)\.(\d+)\.(\d+)$/;
  return versionRegex.test(version);
}

export async function getVersion() {
  try {
    const response = await fetch(`/version.txt`);

    if (response.ok) {
      const version = await response.text();

      if (isValidVersion(version.trim())) {
        return version.trim();
      }
    }

    return "";
  } catch (error) {
    console.log("Error loading version:", error);
    return "0.01"; // or another default string
  }
}

export function _getTeamName(name: WinEnum): string {
  return name === "A" ? "teamA" : name === "B" ? "teamB" : "Draw";
}

export function debounce<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeoutId: any;

  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

export function timer(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}
