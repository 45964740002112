import { PlayerCardType } from "core/enums";
import { PlayerInfo, BoosterInfo, GamePannelOption } from "core/interfaces/common";
import config from "core/config";

const {
  finalResult,
  goalsOverUnder,
  bothTeamsToScore,
  resultBothTeamsScore,
  correctScore,
  firstLastAnytimeGoalScorer,
  handicaps,
  firstGoalScorerCorrectScore,
} = config.gamePannelOptionsHeading;

export const home = {
  users: "4,667",
  balanceAmt1: "320",
  balanceAmt2: "63",
  coins: "340",
  teamName: "My Team FC",
  overCount: "87",
  days: "5d",
  amnt: "300",
  amnt1: "340",
  divisionNum: "10",
  carousalCardText: "EURO 2024",
};

export const store = {
  pack: [
    {
      id: 1,
      coins: 450,
      packType: "random",
      amount: 0,
    },
    {
      id: 2,
      coins: 460,
      packType: "gold",
      amount: 0,
    },
    {
      id: 3,
      coins: 470,
      packType: "silver",
      amount: 0,
    },
    {
      id: 4,
      coins: 480,
      packType: "bronze",
      amount: 0,
    },
  ],
  coins: [
    {
      id: 1,
      coins: 450,
      coinType: "smallCoins",
      amount: 20,
    },
    {
      id: 2,
      coins: 450,
      coinType: "bigCoins",
      amount: 70,
    },
    {
      id: 3,
      coins: 450,
      coinType: "smallCoins",
      amount: 20,
    },
  ],
  multiplyCoins: "X 250",
};

export const playerDetails = {
  playerName: "MARCO VELAZ",
  estimatedCoins: "500",
  overs: "40",
  st: "ST",
  lm: "LM",
  fisrtName: "MARCO",
  secondName: "VELAZ",
  playerStats: [
    { label: "Age", value: 26 },
    { label: "Weight", value: 75 },
    { label: "Height", value: 186 },
  ],
  stats: [
    { label: "Matches Played", value: 12 },
    { label: "Goals", value: 2 },
    { label: "Assists", value: 4 },
  ],
  playerInfo: [
    {
      icon: "icon1",
      label: "ATTACK",
      width: "67%",
    },
    {
      icon: "icon3",
      label: "DEFENCE",
      width: "56%",
    },
    {
      icon: "icon2",
      label: "CREATIVITY",
      width: "45%",
    },
  ],
};

export const bets = [
  {
    amount: 450,
    totalStake: 5,
    totalToReturn: 200,
  },
  {
    amount: 450,
    totalStake: 5,
    totalToReturn: 200,
  },
  {
    amount: 450,
    totalStake: 5,
    totalToReturn: 200,
  },
];

export const swapPlayer = {
  addOne: "1",
  addFive: "5",
};

export const teamSelect = {
  teamList: [
    { name: "J.Khalidov", role: "gk", over: "65" },
    { name: "J.Peres   ", role: "lb", over: "65" },
    { name: "P.Koons", role: "cb", over: "34" },
    { name: "P.Keurn", role: "cb", over: "78" },
    { name: "M.Maikov", role: "rb", over: "45" },
    { name: "K.Izaki", role: "lm", over: "44" },
    { name: "M.Velaz", role: "cm", over: "65" },
    { name: "A.Bouhlavi", role: "cm", over: "34" },
    { name: "N.Bante", role: "rm", over: "87" },
    { name: "P.Horns", role: "st", over: "67" },
    { name: "A.Varaz", role: "st", over: "56" },
  ],
  chemistry: 43,
  teamScore: 56,
};

export const teamFormation = [
  {
    label: "4-3-3",
    value: "4-3-3",
  },
  {
    label: "4-4-2",
    value: "4-4-2",
  },
  {
    label: "4-2-3-1",
    value: "4-2-3-1",
  },
  {
    label: "3-5-2",
    value: "3-5-2",
  },
  {
    label: "5-3-2",
    value: "5-3-2",
  },
];

export const positions = {
  attack: ["ST"],
  midfield: ["LM", "CM", "RM"],
  defence: ["LB", "CB", "RB"],
  goalkeeper: ["GK"],
};

export const players: PlayerInfo[] = [
  {
    id: 1,
    name: "PAOLO ROSI",
    avatar: "images/playerCard/playerAssets/pid_10.png",
    cardType: PlayerCardType.Bronze,
    position: "ST",
    level: 43,
    country: {
      name: "France",
      icon: "images/playerCard/playercountry/Spain.png",
    },
    club: {
      name: "Barcelona",
      icon: "images/playerCard/playerclubs/club_par.png",
    },
    stats: [
      {
        label: "Attack",
        value: 56,
      },
      {
        label: "Defence",
        value: 47,
      },
      {
        label: "Creativity",
        value: 78,
      },
    ],
  },
];

export const PlayerChemistyInfo = {
  nationality: {
    id: 1,
    label: "Nationality",
    value1: 3,
    value2: 5,
    progressbar1: 25,
    progressbar2: 35,
    icon: "images/playerCard/flag1.png",
  },
  club: {
    id: 2,
    label: "Club",
    value1: 2,
    value2: 3,
    progressbar1: 45,
    progressbar2: 25,
    icon: "images/playerCard/club1.png",
  },
  position: {
    id: 3,
    label: "Position",
    value1: 3,
    value2: 5,
    text: "Primary Position",
    progressbar1: 35,
    progressbar2: 45,
    icon: "",
    position: "ST",
  },
};

export const playerListFilterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Forwards",
    value: "forwards",
  },
  {
    label: "Midfielders",
    value: "midfielders",
  },
  {
    label: "Defenders",
    value: "defenders",
  },
  {
    label: "Goal Keepers",
    value: "goalkeepers",
  },
];

export const boosterListFilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Attack",
    value: "Attack",
  },
  {
    label: "Creativity",
    value: "Creativity",
  },
  {
    label: "Defence",
    value: "Defence",
  },
];

export const boosters: BoosterInfo[] = [
  {
    id: 1,
    title: "Speed Master",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-speed-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 2,
    title: "Power",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-power-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 3,
    title: "Dribbler",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-dribbler-bronze.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 4,
    title: "Power",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-power-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 5,
    title: "Speed Master",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-speed-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 6,
    title: "Power",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-power-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 7,
    title: "Dribbler",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-dribbler-bronze.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
  {
    id: 8,
    title: "Power",
    creativity: "+5",
    backgroundImage: "images/boosterCard/booster-power-silver.png",
    stats: [
      {
        label: "Attack",
        value: "+5",
      },
      {
        label: "Defence",
        value: "+10",
      },
    ],
  },
];
export const oddsDisplay = [
  {
    id: 1,
    label: "Fractional",
    value: "11/4",
  },
  {
    id: 2,
    label: "Decimal",
    value: 3.75,
  },
  {
    id: 3,
    label: "American",
    value: "+275",
  },
];

export const gamePannelOptions: GamePannelOption[] = [
  {
    name: finalResult,
    openContent: true,
    data: [
      {
        key: "0-Final Results",
        options: [
          { type: "box", active: false, team: "My Team FC", value: "19/10", hideTitle: false },
          { type: "box", active: false, team: "Draw", value: "9/4", hideTitle: false },
          { type: "box", active: false, team: "Tuluze FC", value: "6/4", hideTitle: false },
        ],
      },
    ],
  },
  {
    name: goalsOverUnder,
    openContent: true,
    data: [
      {
        key: "0-Goals over/under",
        options: [
          { type: "emptySpace", active: false, label: "", hideTitle: true },
          { type: "colHeading", active: false, label: "Over", hideTitle: true },
          { type: "colHeading", active: false, label: "Under", hideTitle: true },
        ],
      },
      {
        key: "1-Goals over/under",
        options: [
          { type: "subLabel", active: false, label: "0.5", hideTitle: true },
          { type: "box", active: false, label: "", value: "1/12", hideTitle: true },
          { type: "box", active: false, label: "", value: "15/2", hideTitle: true },
        ],
      },
      {
        key: "2-Goals over/under",
        options: [
          { type: "subLabel", active: false, label: "1.5", hideTitle: true },
          { type: "box", active: false, label: "", value: "1/2", hideTitle: true },
          { type: "box", active: false, label: "", value: "17/10", hideTitle: true },
        ],
      },
      {
        key: "2-Goals over/under",
        options: [
          { type: "subLabel", active: false, label: "2.5", hideTitle: true },
          { type: "box", active: false, label: "", value: "8/5", hideTitle: true },
          { type: "box", active: false, label: "", value: "8/15", hideTitle: true },
        ],
      },
      {
        key: "2-Goals over/under",
        options: [
          { type: "subLabel", active: false, label: "3.5", hideTitle: true },
          { type: "box", active: false, label: "", value: "9/2", hideTitle: true },
          { type: "box", active: false, label: "", value: "1/6", hideTitle: true },
        ],
      },
    ],
  },
  {
    name: bothTeamsToScore,
    openContent: true,

    data: [
      {
        key: "0-Both Teams To Score",
        options: [
          { type: "subLabel", active: false, label: "Yes", hideTitle: true },
          { type: "box", active: false, label: "", value: "6/5", hideTitle: true },
        ],
      },
      {
        key: "1-Both Teams To Score",
        options: [
          { type: "subLabel", active: false, label: "No", hideTitle: true },
          { type: "box", active: false, label: "Draw", value: "7/10", hideTitle: true },
        ],
      },
    ],
  },
  {
    name: resultBothTeamsScore,
    openContent: true,

    data: [
      {
        key: "0-Results/ Both Teams To Score",
        options: [
          { type: "emptySpace", active: false, label: "", hideTitle: true },
          { type: "colHeading", active: false, label: "Yes", hideTitle: true },
          { type: "colHeading", active: false, label: "No", hideTitle: true },
        ],
      },
      {
        key: "1-Results/ Both Teams To Score",
        options: [
          { type: "subLabel", active: false, label: "My Team FC", hideTitle: true },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: true },
          { type: "box", active: false, label: "", value: "7/2", hideTitle: true },
        ],
      },
      {
        key: "2-Results/ Both Teams To Score",
        options: [
          { type: "subLabel", active: false, label: "Tuluze FC", hideTitle: true },
          { type: "box", active: false, label: "", value: "11/2", hideTitle: true },
          { type: "box", active: false, label: "", value: "11/4", hideTitle: true },
        ],
      },
      {
        key: "3-Results/ Both Teams To Score",
        options: [
          { type: "subLabel", active: false, label: "Draw", hideTitle: true },
          { type: "box", active: false, label: "", value: "17/4", hideTitle: true },
          { type: "box", active: false, label: "", value: "15/2", hideTitle: true },
        ],
      },
    ],
  },
  {
    name: correctScore,
    openContent: true,
    initialLength: 4,
    isExpanded: false,
    showLabel: "Show All",
    data: [
      {
        key: "0-correctScore",
        options: [
          { type: "colHeading", active: false, label: "My Team FC", hideTitle: false },
          { type: "colHeading", active: false, label: "Draw", hideTitle: false },
          { type: "colHeading", active: false, label: "Tuluze FC", hideTitle: false },
        ],
      },
      {
        key: "1-correctScore",
        options: [
          { type: "box", active: false, label: "1 - 0", value: "11/4", hideTitle: false },
          { type: "box", active: false, label: "0 - 0", value: "13/8", hideTitle: false },
          { type: "box", active: false, label: "1 - 0", value: "6/5", hideTitle: false },
        ],
      },
      {
        key: "2-correctScore",
        options: [
          { type: "box", active: false, label: "2 - 1", value: "5/2", hideTitle: false },
          { type: "box", active: false, label: "1 - 1", value: "2/1", hideTitle: false },
          { type: "box", active: false, label: "2 - 1", value: "3/1", hideTitle: false },
        ],
      },
      {
        key: "3-correctScore",
        options: [
          { type: "box", active: false, label: "3 - 1", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "3 - 3", value: "7/1", hideTitle: false },
          { type: "box", active: false, label: "3 - 1", value: "10/1", hideTitle: false },
        ],
      },
      {
        key: "4-correctScore",
        options: [
          { type: "box", active: false, label: "3 - 1", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "3 - 3", value: "7/1", hideTitle: false },
          { type: "box", active: false, label: "3 - 1", value: "10/1", hideTitle: false },
        ],
      },
      {
        key: "5-correctScore",
        options: [
          { type: "box", active: false, label: "3 - 1", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "3 - 3", value: "7/1", hideTitle: false },
          { type: "box", active: false, label: "3 - 1", value: "10/1", hideTitle: false },
        ],
      },
      {
        key: "6-correctScore",
        options: [
          { type: "box", active: false, label: "3 - 1", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "3 - 3", value: "7/1", hideTitle: false },
          { type: "box", active: false, label: "3 - 1", value: "10/1", hideTitle: false },
        ],
      },
    ],
  },
  {
    name: firstLastAnytimeGoalScorer,
    openContent: true,
    initialLength: 5,
    isExpanded: false,
    showLabel: "Show More",
    data: [
      {
        key: "0-1st & Anytime Goal Scorer",
        options: [
          { type: "emptySpace", active: false, label: "", hideTitle: false },
          { type: "colHeading", active: false, label: "First", hideTitle: false },
          { type: "colHeading", active: false, label: "Last", hideTitle: false },
          { type: "colHeading", active: false, label: "Anytime", hideTitle: false },
        ],
      },
      {
        key: "1-1st & Anytime Goal Scorer",
        options: [
          { type: "subLabel", active: false, label: "Rafinha", subLabel: "My Team FC", hideTitle: false, imgTag: "41" },
          { type: "box", active: false, label: "", value: "11/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "11/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "2/1", hideTitle: false },
        ],
      },
      {
        key: "2-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Alexander-Arnold",
            subLabel: "My Team FC",
            hideTitle: false,
            imgTag: "71",
          },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "11/5", hideTitle: false },
        ],
      },

      {
        key: "3-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Fernandinho",
            subLabel: "Tuluze FC",
            hideTitle: false,
            imgTag: "56",
          },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "11/5", hideTitle: false },
        ],
      },
      {
        key: "3-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Fernandinho",
            subLabel: "Tuluze FC",
            hideTitle: false,
            imgTag: "56",
          },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "", value: "11/5", hideTitle: false },
        ],
      },
      {
        key: "4-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Edenilson",
            subLabel: "Tuluze FC",
            hideTitle: false,
            imgTag: "78",
          },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "12/5", hideTitle: false },
        ],
      },
      {
        key: "4-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Edenilson",
            subLabel: "Tuluze FC",
            hideTitle: false,
            imgTag: "78",
          },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "12/5", hideTitle: false },
        ],
      },
      {
        key: "4-1st & Anytime Goal Scorer",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Edenilson",
            subLabel: "Tuluze FC",
            hideTitle: false,
            imgTag: "78",
          },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: false },
          { type: "box", active: false, label: "", value: "12/5", hideTitle: false },
        ],
      },
    ],
  },
  {
    name: handicaps,
    openContent: true,

    data: [
      {
        key: "0-Handicaps",
        options: [
          { type: "colHeading", active: false, label: "My Team FC", hideTitle: false },
          { type: "colHeading", active: false, label: "Draw", hideTitle: false },
          { type: "colHeading", active: false, label: "Tuluze FC", hideTitle: false },
        ],
      },
      {
        key: "1-Handicaps",
        options: [
          { type: "box", active: false, label: "-2", value: "28/1", hideTitle: false },
          { type: "box", active: false, label: "+2", value: "8/1", hideTitle: false },
          { type: "box", active: false, label: "+2", value: "1/10", hideTitle: false },
        ],
      },
      {
        key: "2-Handicaps",
        options: [
          { type: "box", active: false, label: "-1", value: "6/1", hideTitle: false },
          { type: "box", active: false, label: "+1", value: "16/5", hideTitle: false },
          { type: "box", active: false, label: "+1", value: "1/2", hideTitle: false },
        ],
      },
      {
        key: "3-Handicaps",
        options: [
          { type: "box", active: false, label: "+1", value: "19/50", hideTitle: false },
          { type: "box", active: false, label: "-1", value: "15/4", hideTitle: false },
          { type: "box", active: false, label: "-1", value: "8/1", hideTitle: false },
        ],
      },
      {
        key: "3-Handicaps",
        options: [
          { type: "box", active: false, label: "+2", value: "1/16", hideTitle: false },
          { type: "box", active: false, label: "-2", value: "11/1", hideTitle: false },
          { type: "box", active: false, label: "-2", value: "40/1", hideTitle: false },
        ],
      },
    ],
  },
  {
    name: firstGoalScorerCorrectScore,
    openContent: true,
    initialLength: 6,
    isExpanded: false,
    showLabel: "Show More",
    data: [
      {
        key: "0-Correct Score",
        options: [
          { type: "colHeading", active: false, label: "My Team FC", hideTitle: false },
          { type: "emptySpace", active: false, label: "", hideTitle: false },
          { type: "colHeading", active: false, label: "Tuluze FC", hideTitle: false },
        ],
      },
      {
        key: "1-Correct Score",
        options: [
          {
            type: "scoreHandler",
            active: false,
            label: "No",
            value: "0",
            hideTitle: false,
          },
          {
            type: "scoreHandler",
            active: false,
            label: "No",
            value: "0",
            hideTitle: false,
          },
        ],
      },
      {
        key: "1-First Goalscorer / Correct Score",
        options: [
          { type: "subLabel", active: false, label: "Rafinha", subLabel: "My Team FC", hideTitle: true, imgTag: "41" },
          { type: "box", active: false, label: "", value: "11/2", hideTitle: true },
        ],
      },
      {
        key: "2-First Goalscorer / Correct Score",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Alexander-Arnold",
            subLabel: "My Team FC",
            hideTitle: true,
            imgTag: "71",
          },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: true },
        ],
      },
      {
        key: "3-First Goalscorer / Correct Score",
        options: [
          {
            type: "subLabel",
            active: false,
            label: "Fernandinho",
            subLabel: "Tuluze FC",
            hideTitle: true,
            imgTag: "56",
          },
          { type: "box", active: false, label: "", value: "6/1", hideTitle: true },
        ],
      },
      {
        key: "4-First Goalscorer / Correct Score",
        options: [
          { type: "subLabel", active: false, label: "Edenilson", subLabel: "Tuluze FC", hideTitle: true, imgTag: "78" },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: true },
        ],
      },
      {
        key: "4-First Goalscorer / Correct Score",
        options: [
          { type: "subLabel", active: false, label: "Edenilson", subLabel: "Tuluze FC", hideTitle: true, imgTag: "78" },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: true },
        ],
      },
      {
        key: "4-First Goalscorer / Correct Score",
        options: [
          { type: "subLabel", active: false, label: "Edenilson", subLabel: "Tuluze FC", hideTitle: true, imgTag: "78" },
          { type: "box", active: false, label: "", value: "13/2", hideTitle: true },
        ],
      },
    ],
  },
];

export const nationality = [
  {
    icon: "images/ftui/flags/cf/Albania.png",
    country: "Albania",
  },
  {
    icon: "images/ftui/flags/cf/Algeria.png",
    country: "Algeria",
  },
  {
    icon: "images/ftui/flags/cf/Andorra.png",
    country: "Andorra",
  },
  {
    icon: "images/ftui/flags/cf/Angola.png",
    country: "Angola",
  },
  {
    icon: "images/ftui/flags/cf/Argentina.png",
    country: "Argentina",
  },
  {
    icon: "images/ftui/flags/cf/Australia.png",
    country: "Australia",
  },
  {
    icon: "images/ftui/flags/cf/Austria.png",
    country: "Austria",
  },
  {
    icon: "images/ftui/flags/cf/Azerbaijan.png",
    country: "Azerbaijan",
  },
  {
    icon: "images/ftui/flags/cf/Bahamas.png",
    country: "Bahamas",
  },
  {
    icon: "images/ftui/flags/cf/Bahrain.png",
    country: "Bahrain",
  },
  {
    icon: "images/ftui/flags/cf/Bangladesh.png",
    country: "Bangladesh",
  },
  {
    icon: "images/ftui/flags/cf/Barbados.png",
    country: "Barbados",
  },
  {
    icon: "images/ftui/flags/cf/Belarus.png",
    country: "Belarus",
  },
  {
    icon: "images/ftui/flags/cf/Belgium.png",
    country: "Belgium",
  },
  {
    icon: "images/ftui/flags/cf/Belize.png",
    country: "Belize",
  },
  {
    icon: "images/ftui/flags/cf/Benin.png",
    country: "Benin",
  },
  {
    icon: "images/ftui/flags/cf/Bhutan.png",
    country: "Bhutan",
  },
  {
    icon: "images/ftui/flags/cf/Bolivia.png",
    country: "Bolivia",
  },
  {
    icon: "images/ftui/flags/cf/Botswana.png",
    country: "Botswana",
  },
  {
    icon: "images/ftui/flags/cf/Brazil.png",
    country: "Brazil",
  },
  {
    icon: "images/ftui/flags/cf/Brunei.png",
    country: "Brunei",
  },
  {
    icon: "images/ftui/flags/cf/Bulgaria.png",
    country: "Bulgaria",
  },
  {
    icon: "images/ftui/flags/cf/Burundi.png",
    country: "Burundi",
  },
  {
    icon: "images/ftui/flags/cf/Cambodia.png",
    country: "Cambodia",
  },
  {
    icon: "images/ftui/flags/cf/Cameroon.png",
    country: "Cameroon",
  },
  {
    icon: "images/ftui/flags/cf/Canada.png",
    country: "Canada",
  },
  {
    icon: "images/ftui/flags/cf/CAR.png",
    country: "CAR",
  },
  {
    icon: "images/ftui/flags/cf/Chad.png",
    country: "Chad",
  },
  {
    icon: "images/ftui/flags/cf/Chile.png",
    country: "Chile",
  },
  {
    icon: "images/ftui/flags/cf/China.png",
    country: "China",
  },
  {
    icon: "images/ftui/flags/cf/Colombia.png",
    country: "Colombia",
  },
  {
    icon: "images/ftui/flags/cf/Comoros.png",
    country: "Comoros",
  },
  {
    icon: "images/ftui/flags/cf/Cuba.png",
    country: "Cuba",
  },
  {
    icon: "images/ftui/flags/cf/Cyprus.png",
    country: "Cyprus",
  },
  {
    icon: "images/ftui/flags/cf/Denmark.png",
    country: "Denmark",
  },
  {
    icon: "images/ftui/flags/cf/Djibouti.png",
    country: "Djibouti",
  },
  {
    icon: "images/ftui/flags/cf/Dominica.png",
    country: "Dominica",
  },
  {
    icon: "images/ftui/flags/cf/Ecuador.png",
    country: "Ecuador",
  },
  {
    icon: "images/ftui/flags/cf/Egypt.png",
    country: "Egypt",
  },
  {
    icon: "images/ftui/flags/cf/Eritrea.png",
    country: "Eritrea",
  },
  {
    icon: "images/ftui/flags/cf/Estonia.png",
    country: "Estonia",
  },
  {
    icon: "images/ftui/flags/cf/Eswatini.png",
    country: "Eswatini",
  },
  {
    icon: "images/ftui/flags/cf/Ethiopia.png",
    country: "Ethiopia",
  },
  {
    icon: "images/ftui/flags/cf/Fiji.png",
    country: "Fiji",
  },
  {
    icon: "images/ftui/flags/cf/Finland.png",
    country: "Finland",
  },
  {
    icon: "images/ftui/flags/cf/Gabon.png",
    country: "Gabon",
  },
  {
    icon: "images/ftui/flags/cf/Gambia.png",
    country: "Gambia",
  },
  {
    icon: "images/ftui/flags/cf/Georgia.png",
    country: "Georgia",
  },
  {
    icon: "images/ftui/flags/cf/Germany.png",
    country: "Germany",
  },
  {
    icon: "images/ftui/flags/cf/Ghana.png",
    country: "Ghana",
  },
  {
    icon: "images/ftui/flags/cf/Greece.png",
    country: "Greece",
  },
  {
    icon: "images/ftui/flags/cf/Grenada.png",
    country: "Grenada",
  },
  {
    icon: "images/ftui/flags/cf/Guatemala.png",
    country: "Guatemala",
  },
  {
    icon: "images/ftui/flags/cf/Guinea.png",
    country: "Guinea",
  },
  {
    icon: "images/ftui/flags/cf/Guyana.png",
    country: "Guyana",
  },
  {
    icon: "images/ftui/flags/cf/Haiti.png",
    country: "Haiti",
  },
  {
    icon: "images/ftui/flags/cf/Honduras.png",
    country: "Honduras",
  },
  {
    icon: "images/ftui/flags/cf/Hungary.png",
    country: "Hungary",
  },
  {
    icon: "images/ftui/flags/cf/Iceland.png",
    country: "Iceland",
  },
  {
    icon: "images/ftui/flags/cf/India.png",
    country: "India",
  },
  {
    icon: "images/ftui/flags/cf/Indonesia.png",
    country: "Indonesia",
  },
  {
    icon: "images/ftui/flags/cf/Iran.png",
    country: "Iran",
  },
  {
    icon: "images/ftui/flags/cf/Iraq.png",
    country: "Iraq",
  },
  {
    icon: "images/ftui/flags/cf/Ireland.png",
    country: "Ireland",
  },
  {
    icon: "images/ftui/flags/cf/Israel.png",
    country: "Israel",
  },
  {
    icon: "images/ftui/flags/cf/Italy.png",
    country: "Italy",
  },
  {
    icon: "images/ftui/flags/cf/Ivory Coast.png",
    country: "Ivory Coast",
  },
  {
    icon: "images/ftui/flags/cf/Jamaica.png",
    country: "Jamaica",
  },
  {
    icon: "images/ftui/flags/cf/Japan.png",
    country: "Japan",
  },
  {
    icon: "images/ftui/flags/cf/Jordan.png",
    country: "Jordan",
  },
  {
    icon: "images/ftui/flags/cf/Kazakhstan.png",
    country: "Kazakhstan",
  },
  {
    icon: "images/ftui/flags/cf/Kenya.png",
    country: "Kenya",
  },
  {
    icon: "images/ftui/flags/cf/Kiribati.png",
    country: "Kiribati",
  },
  {
    icon: "images/ftui/flags/cf/Kosovo.png",
    country: "Kosovo",
  },
  {
    icon: "images/ftui/flags/cf/Kuwait.png",
    country: "Kuwait",
  },
  {
    icon: "images/ftui/flags/cf/Kyrgyzstan.png",
    country: "Kyrgyzstan",
  },
  {
    icon: "images/ftui/flags/cf/Laos.png",
    country: "Laos",
  },
  {
    icon: "images/ftui/flags/cf/Latvia.png",
    country: "Latvia",
  },
  {
    icon: "images/ftui/flags/cf/Lebanon.png",
    country: "Lebanon",
  },
  {
    icon: "images/ftui/flags/cf/Lesotho.png",
    country: "Lesotho",
  },
  {
    icon: "images/ftui/flags/cf/Liberia.png",
    country: "Liberia",
  },
  {
    icon: "images/ftui/flags/cf/Libya.png",
    country: "Libya",
  },
  {
    icon: "images/ftui/flags/cf/Liechtenstein.png",
    country: "Liechtenstein",
  },
  {
    icon: "images/ftui/flags/cf/Lithuania.png",
    country: "Lithuania",
  },
  {
    icon: "images/ftui/flags/cf/Luxembourg.png",
    country: "Luxembourg",
  },
  {
    icon: "images/ftui/flags/cf/Madagascar.png",
    country: "Madagascar",
  },
  {
    icon: "images/ftui/flags/cf/Malawi.png",
    country: "Malawi",
  },
  {
    icon: "images/ftui/flags/cf/Malaysia.png",
    country: "Malaysia",
  },
  {
    icon: "images/ftui/flags/cf/Maldives.png",
    country: "Maldives",
  },
  {
    icon: "images/ftui/flags/cf/Mali.png",
    country: "Mali",
  },
  {
    icon: "images/ftui/flags/cf/Malta.png",
    country: "Malta",
  },
  {
    icon: "images/ftui/flags/cf/Mauritania.png",
    country: "Mauritania",
  },
  {
    icon: "images/ftui/flags/cf/Mauritius.png",
    country: "Mauritius",
  },
  {
    icon: "images/ftui/flags/cf/Mexico.png",
    country: "Mexico",
  },
  {
    icon: "images/ftui/flags/cf/Moldova.png",
    country: "Moldova",
  },
  {
    icon: "images/ftui/flags/cf/Monaco.png",
    country: "Monaco",
  },
  {
    icon: "images/ftui/flags/cf/Mongolia.png",
    country: "Mongolia",
  },
  {
    icon: "images/ftui/flags/cf/Morocco.png",
    country: "Morocco",
  },
  {
    icon: "images/ftui/flags/cf/Mozambique.png",
    country: "Mozambique",
  },
  {
    icon: "images/ftui/flags/cf/Myanmar.png",
    country: "Myanmar",
  },
  {
    icon: "images/ftui/flags/cf/Namibia.png",
    country: "Namibia",
  },
  {
    icon: "images/ftui/flags/cf/Nauru.png",
    country: "Nauru",
  },
  {
    icon: "images/ftui/flags/cf/Nepal.png",
    country: "Nepal",
  },
  {
    icon: "images/ftui/flags/cf/Netherlands.png",
    country: "Netherlands",
  },
  {
    icon: "images/ftui/flags/cf/New Zealand.png",
    country: "New Zealand",
  },
  {
    icon: "images/ftui/flags/cf/Nicaragua.png",
    country: "Nicaragua",
  },
  {
    icon: "images/ftui/flags/cf/Niger.png",
    country: "Niger",
  },
  {
    icon: "images/ftui/flags/cf/Nigeria.png",
    country: "Nigeria",
  },
  {
    icon: "images/ftui/flags/cf/North Korea.png",
    country: "North Korea",
  },
  {
    icon: "images/ftui/flags/cf/North Macedonia.png",
    country: "North Macedonia",
  },
  {
    icon: "images/ftui/flags/cf/Norway.png",
    country: "Norway",
  },
  {
    icon: "images/ftui/flags/cf/Oman.png",
    country: "Oman",
  },
  {
    icon: "images/ftui/flags/cf/Pakistan.png",
    country: "Pakistan",
  },
  {
    icon: "images/ftui/flags/cf/Palau.png",
    country: "Palau",
  },
  {
    icon: "images/ftui/flags/cf/Palestine.png",
    country: "Palestine",
  },
  {
    icon: "images/ftui/flags/cf/Panama.png",
    country: "Panama",
  },
  {
    icon: "images/ftui/flags/cf/Paraguay.png",
    country: "Paraguay",
  },
  {
    icon: "images/ftui/flags/cf/Peru.png",
    country: "Peru",
  },
  {
    icon: "images/ftui/flags/cf/Philippines.png",
    country: "Philippines",
  },
  {
    icon: "images/ftui/flags/cf/Poland.png",
    country: "Poland",
  },
  {
    icon: "images/ftui/flags/cf/Portugal.png",
    country: "Portugal",
  },
  {
    icon: "images/ftui/flags/cf/Qatar.png",
    country: "Qatar",
  },
  {
    icon: "images/ftui/flags/cf/Romania.png",
    country: "Romania",
  },
  {
    icon: "images/ftui/flags/cf/Russia.png",
    country: "Russia",
  },
  {
    icon: "images/ftui/flags/cf/Rwanda.png",
    country: "Rwanda",
  },
  {
    icon: "images/ftui/flags/cf/Samoa.png",
    country: "Samoa",
  },
  {
    icon: "images/ftui/flags/cf/San Marino.png",
    country: "San Marino",
  },
  {
    icon: "images/ftui/flags/cf/Saudi Arabia.png",
    country: "Saudi Arabia",
  },
  {
    icon: "images/ftui/flags/cf/Senegal.png",
    country: "Senegal",
  },
  {
    icon: "images/ftui/flags/cf/Serbia.png",
    country: "Serbia",
  },
  {
    icon: "images/ftui/flags/cf/Seychelles.png",
    country: "Seychelles",
  },
  {
    icon: "images/ftui/flags/cf/Sierra Leone.png",
    country: "Sierra Leone",
  },
  {
    icon: "images/ftui/flags/cf/Singapore.png",
    country: "Singapore",
  },
  {
    icon: "images/ftui/flags/cf/Slovakia.png",
    country: "Slovakia",
  },
  {
    icon: "images/ftui/flags/cf/Slovenia.png",
    country: "Slovenia",
  },
  {
    icon: "images/ftui/flags/cf/Solomon Islands.png",
    country: "Solomon Islands",
  },
  {
    icon: "images/ftui/flags/cf/Somalia.png",
    country: "Somalia",
  },
  {
    icon: "images/ftui/flags/cf/South Africa.png",
    country: "South Africa",
  },
  {
    icon: "images/ftui/flags/cf/South Sudan.png",
    country: "South Sudan",
  },
  {
    icon: "images/ftui/flags/cf/Spain.png",
    country: "Spain",
  },
  {
    icon: "images/ftui/flags/cf/Sri Lanka.png",
    country: "Sri Lanka",
  },
  {
    icon: "images/ftui/flags/cf/St. Kitts and Nevis.png",
    country: "St.Kitts and Nevis",
  },
  {
    icon: "images/ftui/flags/cf/St. Vincent and the Grenadines.png",
    country: "St. Vincent and the Grenadines",
  },
  {
    icon: "images/ftui/flags/cf/Sudan.png",
    country: "Sudan",
  },
  {
    icon: "images/ftui/flags/cf/Suriname.png",
    country: "Suriname",
  },
  {
    icon: "images/ftui/flags/cf/Syria.png",
    country: "Syria",
  },
  {
    icon: "images/ftui/flags/cf/Taiwan.png",
    country: "Taiwan",
  },
  {
    icon: "images/ftui/flags/cf/Tajikistan.png",
    country: "Tajikistan",
  },
  {
    icon: "images/ftui/flags/cf/Tanzania.png",
    country: "Tanzania",
  },
  {
    icon: "images/ftui/flags/cf/Thailand.png",
    country: "Thailand",
  },
  {
    icon: "images/ftui/flags/cf/Togo.png",
    country: "Togo",
  },
  {
    icon: "images/ftui/flags/cf/Tonga.png",
    country: "Tonga",
  },
  {
    icon: "images/ftui/flags/cf/Trinidad and Tobago.png",
    country: "Trinidad and Tobago",
  },
  {
    icon: "images/ftui/flags/cf/Tunisia.png",
    country: "Tunisia",
  },
  {
    icon: "images/ftui/flags/cf/Turkey.png",
    country: "Turkey",
  },
  {
    icon: "images/ftui/flags/cf/Turkmenistan.png",
    country: "Turkmenistan",
  },
  {
    icon: "images/ftui/flags/cf/Tuvalu.png",
    country: "Tuvalu",
  },
  {
    icon: "images/ftui/flags/cf/UAE.png",
    country: "UAE",
  },
  {
    icon: "images/ftui/flags/cf/Uganda.png",
    country: "Uganda",
  },
  {
    icon: "images/ftui/flags/cf/United Kingdom.png",
    country: "United Kingdom",
  },
  {
    icon: "images/ftui/flags/cf/Uruguay.png",
    country: "Uruguay",
  },
  {
    icon: "images/ftui/flags/cf/USA.png",
    country: "USA",
  },
  {
    icon: "images/ftui/flags/cf/USSR.png",
    country: "USSR",
  },
  {
    icon: "images/ftui/flags/cf/Uzbekistan.png",
    country: "Uzbekistan",
  },
  {
    icon: "images/ftui/flags/cf/Vanuatu.png",
    country: "Vanuatu",
  },
  {
    icon: "images/ftui/flags/cf/Vatican City.png",
    country: "Vatican City",
  },
  {
    icon: "images/ftui/flags/cf/Venezuela.png",
    country: "Venezuela",
  },
  {
    icon: "images/ftui/flags/cf/Vietnam.png",
    country: "Vietnam",
  },
  {
    icon: "images/ftui/flags/cf/Yemen.png",
    country: "Yemen",
  },
  {
    icon: "images/ftui/flags/cf/Zambia.png",
    country: "Zambia",
  },
  {
    icon: "images/ftui/flags/cf/Zimbabwe.png",
    country: "Zimbabwe",
  },
];
