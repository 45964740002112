import React, { useState } from "react";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import useGameStore from "store/gameStore";

const Demo: React.FC = () => {
  const { configData } = getConfig();
  const { updateDrawerStates } = useGameStore();

  const toggleTeamNameDrawer = () => {
    updateDrawerStates({ isOpenTeamNameDrawer: true });
  };

  const toggleSelectNationality = () => {
    updateDrawerStates({ isOpenSelectNationality: true });
  };

  const togglePackSummary = () => {
    updateDrawerStates({ isOpenPackSummary: true });
  };

  return (
    <>
      <div onClick={toggleSelectNationality}>
        <Typography>CLick</Typography>
      </div>
    </>
  );
};

export default Demo;
