import classNames from "classnames";
import Typography from "components/Typography";
import ListView from "pages/team/components/ListView";
import { shallow } from "zustand/shallow";
import getConfig from "util/configHelper";
import Drawer from "components/Drawer";
import { DrawerPositions, DrawerBgType, PlayerCardSize, PlayerCardVariant } from "core/enums";
import useGameStore from "store/gameStore";
import { PlayerInfo } from "core/interfaces/common";
import { playerListFilterOptions, players } from "dummy/dummy";
import PlayerCard from "components/PlayerCard";
import styles from "styles/components/drawers/packSummaryDrawer.module.css";

const PackSummaryDrawer = () => {
  const { configData } = getConfig();

  const headerData = {
    title: configData.team.allPlayers,
    filterOptions: playerListFilterOptions,
  };

  const { isOpenPackSummary, updateDrawerStates } = useGameStore(
    (state) => ({
      isOpenPackSummary: state.drawerStates.isOpenPackSummary,
      updateDrawerStates: state.updateDrawerStates,
    }),
    shallow,
  );

  const handleClose = () => {
    updateDrawerStates({ isOpenPackSummary: false });
  };

  const renderPlayerCard = (item: PlayerInfo) => {
    return (
      <PlayerCard
        playerInfo={item}
        size={PlayerCardSize.Sm}
        variant={PlayerCardVariant.Extended}
        displayChemistryProgress={false}
      />
    );
  };

  return (
    <Drawer
      id="packSummaryDrawer"
      onClose={handleClose}
      isOpen={isOpenPackSummary}
      position={DrawerPositions.Top}
      showFooterButton={true}
      drawerBgType={DrawerBgType.SummerPack}
      footerButtonText="Continue"
    >
      <div className={classNames(styles.packSummarRoot)}>
        <div className={classNames(styles.flexCol)}>
          <Typography fontFamily="degularBlack" size="3xl">
            {configData?.ftuiPages?.sileverPack}
          </Typography>
          <Typography fontFamily="degularBold" size="2xl">
            {configData?.ftuiPages?.summary}
          </Typography>
        </div>
        <div>
          <ListView<PlayerInfo> headerData={headerData} list={players} renderItem={renderPlayerCard} />
        </div>
      </div>
    </Drawer>
  );
};

export default PackSummaryDrawer;
