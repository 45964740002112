import React from "react";
import PackAndCoinModal from "components/modals/PackAndCoinModal";
import PlayerChemistryModal from "components/modals/PlayerChemistryModal";
import SellPlayerModal from "components/modals/SellPlayerModal";
import GameRulesModal from "components/modals/GameRulesModal";
import StakeInfoModal from "components/modals/StakeInfoModal";
import ErrorPopUpModal from "components/modals/ErrorPopUpModal";
import WelcomePackModal from "components/modals/WelcomePackModal";
import PackModal from "./PackModal";
import MinMaxStakeModal from "components/modals/MinMaxStakeModal";

const ModalRenderer: React.FC = () => {
  return (
    <>
      <PackAndCoinModal />
      <SellPlayerModal />
      <PlayerChemistryModal />
      <GameRulesModal />
      <StakeInfoModal />
      <ErrorPopUpModal />
      <WelcomePackModal />
      <PackModal />
      <MinMaxStakeModal />
    </>
  );
};

export default ModalRenderer;
