import classNames from "classnames";
import styles from "styles/components/tab.module.css";
import { TabType } from "core/enums";
import Button from "components/Button";
import Typography from "components/Typography";

interface TabName {
  id: string;
  label: string;
}

interface TabProps {
  type?: TabType;
  tabNames: TabName[];
  activeTab?: string;
  onClick?: (tabId: string) => void;
}

const Tabs = ({ type, tabNames, activeTab, onClick, ...props }: TabProps) => {
  const handleTabClick = (tabId: string) => {
    if (onClick) {
      onClick(tabId);
    }
  };

  return (
    <>
      <div className={classNames(styles.root, { [styles.hideEl]: type === TabType.Square })} {...props}>
        {tabNames?.map((tabName, index) => (
          <div
            key={index}
            className={classNames({
              [styles.roundButton]: type === TabType.Round,
              [styles.roundedActiveBtn]: tabName.id === activeTab && type === TabType.Round,
              [styles.roundedInActiveBtn]: tabName.id !== activeTab && type === TabType.Round,
              [styles.activeText]: tabName.id === activeTab,
              [styles.inactiveText]: tabName.id !== activeTab,
            })}
          >
            <Button id={tabName.id} onClick={(e) => handleTabClick(tabName.id)}>
              <Typography size="lg">{tabName.label}</Typography>
            </Button>
          </div>
        ))}
      </div>

      <div
        className={classNames({
          [styles.outer]: type === TabType.Square,
          [styles.hideEl]: type === TabType.Round,
        })}
      >
        {tabNames?.map((tabName) => (
          <div
            key={tabName.id}
            className={classNames(styles[activeTab!], { [styles.clickedText]: activeTab === tabName.id })}
          >
            <div
              className={classNames(styles.boxWrapper, {
                [styles.boxTwoClicked]: activeTab === tabNames[1]?.id,
                [styles.reverBoxOneStyles]: activeTab === tabNames[0]?.id,
                [styles.boxOneClicked]: activeTab === tabNames[1]?.id,
                [styles.reverBoxTwoStyles]: activeTab === tabNames[0]?.id,
                [styles.inactiveTab]: tabName.id !== activeTab,
              })}
            ></div>
            <Button onClick={(e) => handleTabClick(tabName.id)} color="navyBlue">
              <Typography fontFamily="avenirNextSemiBold" fontWeight="font700">{tabName.label}</Typography>
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
