import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/firstGoalScorer.module.css";
import { CardRarityEnum } from "generated/graphql";
import getConfig from "util/configHelper";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

type ScoreState = {
  [key: string]: number;
};

const FirstGoalscorer = () => {
  let firstGoalScorerOddsDetails = sessionHelper.oddsDetails?.firstGoalscorer;
  let keyName = firstGoalScorerOddsDetails?.name as string;
  const { configData } = getConfig();
  debug({ event: "calling", message: "first goal scorer" });
  const selectedFirstGoalScorerOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
    }),
    shallow,
  );
  const [playersList, setPlayersList] = useState(sessionHelper.oddsDetails?.allPlayers); // initially showing all players list ...
  const [openContent, setOpenContent] = useState(true);

  const [score, setScore] = useState<ScoreState>({
    teamA: 0,
    teamB: 0,
  });
  const [showMore, setShowMore] = useState(false); // State to control Show More / Show Less

  const updateScore = (team: string, increment: number) => {
    if (score.teamA + score.teamB === 6 && increment === 1) {
      return;
    }
    let _score = {
      ...score,
      [team]: Math.max(0, Math.min(6, score[team] + increment)),
    };
    setScore({ ..._score });
    let updatedPlayerList = sessionHelper.oddsDetails?.firstGoalscorer.data[`${_score.teamA}-${_score.teamB}`];

    //if combinatino is 0-0 set all Players list
    if (!_score.teamA && !_score.teamB) {
      setPlayersList(sessionHelper.oddsDetails?.allPlayers);
    } else {
      setPlayersList(updatedPlayerList);
    }
    // console.log({ updatedPlayerList, view: `${_score.teamA}-${_score.teamB}` });
  };

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  let firstGoalScorerOdds = sessionHelper.oddsDetails?.firstGoalscorer;
  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const maxVisibleItems = 5; //  how many items to show initially

  const renderPlayer = (id: number) => {
    let fetchPlayerDetails = sessionHelper.oddsDetails?.allPlayers.find((el) => el.playerId === id);

    if (!fetchPlayerDetails) {
      return <Typography size="xs">{configData.bets.notFound}</Typography>;
    }
    const { teamId, name, attackRating, rarity, currentPosition, preferredPosition } = fetchPlayerDetails;

    return (
      <section
        className={classNames(styles.playerDetails, {
          [styles.hide]: currentPosition === "GK",
        })}
      >
        <div
          className={classNames(styles.attRating, {
            [styles.bgBronze]: rarity === CardRarityEnum.Bronze,
            [styles.bgSilver]: rarity === CardRarityEnum.Silver,
            [styles.bgGold]: rarity === CardRarityEnum.Gold,
            [styles.bgInForm]: rarity === CardRarityEnum.InForm,
          })}
        >
          <Typography size="xs3" color={"black"} fontFamily="avenirFont">
            {configData.bets.ATT}
          </Typography>
          <Typography size="md" color="black" fontFamily="avenirNextBold">
            {attackRating}
          </Typography>
        </div>
        <div className={styles.teamDetails}>
          <Typography color="grayPlus" size="md" fontFamily="avenirFont">
            {name}
          </Typography>{" "}
          <Typography color="grayPlus" size="xs" fontFamily="avenirFont">
            {getTeamName(teamId as string)}
          </Typography>
        </div>
      </section>
    );
  };

  const handleUserInteraction = async (id: string, keyName: string) => {
    try {
      let resp = await handleBoxClick(id, keyName, [...selectedFirstGoalScorerOptions]);
      setSelectedOddsOptions(resp || [], keyName);
    } catch (e) {}
  };

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        boxHeading={firstGoalScorerOdds?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.colRowContainer}>
          <div className={styles.row}>
            <div className={classNames(styles.col)}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {" "}
                {sessionHelper.oddsDetails?.teamA.name && sessionHelper.oddsDetails?.teamA.name}
              </Typography>
            </div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {sessionHelper.oddsDetails?.teamB.name && sessionHelper.oddsDetails?.teamB.name}
              </Typography>
            </div>
          </div>

          <div className={styles.row}>
            {Object.entries(score).map(([team, value], index) => (
              <div key={index} className={styles.col}>
                <div
                  className={classNames(styles.plusMinusBox, styles.paddingSm)}
                  onClick={() => updateScore(team, -1)}
                  style={{ cursor: "pointer" }}
                >
                  <img src="icon/minus.png" alt="minusIcon" />
                </div>
                <Typography size="xl" fontFamily="avenirNextSemiBold">
                  {value}
                </Typography>
                <div
                  className={classNames(styles.plusMinusBox, styles.paddingSm)}
                  onClick={() => updateScore(team, 1)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={`icon/${score.teamA + score.teamB === 6 ? "plusGray" : "plus"}.png`} alt="plusIcon" />
                </div>
              </div>
            ))}
          </div>

          {/* Render the players list */}
          {(showMore ? playersList : playersList?.slice(0, maxVisibleItems))?.map(
            (oddDetails: any, oddIndex) =>
              sessionHelper.oddsDetails?.allPlayers.find((el) => el.playerId === oddDetails.playerId)
                ?.currentPosition !== "GK" && (
                <div key={`${oddIndex}-${oddDetails.teamId}`} className={styles.row}>
                  {/* Render player details */}
                  <div className={styles.col}>{renderPlayer(oddDetails.playerId)}</div>
                  <div className={styles.boxWidth}>
                    <BoxSelection
                      value={oddDetails?.odd ? oddDetails.odd : "-"}
                      numberSelectorForTextSize={5}
                      onClick={() => {
                        if (!oddDetails?.odd) return;
                        handleUserInteraction(oddDetails.id, keyName);
                      }}
                      active={
                        selectedFirstGoalScorerOptions.length &&
                        selectedFirstGoalScorerOptions?.some((ele) => ele.oddId === oddDetails.id)
                          ? true
                          : false
                      } // implement active state
                      isClicked={isAddingBetLoading}
                    />
                  </div>
                </div>
              ),
          )}

          {/* Show More / Show Less toggle */}
          {sessionHelper.oddsDetails?.allPlayers.length &&
            sessionHelper.oddsDetails?.allPlayers?.length > maxVisibleItems && (
              <div onClick={() => setShowMore((prev) => !prev)} className={styles.showMoreContainer}>
                <Typography size="lg" className={styles.showMoreButton}>
                  {showMore ? configData.bets.showLess : configData.bets.showMore}
                </Typography>
                <img
                  className={classNames(styles.arrowIcon, {
                    [styles.rotateIcon]: showMore,
                  })}
                  src={"icon/arrowDown.png"}
                  alt=""
                />
              </div>
            )}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(FirstGoalscorer);
