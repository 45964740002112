import React from "react";
import SettingsDrawer from "components/drawers/SettingsDrawer";
import TeamEditDrawer from "components/drawers/TeamEditDrawer";
import TeamNameDrawer from "./TeamNameDrawer";
import SelectNationalityDrawer from "./SelectNationalityDrawer";
import PackSummaryDrawer from "./PackSummaryDrawer";

const DrawerRenderer: React.FC = () => {
  return (
    <>
      <SettingsDrawer />
      <TeamEditDrawer />
      <TeamNameDrawer />
      <SelectNationalityDrawer />
      <PackSummaryDrawer />
    </>
  );
};

export default DrawerRenderer;
