import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Modal from "components/Modal";
import Typography from "components/Typography";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import Button from "components/Button";
import styles from "styles/components/modals/errorPopUpModal.module.css";

const ErrorPopUpModal = () => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.toggleErrorModal, (info) => {
      const { detail } = info;
      
      setMsg(detail.msg);
      setOpen(true);
    });
    return () => {
      appEventInstance.remove(AppEventTypes.toggleErrorModal);
    };
  }, []);

  const handleClose = async () => {
    setOpen(false);

    await setTimeout(() => {
      setMsg("");
    }, 200);
  };

  return (
    <Modal id="errorPopUpModal" open={open} handleClose={handleClose} title="" containerSize="xs" disableOverlayInteraction={true}>
      <div className={classNames(styles.body)}>
        <img src="icon/errorPopUp.png" alt="errorPopUp" className={styles.icon} />
        <div className={classNames(styles.errormsg)}>
          <Typography fontFamily="avenirNextSemiBold">
            {msg}
          </Typography>
        </div>
        <div className={classNames(styles.btn)}>
          <Button color="blueBtn" size="small" onClick={handleClose}>
            <Typography fontFamily="degularBlack" color="navyBlue">
              OK
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ErrorPopUpModal);
