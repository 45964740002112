import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Button from "components/Button";
import Typography from "components/Typography";
import Modal from "components/Modal";
import PackAndCoin from "pages/store/components/PackAndCoin";
import useGameStore from "store/gameStore";
import styles from "styles/components/modals/packAndCoin.module.css"
import { setCurrencyFormat } from "util/miscUtils";
import { store } from "dummy/dummy";
import getConfig from "util/configHelper";
import { PackAndCoinType } from "core/enums";

const PackAndCoinModal = () => {
  const { configData } = getConfig();
  const { isOpenPackAndCoinsModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenPackAndCoinsModal: state.modalStates.isOpenPackAndCoinsModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleClose = () => {
    updateModalStates({ isOpenPackAndCoinsModal: false });
  };

  return (
    <Modal
      id="packAndCoinsModal"
      open={ isOpenPackAndCoinsModal}
      handleClose={handleClose}
      title={configData?.store?.modal?.title}
      subTitle={configData?.store?.modal?.subTitle}
      footer={
        <Button onClick={handleClose} size="stretch" color="greenBtn">
          <Typography color="navyBlue" size="lg">
            {configData?.store?.modal?.footer}
          </Typography>
        </Button>
      }
      containerSize="lg"
    >
      <div className={classNames(styles.modalContainer)}>
        <div className={classNames(styles.firstDiv)}>
          {store?.coins?.map((el: any, index: any) => (
            <div key={index} className={classNames(styles.packCoin)}>
              <PackAndCoin data={el} type={PackAndCoinType.Coin} />
            </div>
          ))}
        </div>
        <div className={classNames(styles.secondDiv)}>
          <div className={classNames(styles.imagesDiv)}>
            <div className={classNames(styles.firstCol)}>
              <img src="images/store/bigCoins.png" className={styles.coinImg} alt="bigcoins" />
              <Typography fontWeight="font700">{store?.multiplyCoins}</Typography>
            </div>
            <Typography fontWeight="font900">+</Typography>
            <div className={classNames(styles.secondCol)}>
              <img src="images/store/randomPack.png" className={styles.packImg} alt="randompack" />
              <Typography fontWeight="font700">{configData?.store?.modal?.pack}</Typography>
            </div>
          </div>
          <div className={classNames(styles.greenRect)}>
            <Typography color="navyBlue">{setCurrencyFormat({ value: 5 })}</Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(PackAndCoinModal);
