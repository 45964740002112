import React, { useState } from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { extractCurrencySymbol, setCurrencyFormat, setNumberFormat } from "util/miscUtils";
import getConfig from "util/configHelper";
import moment from "moment";
import styles from "styles/pages/bets/components/betSlip.module.css";
import sessionHelper from "service/sessionHelper";

type BetItems = {
  betId: string;
  oddId: string;
  betAmount: number;
  winAmount: number;
  toReturn: number;
  marketType: string;
  marketName: string;
  xp: number;
  xpBoostPercentage: number;
  odd: string;
  decimalOdd: number;
  isResolved: boolean;
  betStatus: string;
  createdAt: string;
  oddsDetails: {
    win: string;
    goals: string;
    type: string;
    value: string;
    team1: string;
    team2: string;
    playerId: number;
    teamId: string;
    handicap: string;
    xpBoostPercentage: number;
  };
};

type Bet = {
  createdAt: string;
  myTeamName: string;
  opponentTeamName: string;
  bets: BetItems[];
  allPlayers: [];
};

type Props = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    betSlipData: Bet;
  }
>;

const BetSlip = ({ betSlipData }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const { configData } = getConfig();

  const getTeamName = (name: string) => {
    if (name === "A") return betSlipData.myTeamName || "-";
    if (name === "B") return betSlipData.opponentTeamName || "-";
    return "Draw";
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${month}/${day}/${year} ${hours}:${minutes}`;
  };

  const getPlayerName = (playerId: number | string, allPlayersList: any) => {
    let playerDetails = allPlayersList?.filter((ele: any, index: any) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return "Not Found!";
    }
  };
  let formatDate = moment();

  return (
    <div className={classNames(styles.betSlip)}>
      <header>
        <Typography size="md" fontFamily="avenirNextMedium">
          {`${betSlipData.myTeamName} - ${betSlipData.opponentTeamName}`}
        </Typography>
        <Typography size="xs" fontFamily="avenirNextMedium">
          {`${moment.utc(betSlipData.createdAt).local().format("DD/MM/YYYY HH:mm")}`}
        </Typography>
      </header>
      {betSlipData.bets.map((betItems, betItemsIndex) => (
        <div key={`${betItemsIndex}-${betItems.betId}`} className={classNames(styles.betSlipItems)}>
          <div className={classNames(styles.col)}>
            <img
              className={styles.activeStatusIcon}
              src={`/images/bets/${betItems.isResolved ? "active" : "inActive"}.png`}
              alt="active btn"
            />
          </div>

          {/* =================== */}

          <div className={classNames(styles.col, styles.addGap)}>
            <Typography
              color="grayPlus"
              size={betItems.marketName.length > 25 ? "xs2" : betItems.marketName.length > 18 ? "md" : "md2"}
              fontFamily="avenirNextMedium"
            >
              {betItems.marketName}
            </Typography>
            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.finalResult,
              })}
            >
              {" "}
              <Typography color="white" fontFamily="avenirNextBold" size="md">
                {getTeamName(betItems.oddsDetails.win as string)}
              </Typography>
              <Typography color="green" fontFamily="avenirNextBold" size="md">
                {betItems.odd}
              </Typography>
            </div>

            {/* ================= */}
            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.goalsOverUnder,
              })}
            >
              {" "}
              <Typography color="white" fontFamily="avenirNextBold" size="md">
                {betItems.oddsDetails.type &&
                  betItems.oddsDetails.type.charAt(0).toUpperCase() + betItems.oddsDetails.type.slice(1)}
              </Typography>
              <Typography color="white" fontFamily="avenirNextBold" size="md">
                {betItems.oddsDetails.goals}
              </Typography>{" "}
              <Typography color="green" fontFamily="avenirNextBold" size="md">
                {betItems.odd}
              </Typography>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.bothTeamsToScore,
              })}
            >
              <Typography color="white" fontFamily="avenirNextBold" size="md">
                {betItems.oddsDetails.value}
              </Typography>{" "}
              <Typography color="green" fontFamily="avenirNextBold" size="md">
                {betItems.odd}
              </Typography>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.resultBothTeamsScore,
              })}
            >
              <Typography color="white" fontFamily="avenirNextBold" size="md">
                {getTeamName(betItems.oddsDetails.win as string)} / {betItems.oddsDetails.value}
              </Typography>
              <Typography color="green" fontFamily="avenirNextBold" size="md">
                {betItems.odd}
              </Typography>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.correctScore,
                [styles.flexColDirection]: true,
              })}
            >
              <div>
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {betItems.oddsDetails.team1} - {betItems.oddsDetails.team2}
                </Typography>
                <Typography color="green" fontFamily="avenirFont" size="md2">
                  {betItems.odd}
                </Typography>
              </div>
              <Typography color="white" fontFamily="avenirFont" size="md2">
                {getTeamName(betItems.oddsDetails.win as string)}
              </Typography>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]:
                  betItems.marketType !== configData.gamePannelOptionsHeading.firstLastAnytimeGoalScorerType,
                [styles.flexColDirection]: true,
              })}
            >
              <div>
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {getPlayerName(betItems.oddsDetails.playerId, betSlipData.allPlayers)}
                </Typography>
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {betItems.odd}
                </Typography>
              </div>
              <Typography color="white" fontFamily="avenirFont" size="md2">
                {getTeamName(betItems.oddsDetails.teamId as string)}
              </Typography>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.handicaps,
                [styles.flexColDirection]: true,
              })}
            >
              <div>
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {getTeamName(betItems.oddsDetails.win as string)}
                </Typography>{" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {` ${betItems.oddsDetails.handicap}`}
                </Typography>
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {betItems.odd}
                </Typography>
              </div>
            </div>

            {/* ================= */}

            <div
              className={classNames(styles.marketDetails, {
                [styles.hide]: betItems.marketName !== configData.gamePannelOptionsHeading.firstGoalScorerCorrectScore,
                [styles.flexColDirection]: true,
              })}
            >
              <div className={styles.flexColDirection}>
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {getPlayerName(betItems.oddsDetails?.playerId, betSlipData?.allPlayers)} /
                  {` ${betItems.oddsDetails.team1}-${betItems.oddsDetails.team2} `}
                </Typography>
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {betItems.odd}
                </Typography>
              </div>
              <Typography color="white" fontFamily="avenirFont" size="md2">
                {getTeamName(betItems.oddsDetails.teamId as string)}
              </Typography>
            </div>
            <Typography fontFamily="avenirNextMedium" size="xs2" color="grayPlus" noWrap={true}>
              {configData.betPannelWords.betRef}
              {betItems.betId}
            </Typography>
          </div>

          {/* =================== */}

          <div className={classNames(styles.col)}>
            <div className={classNames(styles.row)}>
              <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                {configData.betPannelWords.stake}
              </Typography>
              <Typography
                fontFamily="avenirNextBold"
                size={
                  setNumberFormat(betItems.betAmount ?? 0, 2).length > 4
                    ? "xs"
                    : setNumberFormat(betItems.betAmount ?? 0, 2).length > 6
                      ? "xs2"
                      : "md"
                }
              >
                {extractCurrencySymbol("£")}
                {setNumberFormat(betItems.betAmount ?? 0, 2)}
              </Typography>
            </div>
            <div className={classNames(styles.row)}>
              <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                {configData.betPannelWords.returned}
              </Typography>
              <Typography
                fontFamily="avenirNextBold"
                size={setNumberFormat(betItems.toReturn ?? 0, 2).length > 6 ? "xs2" : "md"}
                color={betItems.toReturn > 0 ? "green" : "white"}
              >
                {extractCurrencySymbol("£")}
                {setNumberFormat(betItems.toReturn ?? 0, 2)}
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BetSlip;
