type EventPayload = Record<string, any>; // adjust this type based on specific payload structure

interface AppEventProps {
  eventType: string;
  payload?: EventPayload;
}

type CallbackFunction = (event: CustomEvent) => void;

class AppEvent {
  dispatch({ eventType, payload }: AppEventProps): void {
    window.dispatchEvent(new CustomEvent(eventType, { detail: { ...payload } }));
  }

  listen(eventType: string, callback: CallbackFunction = () => {}): void {
    window.addEventListener(eventType, callback as EventListener);
  }

  remove(eventType: string, callback: CallbackFunction = () => {}): void {
    window.removeEventListener(eventType, callback as EventListener);
  }
}

const appEventInstance = new AppEvent();

export default appEventInstance;
