import { useState } from "react";
import classNames from "classnames";
import ContentShell from "components/ContentShell";
import getConfig from "util/configHelper";
import BetSlip from "pages/bets/components/BetSlip";
import styles from "styles/pages/bets/bets.module.css";
import Typography from "components/Typography";
import { BetStatus, DateFilterEnum, useMyBetsQuery } from "generated/graphql";

const Bets = () => {
  const [betsFilters, setBetsFilter] = useState({
    betStatus: null,
    dateFilter: DateFilterEnum.Today,
    matchStatus: null,
    matchId: null,
    sortDir: null,
    sortKey: null,
    skipPagination: null,
    limit: 5,
    pageNo: 1,
  });
  const {
    data: myBetsData,
    loading: myBetsQueryLoading,
    error: myBetsError,
  } = useMyBetsQuery({
    variables: betsFilters,
  });

  const { configData } = getConfig();
  const [activeTabId, setActiveTabId] = useState(configData.betHistoryFilterOptions[0].id);
  const onTabClick = (tabId: DateFilterEnum) => {
    setActiveTabId(tabId);
    setBetsFilter((prev) => {
      return { ...prev, dateFilter: tabId };
    });
    // console.log(myBetsData);
  };

  // return if got an error .....
  if (myBetsError) {
    return (
      <section className={classNames(styles.centerErrorMsg)}>
        <Typography color="white" fontFamily="avenirNextBold" size="lg">
          {configData.bets.myBetsError}
        </Typography>
      </section>
    );
  }

  return (
    <ContentShell loading={false}>
      <section className={classNames(styles.betSlipRoot)}>
        <div className={classNames(styles.betSlipHeader)}>
          <Typography fontFamily="avenirNextSemiBold" color="white" size="lg">
            {configData?.bets?.betHistory}
          </Typography>
        </div>
        <section
          className={classNames(styles.betsFilterList, {
            [styles.showHalfOlderTab]: activeTabId === DateFilterEnum.Last30Days,
            [styles.showFullOlderTab]: activeTabId === DateFilterEnum.Older,
          })}
        >
          {configData.betHistoryFilterOptions.map((data, tabsIndex) => (
            <div
              key={`${tabsIndex}-${data.id}`}
              onClick={() => onTabClick(data.id)}
              className={classNames(styles.list, {
                [styles.active]: data.id === activeTabId,
              })}
            >
              <Typography
                noWrap={true}
                color={data.id === activeTabId ? "purpleBlue" : "white"}
                fontFamily="avenirNextBold"
                size="md"
              >
                {data.label}
              </Typography>
            </div>
          ))}
        </section>

        <section
          className={classNames(styles.betSlipContainer, {
            [styles.centerItems]: myBetsError,
          })}
        >
          {myBetsData?.players?.myBets?.items.length === 0 ? (
            <section className={classNames(styles.centerErrorMsg)}>
              <Typography color="white" size="md" fontFamily="avenirNextBold">
                {configData.bets.myBetsNotFound}
              </Typography>
            </section>
          ) : (
            myBetsData?.players?.myBets?.items?.map((data, index) => {
              if(data.bets.length === 0) return;
              return <BetSlip key={`${data.matchId}-${data.createdAt}`} betSlipData={data as any}  />;
            })
          )}
        </section>
      </section>
    </ContentShell>
  );
};

export default Bets;
