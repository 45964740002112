import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/btts.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import { useAddUpdateBetMutation } from "generated/graphql";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

const BTTS = () => {
  const keyName = sessionHelper.oddsDetails?.btts?.name as string;
  const [openContent, setOpenContent] = useState(true);
  const selectedBttsOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  // console.log(finalResultOddDetails);
  const BttsOddsDetails = sessionHelper.oddsDetails?.btts;
  debug({ event: "calling", message: "BTTS" });
  const { setSelectedOddsOptions } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
    }),
    shallow,
  );

  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    try {
      let resp = await handleBoxClick(id, keyName, [...selectedBttsOptions]);
      setSelectedOddsOptions(resp || [], keyName);
    } catch (e) {}
  };

  return (
    <section className={classNames(styles.root)}>
      <Accordion
        open={openContent}
        boxHeading={BttsOddsDetails?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxList}>
          {BttsOddsDetails?.data.map((item, itemIndex) => (
            <section
              key={`${itemIndex}-${item.__typename}`}
              className={classNames(styles.list, {
                [styles.marginBottom]: itemIndex < BttsOddsDetails.data.length - 1, // Apply marginBottom to all except the last item
              })}
            >
              <Typography color="grayPlus" size="md" fontFamily="avenirFont">
                {item?.value}
              </Typography>
              <div className={styles.col}>
                <BoxSelection
                  active={
                    selectedBttsOptions.length && selectedBttsOptions?.some((ele) => ele.oddId === item.id)
                      ? true
                      : false
                  }
                  onClick={() => handleUserInteraction(item.id, keyName)}
                  value={item.odd}
                  isClicked={isAddingBetLoading}

                />
              </div>
            </section>
          ))}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(BTTS);
