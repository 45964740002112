import React, { useState, useEffect } from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Button from "components/Button";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import { playerDetails } from "dummy/dummy";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/sellPlayer.module.css";
import PlayerCard2 from "components/PlayerCard2";
import { PlayerCardSize, PlayerCardVariant } from "core/enums";
import { UserPlayerCard } from "generated/graphql";

const SellPlayerModal = () => {
  const { configData } = getConfig();
  const [sellPlayer, setSellPlayer] = useState<UserPlayerCard | null>(null);
  const { isOpenSellPlayerModal, updateModalStates, selectedBoosterPlayer } = useGameStore(
    (state) => ({
      isOpenSellPlayerModal: state.modalStates.isOpenSellPlayerModal,
      updateModalStates: state.updateModalStates,
      selectedBoosterPlayer: state.selectedBoosterPlayer,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenSellPlayerModal: false });
  };

  useEffect(() => {
    if (isOpenSellPlayerModal) {
      setSellPlayer(selectedBoosterPlayer);
    }
  }, [isOpenSellPlayerModal]);

  return (
    <Modal
      id="sellPlayerModal"
      open={isOpenSellPlayerModal}
      handleClose={() => handleModalClose()}
      title={configData?.playerDetailsModal?.title}
      subTitle={configData?.playerDetailsModal?.subTitle}
      footer={
        <div className={classNames(styles.footer)}>
          <Button onClick={() => handleModalClose()} size="small" color="blueBtn">
            <Typography color="navyBlue" size="lg" fontFamily="degularBold">
              {configData?.playerDetailsModal?.cancel}
            </Typography>
          </Button>
          <Button onClick={() => handleModalClose()} size="small" color="greenBtn">
            <Typography color="navyBlue" size="lg" fontFamily="degularBold">
              {configData?.playerDetailsModal?.sell}
            </Typography>
          </Button>
        </div>
      }
      containerSize="lg"
    >
      <div className={classNames(styles.body)}>
        {sellPlayer && (
          <PlayerCard2
            playerInfo={sellPlayer}
            size={PlayerCardSize.Md}
            variant={PlayerCardVariant.Extended}
            displayChemistryProgress={true}
          />
        )}
        <div className={classNames(styles.playerNm)}>
          <Typography fontFamily="degularSemiBold">{sellPlayer?.displayName}</Typography>
          <div className={classNames(styles.coins)}>
            <img src="icon/coinIcon.png" alt="coinIcon" className={styles.coinIcon} />
            <Typography fontFamily="degularSemiBold">+{playerDetails?.estimatedCoins}</Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default React.memo(SellPlayerModal);
