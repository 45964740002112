import React from "react";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import Modal from "components/Modal";
import styles from "styles/components/modals/gameRulesModal.module.css";
import classNames from "classnames";

interface Column {
  key: string;
  header: string;
}

interface TableProps {
  columns: Column[];
  data: { [key: string]: React.ReactNode }[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <div className={classNames(styles.tableRoot)}>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.key}>
                <Typography fontFamily="avenirNextBold">{col.header}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col) => (
                <td key={col.key}>
                  <Typography fontFamily="avenirFont" size="xs">
                    {row[col.key]}
                  </Typography>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GameRulesModal = () => {
  const { configData } = getConfig();
  const { isOpenGameRulesModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenGameRulesModal: state.modalStates.isOpenGameRulesModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleClose = () => {
    updateModalStates({ isOpenGameRulesModal: false });
  };

  return (
    <Modal
      id="gameRulesModal"
      open={isOpenGameRulesModal}
      handleClose={handleClose}
      title={configData?.settingsDrawer?.gameRules}
      addBorder={true}
      containerSize="xl"
      noPaddingTop={true}
    >
      <div className={classNames(styles.root)}>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.overview}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.overViewText1}</Typography>
          <Typography size="xs">{configData?.gameRules?.overViewText2}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.playingMatch}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.playingMatchText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.betting}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText1}</Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText2}</Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText3}</Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText4}</Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText5}</Typography>
          <Typography size="xs">{configData?.gameRules?.bettingText6}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtp}</Typography>
          <Table columns={configData?.rtp} data={configData?.rtpData} />
          <Typography size="xs">{configData?.gameRules?.rtpValue1}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue2}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue3}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue4}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue5}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue6}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue7}</Typography>
          <Typography size="xs">{configData?.gameRules?.rtpValue8}</Typography>
          <Table columns={configData?.multipliers} data={configData?.multipliersData} />
          <Typography size="xs">{configData?.gameRules?.odds}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.pbfCoins}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.pbfCoinsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.mechanics}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText1}</Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText2}</Typography>
          <Typography size="xs">• {configData?.gameRules?.mechanicsText3}</Typography>
          <Typography size="xs">• {configData?.gameRules?.mechanicsText4}</Typography>
          <Typography size="xs">• {configData?.gameRules?.mechanicsText5}</Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText6}</Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText7}</Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText8}</Typography>
          <Typography size="xs">{configData?.gameRules?.mechanicsText9}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.myTeam}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.myTeamText1}</Typography>
          <Typography size="xs">{configData?.gameRules?.myTeamText2}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText3}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText4}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText5}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText6}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText7}</Typography>
          <Typography size="xs">• {configData?.gameRules?.myTeamText8}</Typography>
          <Typography size="xs">{configData?.gameRules?.myTeamText9}</Typography>
          <Table columns={configData?.chemistryScore} data={configData?.chemistryScoreData} />
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.boosts}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.boostsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.divisions}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.divisionsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.dailyRewards}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.dailyRewardsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {configData?.gameRules?.store}
          </Typography>
          <Typography size="xs">{configData?.gameRules?.storeText}</Typography>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(GameRulesModal);
