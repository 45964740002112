import { RoutesEnum } from "core/enums";
import { DateFilterEnum } from "generated/graphql";

const config = {
  drawerCloseBtnWords: {
    back: "Back",
    tapToOpen: "Top To Open",
    continue: "Continue",
    addMoreBets: "Add More Bets",
  },
  betHistoryFilterOptions: [
    { id: DateFilterEnum.Today, label: "Today" },
    { id: DateFilterEnum.Last7Days, label: "Last 7 Days" },
    { id: DateFilterEnum.Last30Days, label: "Last 30  Days" },
    { id: DateFilterEnum.Older, label: "Older" },
  ],
  teamEditTabOption: [
    { id: "kits", label: "KITS" },
    { id: "badges", label: "BADGES" },
  ],
  home: {
    carousalCard: [
      {
        image: "images/home/season.png",
      },
      {
        image: "images/home/season.png",
      },
      {
        image: "images/home/season.png",
      },
      {
        image: "images/home/season.png",
      },
    ],
    playmatch: "PLAY MATCH",
    userDetails: {
      playbookFootball: "Playbook Football",
    },
    myClub: {
      club: "CLUB",
      overs: "OVR",
    },
    seasonEnds: {
      division: "Division",
      season: "Season Ends in",
    },
    theVault: {
      vault: "The Vault",
      playMatch: "PLAY MATCH",
      xp: "XP",
    },
  },
  footer: {
    menuData: [
      {
        id: 1,
        src: "images/footer/store.svg",
        clickedSrc: "images/footer/store-clicked.svg",
        alt: "wallet",
        text: "Wallet",
        route: RoutesEnum.Wallet,
      },
      {
        id: 2,
        src: "images/footer/my-bet.svg",
        clickedSrc: "images/footer/my-bet-clicked.svg",
        alt: "my-bets",
        text: "Bets",
        route: RoutesEnum.Bets,
      },
      {
        id: 3,
        src: "images/footer/home.svg",
        clickedSrc: "images/footer/home-clicked.svg",
        alt: "home",
        text: "Home",
        route: RoutesEnum.Home,
      },
      {
        id: 4,
        src: "images/footer/team.svg",
        clickedSrc: "images/footer/team-clicked.svg",
        alt: "team",
        text: "Team",
        route: RoutesEnum.Team,
      },
      {
        id: 5,
        src: "images/footer/store-1.svg",
        clickedSrc: "images/footer/store-1-clicked.svg",
        alt: "store1",
        text: "Store",
        route: RoutesEnum.Store,
      },
    ],
  },
  store: {
    seasonalOffer: {
      seasonal: "SEASONAL",
      offer: "OFFER",
      specialPacks: "Special Packs + 4400 PBF",
    },
    title: {
      packs: "PACKS",
      pbfCoins: "PBF COINS",
      swap: "SWAP",
    },
    modal: {
      title: "Not Enough Coins",
      subTitle: "Do you want to buy more coins?",
      footer: "Go To Store",
      pack: "Common Pack",
    },
  },
  bets: {
    single: "Single",
    value: "Value",
    bettingMarket: "Betting Market",
    myTeamFc: "My Team FC",
    team: "My Team FC v  ToLoose FC",
    totalStake: "Total Stake",
    totalToReturn: "Total To Return",
    xValue: "X",
    betHistory: "Bet History",
    notFound: "data not found !",
    myBetsNotFound: "No bet history found.",
    myBetsError: "Error Fetching My Bets Data....",
    bettingOddsNotFound: "Bettings Odds Not Found !",
    ATT: "ATT",
    teamA: "A",
    teamB: "B",
    over: "Over",
    addedToSlip: "ADDED_TO_SLIP",
    debitSuccess: "DEBIT_SUCCESS",
    under: "Under",
    yes: "Yes",
    no: "No",
    showMore: "Show All",
    showLess: "Show Less",
    first: "First",
    last: "Last",
    anytime: "Anytime",
    toReturn: "To Return",
    updateStakeSlip: "update_stake_slip",
    vaultXpBoost: " Vault XP Boost = ",
    v: " v ",
    minMaxValueList: [
      { list: "Maximum stake allowed £250.00" },
      { list: "Min Bet should be £0.10" },
      { list: " Max Bet should be £10,000" },
      { list: "Max Payout should be £250,000" },
    ],
  },
  team: {
    details: "Details",
    swap: "swap",
    formation: "Formation",
    allPlayers: "All Players",
    allBoosters: "All Boosts",
    addBooster: "SELECT BOOST",
    boost: "Boost",
    matches: "Matches",
    modal: {
      buildingChemistry: {
        title: "Building Chemistry",
        body: "Build chemistry by adding players from the same club or nationality, as well as playing them in their primary position. Each player can have a maximum chemistry contribution of 4.",
      },
    },
    SwapPlayerDrawer: {
      forwardPlayer: "FORWARD PLAYERS",
      choosePlayer: "CHOOSE PLAYER",
      toSwap: "TO SWAP",
      swap: "SWAP PLAYER",
      swapBtn: "SWAP",
    },
    playerDetails: {
      statoption: {
        attack: "Attack",
        defence: "Defence",
        creativity: "Creativity",
      },
    },
  },
  teamEdit: {
    kits: [
      {
        image: "images/team/teamEdit/kits/Black.png",
        name: "Black",
      },
      {
        image: "images/team/teamEdit/kits/Green.png",
        name: "Green",
      },
      {
        image: "images/team/teamEdit/kits/LightBlue.png",
        name: "LightBlue",
      },
      {
        image: "images/team/teamEdit/kits/NavyBlue.png",
        name: "NavyBlue",
      },
      {
        image: "images/team/teamEdit/kits/Orange.png",
        name: "Orange",
      },
      {
        image: "images/team/teamEdit/kits/Purple.png",
        name: "Purple",
      },
      {
        image: "images/team/teamEdit/kits/Red.png",
        name: "Red",
      },
      {
        image: "images/team/teamEdit/kits/Yellow.png",
        name: "Yellow",
      },
    ],
    badges: [
      {
        image: "images/team/teamEdit/badges/badge1.svg",
        name: "badge1",
      },
      {
        image: "images/team/teamEdit/badges/badge2.svg",
        name: "badge2",
      },
      {
        image: "images/team/teamEdit/badges/badge3.svg",
        name: "badge3",
      },
      {
        image: "images/team/teamEdit/badges/badge4.svg",
        name: "badge4",
      },
      {
        image: "images/team/teamEdit/badges/badge5.svg",
        name: "badge5",
      },
      {
        image: "images/team/teamEdit/badges/badge6.svg",
        name: "badge6",
      },
      {
        image: "images/team/teamEdit/badges/badge7.svg",
        name: "badge7",
      },
      {
        image: "images/team/teamEdit/badges/badge8.svg",
        name: "badge8",
      },
      {
        image: "images/team/teamEdit/badges/badge9.svg",
        name: "badge9",
      },
      {
        image: "images/team/teamEdit/badges/badge10.svg",
        name: "badge10",
      },
      {
        image: "images/team/teamEdit/badges/badge11.svg",
        name: "badge11",
      },
      {
        image: "images/team/teamEdit/badges/badge12.svg",
        name: "badge12",
      },
      {
        image: "images/team/teamEdit/badges/badge13.svg",
        name: "badge13",
      },
      {
        image: "images/team/teamEdit/badges/badge14.svg",
        name: "badge14",
      },
      {
        image: "images/team/teamEdit/badges/badge15.svg",
        name: "badge15",
      },
      {
        image: "images/team/teamEdit/badges/badge16.svg",
        name: "badge16",
      },
      {
        image: "images/team/teamEdit/badges/badge17.svg",
        name: "badge17",
      },
      {
        image: "images/team/teamEdit/badges/badge18.svg",
        name: "badge18",
      },
      {
        image: "images/team/teamEdit/badges/badge19.svg",
        name: "badge19",
      },
      {
        image: "images/team/teamEdit/badges/badge20.svg",
        name: "badge20",
      },
    ],
    tabs: {
      kits: "kits",
      badges: "badges",
      myteam: "My Team FC",
      select: "SELECT",
    },
  },
  playerInfo: [
    { key: "ovr", label: "OVERALL" },
    { key: "attack", label: "ATTACK" },
    { key: "defence", label: "DEFENCE" },
    { key: "creativity", label: "CREATIVITY" },
  ],
  teamSelect: {
    myTeam: " My Team fc",
    loosefTeam: "To Loose fc",
    estimatedTime: "Estimated queue time 5 s",
    continue: "continue",
    ovr: "ovr",
    matchMakingOpp: "Matchmaking Opponent ",
  },
  Drawer: {
    mainTitle: "Settings",
    drawerContent: [
      {
        title: "ACCOUNT MANAGEMENT",
        content: [
          { subtitle: "LINK ACCOUNTS", text: "Link to personal accounts" },
          { subtitle: "UID", text: "546729287618390204" },
        ],
      },
      {
        title: "CUSTOMIZATION",
        content: [
          { subtitle: "GAMEPLAY", text: "Adjust gameplay preferences" },
          { subtitle: "GRAPHICS", text: "Adjust graphics level of details" },
          { subtitle: "LANGUAGE", text: "Select menu language" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
        ],
      },
      {
        title: "HELP AND FEEDBACK",
        content: [
          { subtitle: "LANGUAGE", text: "Select menu language" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
          { subtitle: "LANGUAGE", text: "Select menu language" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
        ],
      },
      {
        title: "ADVANCED SETTINGS",
        content: [
          { subtitle: "LANGUAGE", text: "Select menu language" },
          { subtitle: "UNITS", text: "Change between SI and US units" },
        ],
      },
    ],
  },
  playerDetails: {
    fisrtName: "MARCO",
    secondName: "VELAZ",
    playerStats: [
      { label: "Age", value: 26 },
      { label: "Weight", value: 75 },
      { label: "Height", value: 186 },
    ],
    chemistry: "CHEMISTRY",
    estimatedvalue: "QUICK SELL VALUE",
    overs: "40",
    position: "ST",
    addBooster: "ADD BOOST",
    removeBooster: "REMOVE BOOST",
    sell: "SELL",
  },
  playerDetailsModal: {
    title: "Sell Player",
    subTitle: "Are you sure you want to sell this player?",
    playerName: "MARCO VELAZ",
    coins: "500",
    sell: "SELL",
    cancel: "CANCEL",
  },
  packModal: {
    title: "Congratulations!",
    subTitle: "You Get:",
    watchNow: "Watch Now!",
    resetIn: "Reset in",
    info: "This chest opens immediately",
  },
  storeCard: {
    text1: "Buy more players",
    text2: "GO TO STORE",
  },
  swapPLayer: {
    forwardPlayer: "FORWARD PLAYERS",
    choosePlayer: "CHOOSE PLAYER",
    toSwap: "TO SWAP",
    swap: "SWAP",
  },
  settings: [
    { key: "fractional", label: "Fractional" },
    { key: "decimal", label: "Decimal" },
    { key: "american", label: "American" },
  ],
  settingsDrawer: {
    settings: "Settings",
    exit: "Exit Game",
    oddsDisplay: "Odds Display",
    information: "Information",
    help: "Help",
    sounds: "Sounds",
    audio: "Audio",
    gameRules: "Game Rules",
  },

  accordion: [
    {
      openContent: false,
      key: "feedback",
      label: "Game Rules",
      value:
        "Games rules will be displayed here go throught it once.Games rules will be displayed here go throught it once.",
    },
  ],

  keypadNumberOptions: [
    {
      key: "1",
      value: 1,
    },
    {
      key: "2",
      value: 2,
    },
    {
      key: "3",
      value: 3,
    },
    {
      key: "4",
      value: 4,
    },
    {
      key: "5",
      value: 5,
    },
    {
      key: "6",
      value: 6,
    },
    {
      key: "7",
      value: 7,
    },
    {
      key: "8",
      value: 8,
    },
    {
      key: "9",
      value: 9,
    },
    {
      key: "decimal",
      value: ".",
    },
    {
      key: "0",
      value: 0,
    },
    {
      key: "icon",
      value: "",
    },
  ],
  keyPadDefaultOptions: [1, 2, 5, 10],
  betPannelWords: {
    placeBet: "PLACE BET",
    betPlaced: "Bet Placed",
    startMatch: "START MATCH",
    betRef: "Bet ref: ",
    dummyRefCode: "YGHGSYJ8638",
    stake: "Stake",
    returned: "Returned",
    bettingMarket: "Betting Market",
    myTeamVersus: "My TEAM FC v ToLoose FC",
    toReturn: "To Return ",
    done: "DONE",
    value: "Value",
    selection: "Selection",
    selections: "Selections",
  },
  updateStakePannelOptionsKeys: [],
  gamePannelOptionsHeading: {
    finalResult: "Final Result",
    goalsOverUnder: "Goals - Over / Under",
    bothTeamsToScore: "Both Teams to Score",
    resultBothTeamsScore: "Result / Both Teams to Score",
    correctScore: "Correct Score",
    firstLastAnytimeGoalScorer: "First / Last / Anytime Goalscorer",
    firstLastAnytimeGoalScorerType: "FIRST_LAST_ANY_GOAL_SCORE",
    firstGoalScorer: "First Goalscorer",
    lastGoalScorer: "Last Goalscorer",
    AnyTimeGoalScorer: "Anytime Goalscorer",
    handicaps: "Handicaps",
    firstGoalScorerCorrectScore: "First Goalscorer / Correct Score",
  },
  ftuiPages: {
    nameYourTeam: " Name Your Team",
    teamName: "Team Name",
    sileverPack: "SILVER PACK",
    summary: "SUMMARY",
    selectNationlaity: "Select nationality for your welcome pack.",
    welcomepackMsg:
      "Welcome to Playbook Football! Build your dream team, go up against players from around the world, bet on the games, and climb up the Divisions for better rewards... Let's get you started by creating a team name.",
    canvasmsg:
      "Here's a pack containing players to start building your team. You can get more packs to improve your team in the shop.",
  },
  rtp: [
    { key: "market", header: "Market" },
    { key: "rtp", header: "RTP" },
  ],
  rtpData: [
    { market: "Win-Draw Win", rtp: "(x%)" },
    { market: "BTTS", rtp: "(x%)" },
    { market: "Under/Over", rtp: "(x%)" },
    { market: "Correct Score", rtp: "(x%)" },
    { market: "1X2 & BTTS", rtp: "(x%)" },
    { market: "Handicap", rtp: "(x%)" },
    { market: "1st Player to Score", rtp: "(x%)" },
    { market: "1st Player to Score & Correct Score", rtp: "(x%)" },
    { market: "Player to score Anytime", rtp: "(x%)" },
  ],
  multipliers: [
    { key: "odds", header: "Odds" },
    { key: "multiplier", header: "Points Multiplier (Bonus)" },
  ],
  multipliersData: [
    { odds: "Up to 2/1 (3.0, +200)", multiplier: "x1" },
    { odds: "Greater than 2/1 (3.0, +200)", multiplier: "x 1.2 (+20%)" },
    { odds: "Greater than 5/1 - (6.0, +500)", multiplier: "	x 1.4 (+40%)" },
    { odds: "Greater than 10/1 - (11.0, +1000)", multiplier: "	x 2 (+100%)" },
  ],
  chemistryScore: [
    { key: "cPoints", header: "Chemistry Points" },
    { key: "cRating", header: "Team Chemistry Rating (%)" },
    { key: "ovrEffect", header: "Team OVR Effect" },
  ],
  chemistryScoreData: [
    { cPoints: "0", cRating: "0", ovrEffect: "0" },
    { cPoints: "4", cRating: "9", ovrEffect: "" },
    { cPoints: "8", cRating: "18", ovrEffect: "" },
    { cPoints: "12", cRating: "27", ovrEffect: "" },
    { cPoints: "16", cRating: "36", ovrEffect: "" },
    { cPoints: "20", cRating: "45", ovrEffect: "1" },
    { cPoints: "24", cRating: "55", ovrEffect: "" },
    { cPoints: "28", cRating: "64", ovrEffect: "2" },
    { cPoints: "32", cRating: "73", ovrEffect: "" },
    { cPoints: "36", cRating: "82", ovrEffect: "3" },
    { cPoints: "40", cRating: "91", ovrEffect: "" },
    { cPoints: "44", cRating: "100", ovrEffect: "4" },
  ],
  modalPack: {
    series: "SERIES 1",
    freepack: "Free Pack",
    rewardsCalender: [
      { id: "1", rewards: "30", pack: "images/packs/smallCoin.png", width: "4.1rem" },
      { id: "2", rewards: "50", pack: "images/packs/smallCoin.png", width: "4.1rem" },
      { id: "3", rewards: "4,750", pack: "images/packs/smallCoin.png", width: "4.1rem" },
      { id: "4", rewards: "100", pack: "images/packs/bigCoin.png", width: "5.5rem" },
      { id: "5", rewards: "1 x Boost", pack: "images/packs/booster.png", width: "2.1rem" },
      { id: "6", rewards: "1", pack: "images/packs/pack.png", width: "2rem" },
    ],
  },
  gameRules: {
    overview: "Overview",
    overViewText1:
      "Playbook Football is a virtual football strategy betting game that offers 2D Football simulated matches 24 hours a day, 7 days a week, in an on-demand environment. Users can open packs, build their team, play matches, and climb divisions.",
    overViewText2:
      "The outcomes of the games are determined by a Random Number Generator (RNG) with weighting applied proportionally to the higher-ranked teams. Teams and players are ranked via Overall, Attack, Creativity, and Defence.",
    playingMatch: "Playing a Match",
    playingMatchText:
      "Users create and manage their team on the My Team page. When entering a match, they're paired with a randomly selected opponent team. The opponent's team is chosen from a pool of teams with a similar overall rating, based on the user's current division.",
    betting: "Betting",
    bettingText1:
      "Before a match begins, users have the opportunity to place bets on the outcome of the upcoming match. Users select their desired bets, input a stake amount, and click the 'Place Bet' button. Games in Playbook Football are on-demand, meaning they start at the user's discretion. Matches commence once bets are placed and the user initiates the match by clicking the 'Start Now' button.",
    bettingText2:
      "Bets on Playbook Football are settled according to real-world football rules. The Return to Player (RTP) for each betting market ranges from (x%) to (x%) depending on the betting market. This RTP represents the theoretical long-term return for players. Actual individual results may vary significantly, especially over shorter periods of play.",
    bettingText3: "The fairness of the Random Number Generator has been independently tested and certified by eCogra.",
    bettingText4:
      "eCogra’s evaluation of Playbook Football’s RNG (random number generator) was performed with the aim of verifying compliance with the following sets of standards: The Gambling Commission's Remote Gambling and Software Technical Standards, June 2007. Generally-accepted industry standards for highly-regulated jurisdictions.",
    bettingText5: "Clicking any bet selections will place the selected bet into the bet slip.",
    bettingText6:
      "The bet’s value is defined by the stake selected by the user. Bets are submitted once the user clicks ‘place bet’",
    rtp: "RTP’s for each betting market are listed below:",
    rtpValue1:
      "Once the match begins, bets will be presented below the match simulation. There is an option to skip directly to the end of the match which provides an immediate bet settlement.",
    rtpValue2:
      "By selecting the bet history option on the bottom navigation menu, users can view their bets history. Bet history includes previous bets over 7 days or 1 month.",
    rtpValue3:
      "A malfunction leading to a game being generated incorrectly for any reason will void all bets for that game and return all of the original stakes to the player.",
    rtpValue4: "Bets cannot be altered or cancelled once placed.",
    rtpValue5: "Winnings are credited to the users account immediately after the match simulation has finished.",
    rtpValue6:
      "In the event of a dispute regarding game outcomes or bets, the decision of the game administrators is final.",
    rtpValue7:
      "The Maximum Win is capped at £100,000 for any single bet. Any bet placed in breach of this is capped at £100,000 pay-out.",
    rtpValue8:
      "Every bet that is placed will earn XP for ‘The Vault' this is calculated by multiplying the stake of the bet with the below multipliers:",
    odds: "Playbook Football offers odds in decimal, fractional, and US formats based on percentage chances. Fractional odds are always rounded up for the user in cases where a known fractional odds doesn't match the percentage chance. In cases where an exact fractional equivalent doesn't exist, the odds are rounded up to the nearest fraction. For instance, a probability of a bet that’s 79%, would have decimal odds of 1.27, the fractional odds conversion would be 2/7 not 1/4.",
    pbfCoins: "PBF Coins",
    pbfCoinsText:
      "Playbook Fusion Coins (PBF Coins) are the virtual currency used within the game. These coins can be acquired by purchasing them with real money through the in-game Store. It's important to note that PBF Coins cannot be redeemed for real-world currency. Once purchased, PBF Coins can be used to buy in-game items such as player packs.",
    mechanics: "Packs",
    mechanicsText1: "Packs can be purchased with PBF coins or received as rewards throughout the game.",
    mechanicsText2: "Types of Packs:",
    mechanicsText3:
      "Standard Packs: Available at all times, containing a variety of Players and consumables, with standard drop rates",
    mechanicsText4:
      "Event Packs: Time-limited packs offering exclusive or higher-drop-rate items tied to a specific event or promotion",
    mechanicsText5: "Special Packs: Premium packs that include the rarest Players",
    mechanicsText6:
      "Each pack has a predetermined chance of dropping specific Players or consumables categorised by rarity (e.g., Gold, Silver, Bronze). The drop rates for each rarity tier are displayed transparently before opening a pack.",
    mechanicsText7:
      "If a user gets a duplicate Player, they will automatically be rewarded the quick sell value of that player.",
    mechanicsText8:
      "Once a pack is opened, the result is final. There are no refunds or exchanges for opened packs or spent currency.",
    mechanicsText9: "Rewards from packs will be delivered to the users inventory immediately after the pack is opened.",
    myTeam: "My Team",
    myTeamText1:
      "This is where users build their team. Players have 4 ratings: Defence, Creativity, Attack, and Overall. All players in the team contribute to the team's Overall Rank.",
    myTeamText2:
      "Team Chemistry makes your team play better, and it is the sum of individual player Chemistry points earned via a number of factors:",
    myTeamText3: "1 point if the player is in their primary position",
    myTeamText4: "1 point if the player shares the same nationality with 2 other players",
    myTeamText5: "2 points if the player shares the same nationality with 4 or more players",
    myTeamText6: "1 point if the player shares the same club with another player",
    myTeamText7: "2 points if the player shares the same club with 2 or more players",
    myTeamText8: "1 point if all players in that group are in their preferred position",
    myTeamText9:
      "Each player can have a maximum of 4 points in Chemistry. Based on the total chemistry score, the Team will receive an Overall bonus.",
    boosts: "Boosts",
    boostsText:
      "Boost cards can be applied to players to improve their stats (Defence, Creativity, Attack, All) for a specific number of games. A maximum of 3 Boost cards can be active on a team at once.",
    divisions: "Divisions",
    divisionsText:
      "All users begin their journey in Division 10. As users win, draw, or lose matches, they move up, stay the same, or move down division tiers, respectively. However, there's a safety net to prevent immediate demotion. Users can earn rewards for reaching specific milestones within each division. Seasons last for 7 days, after which users are reset to the start of their current division and rewarded for their progress over the last season. Division 10 operates on a different cycle and is not subject to the 7-day season reset.",
    dailyRewards: "Daily Rewards",
    dailyRewardsText:
      "By logging into Playbook Football on consecutive days, users will receive rewards. These rewards increase in value up to the 7th consecutive day, after day 7 the cycle is reset. If a user misses a day, the daily reward streak is reset.",
    store: "Store",
    storeText:
      "This is where users can purchase PBF Coins using real-world currency. Once purchased, these PBF Coins can be used to buy various in-game items, such as player packs.",
  },
  xpBoostInfo: {
    xpBoost: "XP Boost",
    forEveryStake: "For every £1 staked, users get 5XP.",
    forEvery: "For every",
    euro: "£1",
    staked: "staked, users get",
    xp: "5XP",
    xpBoostedInfo: "XP is boosted depending on the odds of the bet.",
    xpOddsInfoList: [
      { item: "Greater than 2/1 =", percent: "20%", boost: " boost." },
      { item: "Greater than 5/1 = ", percent: "40%", boost: " boost." },
      { item: "Greater than 10/1 =", percent: "100%", boost: " boost." },
    ],
  },
  stakePannelAnimationViews: {
    // removeKeypad: "removeKeypad",
    // addKeypad: "addKeypad",
    // selectedStakeView: "selectedStakeView",
    // betPlaceView: "betPlaceView",
    selectedOddsInputsView: "selectedOddsInputsView",
    selectedOddsLabelsView: "selectedOddsLabelsView",
    selectedOddsInputsAndKeypadView: "selectedOddsInputsAndKeypadView",
    betPlaceView: "betPlaceView",
  },
};

export default config;
