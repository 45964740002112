import { Route, Routes, Navigate } from "react-router-dom";
import { routes } from "./config";
import { RoutesEnum } from "core/enums";

export default function Router() {
  return (
    <Routes>
      {/* Redirect / to /home */}
      <Route path="/" element={<Navigate to={RoutesEnum.Home} replace />} />
      {/* <Route path="/page-not-found" element={<Navigate to={RoutesEnum.NotFound} replace />} /> */}

      {/* Render other routes */}
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
