import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import styles from "styles/components/accordion.module.css";
import Typography from "components/Typography";

type AccordionProps = {
  open: boolean;
  boxHeading: string;
  handleHeaderClick: () => void;
  type?: string;
  icon?: string;
  children: React.ReactNode;
};

const Accordion = ({
  open,
  boxHeading,
  handleHeaderClick,
  children,
  type,
  icon = "icon/arrowDown.png",
}: AccordionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      if (open) {
        content.style.maxHeight = `${content.scrollHeight}px`;
      } else {
        content.style.maxHeight = "0";
      }
    }
  }, [open, children]); // calculate height recalculation when children change

  return (
    <section className={styles.root}>
      <header onClick={handleHeaderClick} className={styles.accordionHeader}>
        <Typography size="md" fontFamily="avenirNextSemiBold">
          {boxHeading}
        </Typography>{" "}
        <img
          src={icon}
          className={classNames(styles.accordionIcon, {
            [styles.rotateAccordionIcon]: open,
          })}
          alt="arrowIcon"
        />
      </header>
      <div ref={contentRef} className={classNames(styles.childRoot)}>
        {children}
      </div>
    </section>
  );
};

export default React.memo(Accordion);
