import React, { useEffect, PropsWithChildren, HTMLAttributes } from "react";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, CanvasEvents } from "core/enums";
import Button from "components/Button";
import style from "styles/components/footer.module.css";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  showFooter?: boolean;
}

const Footer: React.FC<Props> = ({ showFooter = true }) => {
  const { configData } = getConfig();
  const { selectedFooterMenuId, setSelectedFooterMenuId, updateDrawerStates } = useGameStore(
    (state) => ({
      updateDrawerStates: state.updateDrawerStates,
      selectedFooterMenuId: state.selectedFooterMenuId,
      setSelectedFooterMenuId: state.setSelectedFooterMenuId,
    }),
    shallow,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [btnText, setBtnText] = React.useState<{
    event: string;
    label: string;
    forCanvas?: boolean;
    callback?: () => {};
    disable?: boolean;
  } | null>(null);

  const handleMenuClick = (id: number, route: string) => {
    setSelectedFooterMenuId(id);
    navigate(`${route}${location.search}`);
  };

  const onButtonClick = () => {
    try {
      if (btnText?.forCanvas) {
        console.log("trigerring to canvas", {
          type: btnText?.event === CanvasEvents.showBack ? CanvasEvents?.removeBack : CanvasEvents.removeContinue,
          value: null,
        });
        setBtnText(null);

        //@ts-ignore
        window?.gameExtension({
          type: btnText?.event === CanvasEvents.showBack ? CanvasEvents?.removeBack : CanvasEvents.removeContinue,
          value: null,
        });
      } else {
        console.log("wrapper", btnText)
        setTimeout(() => (btnText?.callback ? btnText?.callback() : null), 0);
        setBtnText(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const _handleCanvasEvent = (info: CustomEvent) => {
    const { detail } = info;

    //if no forCanvas is passed then it for handling react code
    if (!detail?.messageName) {
      setBtnText({ ...detail });
    } else {
      switch (detail.messageName) {
        case CanvasEvents.showBack:
          setBtnText({ event: detail?.messageName, label: configData.drawerCloseBtnWords.back, forCanvas: true });
          break;
        case CanvasEvents.showContinue:
          setBtnText({ event: detail?.messageName, label: configData.drawerCloseBtnWords.continue, forCanvas: true });
          break;
        case CanvasEvents.removeBack:
          setBtnText(null);
          break;
        case CanvasEvents.removeContinue:
          setBtnText(null);
          break;
        default:
      }
    }
  };

  // Update the selected menu ID based on the current route
  useEffect(() => {
    const currentMenu = configData?.footer?.menuData?.find((menu) => menu.route === location.pathname);

    if (currentMenu && selectedFooterMenuId !== currentMenu.id) {
      setSelectedFooterMenuId(currentMenu.id);
    }
  }, [location.pathname]);

  useEffect(() => {
    // console.log("first", AppEventTypes.toogleFooter);
    appEventInstance.listen(AppEventTypes.toogleFooter, _handleCanvasEvent);

    return () => {
      appEventInstance.remove(AppEventTypes.toogleFooter, () => {});
    };
  }, []);

  return (
    <div
      className={classNames(style.root, {
        [style.hiddenDiv]: !showFooter,
      })}
    >
      {!btnText?.event ? (
        configData?.footer?.menuData?.map((icon) => (
          <div
            key={icon.id}
            className={classNames(style.menuContainer, {
              [style.showText]: selectedFooterMenuId === icon.id,
              [style.hiddenText]: selectedFooterMenuId !== icon.id,
            })}
            onClick={() => handleMenuClick(icon.id, icon.route)}
          >
            <div
              className={classNames(style.menuHrLine, {
                [style.show]: selectedFooterMenuId === icon.id,
                [style.hiddenDiv]: selectedFooterMenuId !== icon.id,
              })}
            />
            <img
              src={selectedFooterMenuId === icon.id ? icon.clickedSrc : icon.src}
              alt={icon.alt}
              className={classNames(style.menuImg, {
                [style.selected]: selectedFooterMenuId === icon.id,
              })}
            />
            <Typography>{icon.text}</Typography>
          </div>
        ))
      ) : (
        <Button
          size="stretch"
          displayFlex={true}
          color="blackBtn"
          onClick={() => onButtonClick()}
          disabled={btnText?.disable}
        >
          <img
            src="icon/backArrow.png"
            alt="backButton"
            className={classNames(style.backButtonImage, {
              [style.backButtonImgHidden]: btnText?.event === CanvasEvents.showBack,
              [style.hide]: btnText?.event !== CanvasEvents.showBack,
            })}
          />
          <Typography fontFamily="degularBold" size="xl" transform={true} hide={btnText?.disable}>
            {btnText?.label}
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default Footer;
