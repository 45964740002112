import React from "react";
import BTTS from "pages/stakePannelOptions/components/oddsList/BTTS";
import CorrectScore from "pages/stakePannelOptions/components/oddsList/CorrectScore";
import FinalResult from "pages/stakePannelOptions/components/oddsList/FinalResult";
import FirstGoalscorer from "pages/stakePannelOptions/components/oddsList/FirstGoalscorer";
import GoalScorerOdds from "pages/stakePannelOptions/components/oddsList/GoalScorerOdds";
import GoalsUnderOver from "pages/stakePannelOptions/components/oddsList/GoalsUnderOver";
import Handicaps from "pages/stakePannelOptions/components/oddsList/Handicaps";
import ResultBtts from "pages/stakePannelOptions/components/oddsList/ResultBtts";
import { debug } from "util/miscUtils";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/bettingOdds.module.css";

const BettingsOdds: React.FC = () => {
  debug({ event: "calling", message: "betting odds list ..." });

  return (
    <section className={styles.root}>
      <FinalResult />
      <GoalsUnderOver />
      <BTTS />
      <ResultBtts />
      <CorrectScore />
      <GoalScorerOdds />
      <Handicaps />
      <FirstGoalscorer />
    </section>
  );
};

export default React.memo(BettingsOdds);
