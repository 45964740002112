import React from "react";
import classNames from "classnames";
import Button from "components/Button";
import Typography from "components/Typography";
import Modal from "components/Modal";
import getConfig from "util/configHelper";
import appEventInstance from "util/eventHandler";
import { CanvasMessage, AppEventTypes } from "core/enums";
import styles from "styles/components/modals/packModal.module.css";

const PackModal = () => {
  const { configData } = getConfig();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

    window.gameExtension({
      type: CanvasMessage.packModalClosed,
      value: {},
    });
  };

  React.useEffect(() => {
    appEventInstance.listen(AppEventTypes.togglePackModal, (info) => {
      setOpen(true);

      //@ts-ignore
      window?.gameExtension({
        type: CanvasMessage.showFTUI,
        value: { isFTUI: true },
      });
    });

    return () => {
      appEventInstance.remove(AppEventTypes.togglePackModal);
    };
  }, []);

  return (
    <Modal
      id="packAndCoinsModal"
      open={open}
      handleClose={handleClose}
      containerSize="xl"
      title={configData?.packModal?.title}
      disableScroll={true}
      subTitle={
        <section className={styles.label}>
          <Typography fontFamily="avenirNextBold">{configData?.packModal?.subTitle}</Typography>
          <div>
            <img src="icon/coinIcon.png" alt="coin" height="12px" width="12px" />
            <Typography fontFamily="avenirNextBold">30</Typography>
          </div>
        </section>
      }
      footer={
        <Button onClick={handleClose} size="stretch" color="greenBtn">
          <div className="py-2">
            <Typography color="navyBlue" size="lg" fontFamily="degularBold">
              {configData?.packModal?.watchNow}
            </Typography>
          </div>
        </Button>
      }
    >
      <div>
        <div className={classNames(styles.packRows)}>
          {configData?.modalPack?.rewardsCalender.map((el, id) => (
            <div
              className={classNames(styles.packs, (el.id === "1" || el.id === "2") && styles.specialGradient)}
              key={id}
            >
              <div>
                <Typography color="navyBlue" fontFamily="avenirNextBold" size="md2">
                  {el.id}
                </Typography>
              </div>
              <img src={el.pack} alt="packimg" style={{ width: el.width }} />
              <div className={classNames(styles.rewards)}>
                <Typography color="navyBlue" fontFamily="avenirNextBold" size="md2">
                  {el.rewards}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <div className={classNames(styles.goldPack)}>
          <div className={classNames(styles.day)}>
            <Typography color="navyBlue" fontFamily="avenirNextBold" size="md2">
              7
            </Typography>
          </div>
          <div className={classNames(styles.packText)}>
            <div>
              <Typography color="navyBlue" fontFamily="avenirNextBold" size="md2">
                {configData?.modalPack?.series}
              </Typography>
              <Typography color="navyBlue" fontFamily="degularBlack" size="2xl">
                {configData?.modalPack?.freepack}
              </Typography>
            </div>
            <img src="images/packs/goldpack.png" alt="pack" />
          </div>
        </div>
        <section className={styles.info}>
          <Typography fontFamily="degular">{configData?.packModal?.info}</Typography>
          <Typography fontFamily="degular">{configData?.packModal?.resetIn}</Typography>
          <Typography fontFamily="degularBlack" size="2xl">{`0d 0h 27m 54s`}</Typography>
        </section>
      </div>
    </Modal>
  );
};

export default React.memo(PackModal);
