import { useEffect } from "react";

function useDisableZoom() {
  useEffect(() => {
    // Handle multi-touch to prevent zooming
    function touchHandler(event: TouchEvent) {
      if (event.touches.length > 1) {
        // Prevent default behavior if there are multiple touches (to prevent zoom)
        event.preventDefault();
      }
    }

    // Only prevent touchmove's default behavior for multi-touch events (zoom), not single-touch (scroll)
    const preventMultiTouchZoom = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", touchHandler, { passive: false });
    document.addEventListener("touchmove", preventMultiTouchZoom, { passive: false });

    return () => {
      document.removeEventListener("touchstart", touchHandler);
      document.removeEventListener("touchmove", preventMultiTouchZoom);
    };
  }, []);
}

export default useDisableZoom;
