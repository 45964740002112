import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import appEventInstance from "util/eventHandler";
import getConfig from "util/configHelper";
import { CanvasView, AppEventTypes, CanvasEvents, RoutesEnum, CanvasMessage } from "core/enums";
import styles from "styles/pages/matchResult/matchResult.module.css";
import classNames from "classnames";
import sessionHelper from "service/sessionHelper";

const MatchResult = () => {
  const { configData } = getConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const [showPromotion, setShowpromotion] = React.useState(false);

  React.useEffect(() => {
    let matchId = sessionHelper.oddsDetails?.matchId;
    if (matchId) {
      //@ts-ignore
      window.gameExtension({ type: CanvasMessage.matchId, value: { matchId } });
    } else {
      console.log("match Id not sent ..", matchId);
    }

    setTimeout(() => {
      appEventInstance.dispatch({
        eventType: AppEventTypes.toogleFooter,
        payload: {
          label: configData.drawerCloseBtnWords.continue,
          event: CanvasEvents.showContinue,
          callback: () => {
            setTimeout(() => {
              appEventInstance.dispatch({
                eventType: AppEventTypes.togglePackModal,
                payload: {},
              });
            }, 500);
            navigate(`${RoutesEnum.Home}${location.search}`);
          },
          forCanvas: false,
        },
      });
    }, 0);
  }, []);

  return (
    <ContentShell loading={false}>
      <PixiContainer view={CanvasView?.matchResult} />
      <div
        className={classNames(styles.root, {
          [styles.hideEL]: showPromotion,
        })}
        onClick={() => {
          setShowpromotion(true);
          //@ts-ignore
          window?.gameExtension({
            type: CanvasView.showPromotion,
            value: { isFTUI: true },
          });
        }}
      ></div>
    </ContentShell>
  );
};

export default MatchResult;
